import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
  marginBottom: "10px",
}));

const PerformanceSearch3 = ({
  text,
  fieldType,
  setPerformanceFilterState,
  setPreviousBrandSponsorsState,
  setCommentRateState,
  setMostRecentPostState,
  setAveragePostedContentState,
  setMinAvgContentLengthState,
  setMaxAvgContentLengthState,
  setImpressionOnReelsState,
  setImpressionOnPostsState,
  setImpressionOnVideosState,
  setHasSponsoredPostsState,
  clear,
  tooltipInfo,
  setAvgLikesState,
  setAvgReelsViewsState,
  setAvgContentLenState,
}) => {


  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value2, setValue2] = useState([0, 1000000]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1000000);


  const handleChange2 = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setValue2(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);

    if (setAvgLikesState) {
      setAvgLikesState({ min: newValue[0], max: newValue[1] });
    } else if (setAvgReelsViewsState) {
      setAvgReelsViewsState({ min: newValue[0], max: newValue[1] });
    } else if (setAvgContentLenState) {
      setAvgContentLenState({ min: newValue[0], max: newValue[1] });
    }
  };


  const handleMinChange = (event) => {
    const newMinValue =
      event.target.value === "" ? "" : parseInt(event.target.value, 10) || 0;
    setMinValue(newMinValue);
    setValue2([newMinValue, maxValue]);
  };

  const handleMaxChange = (event) => {
    const newMaxValue =
      event.target.value === ""
        ? ""
        : parseInt(event.target.value, 10) || 1000000;
    setMaxValue(newMaxValue);
    setValue2([minValue, newMaxValue]);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (clear) setValue("");
  }, [clear]);

  const handleChange = (e) => {
    setValue(e.target.value);
    setPerformanceFilterState(e.target.value);
  };

  if (setPreviousBrandSponsorsState) setPreviousBrandSponsorsState(value);
  else if (setCommentRateState) setCommentRateState(value);
  else if (setMostRecentPostState) setMostRecentPostState(value);
  else if (setAveragePostedContentState) setAveragePostedContentState(value);
  else if (setMinAvgContentLengthState) setMinAvgContentLengthState(value);
  else if (setMaxAvgContentLengthState) setMaxAvgContentLengthState(value);
  else if (setImpressionOnReelsState) setImpressionOnReelsState(value);
  else if (setImpressionOnVideosState) setImpressionOnVideosState(value);
  else if (setImpressionOnPostsState) setImpressionOnPostsState(value);
  else if (setHasSponsoredPostsState) setHasSponsoredPostsState(value);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>
      <Box sx={{ marginTop: 1 }}></Box>

      <AccordionDetails sx={{ textAlign: "start" }}>
        <TextField
          id="outlined-basic"
          label={text}
          placeholder="enter a number"
          type={fieldType}
          variant="outlined"
          value={value}
          onChange={handleChange}
          sx={{
            width: "100%",
            "& .MuiInputLabel-root": {
              color: "#3dbe72",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3dbe72",
              },
              "&:hover fieldset": {
                borderColor: "#3dbe72",
              },
            },
            "& .MuiInputBase-input": {
              color: "#3dbe72",
            },
          }}
        />
      </AccordionDetails>
      
      <Slider
        getAriaLabel={() => "Minimum distance shift"}
        value={value2}
        onChange={handleChange2}
        valueLabelDisplay="auto"
        disableSwap
        style={{ color: "#3dbe72", padding: 0, width: "100%" }}
        size="small"
        min={0}
        max={1000000}
      />
      <MinMaxFieldGroup>
        <TextField
          variant="outlined"
          type="number"
          value={minValue}
          onChange={handleMinChange}
          style={{ marginRight: "10px" }}
          size="small"
          label="min"
          sx={{
            width: "50%",
            padding: 0,
            margin: 0,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3dbe72",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3dbe72",
            },
          }}
        />
        <SwapHorizIcon style={{ marginRight: 6, color: "#3dbe72" }} />
        <TextField
          variant="outlined"
          type="number"
          label="max"
          value={maxValue}
          onChange={handleMaxChange}
          size="small"
          sx={{
            width: "50%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#3dbe72",
              color: "#3dbe72",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              color: "#3dbe72",
              borderColor: "#3dbe72",
            },
          }}
        />
      </MinMaxFieldGroup>

    
    </Accordion>
  );
};

export default PerformanceSearch3;
