import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  ListItemIcon,
  MenuItem,
  styled,
  Button,
  InputAdornment,
  TextField,
  Chip,
  Tooltip,
  NativeSelect,
  Autocomplete,
  useMediaQuery,
  Tab,
  Tabs,
  IconButton,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsBusFilledIcon from "@mui/icons-material/DirectionsBusFilled";
// import SelectComponent from './SelectComponent';
import SelectComponent from "./SelectComponent";
import FilterIcon from "../../../images/filter.png";
import { debounce } from "lodash";

import IG from "../../../images/insta-sm.png";
import youtubeIcon from "../../../images/youtube-sm.png";
import facebookIcon from "../../../images/facebook.png";
import SearchDropdownFilter from "./SearchDropdownFilter";
import usePermission from "../../../../hooks/permissionHooks";

// const Container = styled(Box)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'center',
//   alignItems: 'center',
//   marginBottom: '3rem',

// }))

// // CARD
// const SearchCard = styled(Grid)(({ theme }) => ({
//   width: '70%',
//   boxShadow: '0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40',
//   padding: 30,
//   borderRadius: 20,
//   [theme.breakpoints.down('sm')] : {
//     width: '100%',
//     padding: 10,
//   }

// }))

// const ShowBtn = styled(Button)(({ theme }) => ({
//   // padding : '8px 10px'
//   width: '100%',
//   padding: 10
// }))

// const AdvBtn = styled(Button)(({ theme }) => ({

//   textTransform: 'none',
//   fontSize: 15,
//   padding: '8px 25px',
//   borderRadius: 20,

// }))

// const [selectedOption, setSelectedOption] = React.useState('option1');

// const handleChange = (event) => {
//   setSelectedOption(event.target.value);
// };

// const [selectedIcon, setSelectedIcon] = useState('instagram');

// const handleChange = (event) => {
//   setSelectedIcon(event.target.value);
// };

// const renderValue = (selected) => {
//   // Customize the display of the selected item
//   return (
//     <ListItemIcon>
//       {selected === 'instagram' && <img src={IG} width={30} />}
//       {selected === 'drafts' && <img src={IG} width={30} />}
//       {selected === 'send' && <img src={IG} width={30} />}
//     </ListItemIcon>
//   );
// };

// const Container = styled(Box)(({ theme }) => ({
//   // background : '#9BD66A40',
//   // background: 'rgb(61,190,114)',
//   // background: 'radial-gradient(circle, rgba(61,190,114,1) 0%, rgba(255,255,255,1) 100%)',
//   // background: 'linear-gradient(355deg, rgba(61,190,114,1) 17%, rgba(255,255,255,1) 100%)',
//   backgroundImage: 'url("https://framerusercontent.com/images/lre0ZxRR1x4OKJNjYxP6s2SOaB8.png")', // Replace with the URL of your image
//   backgroundSize: 'cover', // You can adjust this based on your image size preference
//   backgroundPosition: 'center', // You can adjust this based on your image position preference
//   // Add other styles as needed
//   padding: 10,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   height: '300px'
// }))

const Container = styled(Box)(({ theme, sticky }) => ({
  position: sticky ? "sticky" : "relative",
  top: sticky ? "0px" : "auto",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundColor: "white",
  padding: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "300px",
  zIndex: 10,
}));
// useEffect(() => {
//   const handleScroll = () => {
//     if (window.scrollY < 200) {
//       setIsSticky(true);
//     } else {
//       setIsSticky(false);
//     }
//   };

//   window.addEventListener("scroll", handleScroll);
// }, []);

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginBottom: "1.5rem",
}));

const ChildContainer = styled(Box)(({ theme }) => ({
  width: "95%",
  boxShadow: "0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40",
  // boxShadow: ' 14px 200px 174px 123px rgba(61,190,114,0.43)',
  borderRadius: 20,
  padding: 10,
  // border: '3px solid #3DBE72',
  background: "white",
  margin: "1rem 1.5rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const ShowResultBtn = styled(Button)(({ theme }) => ({
  background: "black",
  textTransform: "none",
  borderRadius: 30,
  fontSize: 12,
  // padding: "10px 20px",
  "&:hover": {
    background: "black",
  },
  boxShadow: "none",
}));

const ShowSizeResultBtn = styled(Button)(({ theme }) => ({
  background: "black",
  textTransform: "none",
  borderRadius: 30,
  fontSize: 5,
  paddingLeft: 0,
  paddingRight: 0,
  width: 20,
  "&:hover": {
    background: "black",
  },
  boxShadow: "none",
}));

const SearchField = styled(TextField)(({ theme }) => ({
  width: "70%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const SizeSearchField = styled(TextField)(({ theme }) => ({
  width: "100px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const AdvFilterBtn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "#000000",
  fontWeight: "bold",
}));

const SelectSocial = styled(FormControl)(({ theme }) => ({
  background: "white",
  // marginLeft: "6rem",
  // marginBottom: "3rem",
}));


const handleButtonClick = () => {
  // Handle button click logic here
  console.log("Button clicked!");
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  minWidth: 80,
  padding: 0, // Adjust the padding value as needed
}));

const SearchBar = ({
  open,
  set,
  isMiniOpen,
  // setInfluencers,
  // influencers,
  // fetchMoreInfluencers,
  selectedSocial,
  setSelectedSocial,
  locations,
}) => {

  const [socialMediaOptions, setSocialMediaOptions] = useState([]);

  const ytFilterAccess = usePermission(['findInfluencer/filter/yt']);
  const igFilterAccess = usePermission(['findInfluencer/filter/ig']);
  const searchBoxAccess = usePermission(['findInfluencer/searchbox']);

  const [selectedValue, setSelectedValue] = useState(null);
  const [filter, setFilter] = useState({});
  const [searchKey, setSearchKey] = useState("");
  const [sticky, setSticky] = useState(false);

  const [filterAccess, setFilterAccess] = useState(false);

  useEffect(() => {
    // if (selectedSocial == "youtube") {
    //   setFilterAccess(ytFilterAccess);
    // } else if (selectedSocial == "instagram") {
    //   setFilterAccess(igFilterAccess);
    // }
    let socialMediaOptionsArr = [];
    if (ytFilterAccess) {
      setFilterAccess(true);
      socialMediaOptionsArr.push({ value: "youtube", label: "youtube", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/512px-YouTube_full-color_icon_%282017%29.svg.png?20240107144800" });
    } else {
      setFilterAccess(false);
    }
    if (igFilterAccess) {
      setFilterAccess(true);
      socialMediaOptionsArr.push({ value: "instagram", label: "Instagram", icon: IG })
    } else {
      setFilterAccess(false);
    }
    setSocialMediaOptions(socialMediaOptionsArr);
  }, [ytFilterAccess, igFilterAccess,]);

  useEffect(() => {
    setSelectedValue(selectedSocial);
  }, [selectedSocial]);

  const handleChangeAdv = (e) => {
    const { name, value } = e.target;
    if (filter[name] === undefined) {
      setFilter((prevFilter) => ({ ...prevFilter, [name]: [value] }));
    } else if (!filter[name].includes(value)) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        [name]: [...prevFilter[name], value],
      }));
    }
  };

  // useEffect(() => {
  //   console.log("influencer aagya", influencers)
  // }, [influencers]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setSelectedSocial(event.target.value);
  };

  const handleChange1 = (event) => {
    const newValue = event.target.alt;
    setSelectedValue(newValue);
    setSelectedSocial(newValue);
    console.log(newValue);
  };

  const handleSearch = (e) => {
    // const lowerCaseRequest = e.target.value.toLowerCase();
    // // return lowerCaseRequest==='' && setInfluencers(influencers);
    // // if(lowerCaseRequest)
    // if (lowerCaseRequest == "") {
    //   fetchMoreInfluencers();
    // }
    // setSearchKey(lowerCaseRequest);
  };

  const handleDelete = (key, index) => {
    // Create a copy of the current state
    const updatedFilter = { ...filter };

    // Remove the value associated with the specified key
    updatedFilter[key].splice(index, 1);

    if (updatedFilter[key].length === 0) {
      delete updatedFilter[key];
    }

    // Update the state
    setFilter(updatedFilter);
  };

  const handleButtonClick = () => {
    // const results = influencers?.filter((item) => {
    //   return (
    //     item.name.toLowerCase().includes(searchKey)
    //     // || item.categories.toLowerCase().includes(searchKey) ||
    //     // item.followers.includes(lowerCaseRequest) ||
    //     // item.engagement.includes(lowerCaseRequest) ||
    //     // item.average.includes(lowerCaseRequest)
    //   );
    // });
    // setInfluencers(results);
  };

  const category = [
    "Beauty",
    "Fashion",
    "Lifestyle",
    "Travel",
    "Food",
    "Gaming",
    "Sports",
    "Technology",
    "Business",
    "Entertainment",
  ];

  const language = [
    "English",
    "Spanish",
    "Mandarin",
    "Arabic",
    "Hindi",
    "Bengali",
    "Portuguese",
    "Russian",
    "Japanese",
    "Punjabi",
    "German",
    "Javanese",
    "Wu",
    "French",
    "Telugu",
    "Vietnamese",
    "Marathi",
    "Korean",
    "Tamil",
    "Turkish",
    "Polish",
    "Thai",
    "Urdu",
    "Greek",
    "Italian",
    "Cantonese",
    "Swedish",
    "Romanian",
    "Dutch",
    "Hungarian",
    "Finnish",
    "Indonesian",
    "Norwegian",
    "Danish",
    "Czech",
    "Slovak",
    "Bulgarian",
    "Croatian",
    "Slovenian",
    "Lithuanian",
    "Latvian",
    "Estonian",
    "Hebrew",
    "Persian",
    "Afrikaans",
    "Albanian",
    "Amharic",
    "Armenian",
    "Azerbaijani",
    "Basque",
    "Belarusian",
    "Bosnian",
    "Catalan",
    "Cebuano",
    "Chichewa",
    "Corsican",
    "Welsh",
    "Yiddish",
    "Zulu",
    "Hmong",
    "Igbo",
    "Khmer",
    "Lao",
    "Malagasy",
    "Malay",
    "Mongolian",
    "Nepali",
    "Pashto",
    "Sinhala",
    "Somali",
    "Sundanese",
    "Tagalog",
    "Tajik",
    "Ukrainian",
    "Uzbek",
    "Yoruba",
  ];

  const age = ["13-17", "18-24", "25-34", " 35-44", "45-54", "55-64", "65+"];

  const matches = useMediaQuery("(max-width:600px)"); // adjust the width as needed

  const socialMediaTabs = useMediaQuery("(max-width:768px)"); // adjust the width as needed

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          display: "flex",
          margin: "0 auto",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        sticky={sticky}
      >
        <ChildContainer>
          {selectedSocial && <Header>
            <AdvFilterBtn
              variant="text"
              onClick={() => set(true)}
              startIcon={<img src={FilterIcon} alt="Local Icon" />}
            >
              Advance Filters
            </AdvFilterBtn>
          </Header>}
          <Grid container>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "0 auto",
                height: "max-content",
                flexWrap: "wrap",
              }}
            >
              {filterAccess && (
                socialMediaTabs ? (
                  <Box
                    sx={{
                      margin: "0 auto 20px auto",
                    }}
                  >
                    <Tabs
                      value={selectedValue}
                      onChange={handleChange1}
                      aria-label="Social Media Tabs"
                      sx={{
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#3dbe72",
                          height: "1px",
                        },
                      }}
                    >
                      {igFilterAccess && <Tab
                        key="instagram"
                        value="instagram"
                        icon={
                          <img
                            src={IG}
                            alt="instagram"
                            style={{ width: "24px", marginRight: "8px" }}
                          />
                        }
                      />}
                      {ytFilterAccess && <Tab
                        key="youtube"
                        value="youtube"
                        icon={
                          <img
                            src={"https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/512px-YouTube_full-color_icon_%282017%29.svg.png?20240107144800"}
                            alt="youtube"
                            style={{ width: "24px", marginRight: "8px" }}
                          />
                        }
                      />}
                    </Tabs>
                  </Box>
                ) : (
                  <Box marginLeft={4} width={"5%"}>
                    <SelectSocial
                      sx={{
                        margin: "0 auto",
                        width: "100%",
                      }}
                    >
                      <Select
                        value={selectedValue}
                        onChange={handleChange}
                        displayEmpty
                        sx={{
                          boxShadow: "0px 4.829268455505371px 15.695122718811035px 0px #9BD66A40",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                          {
                            border: 0,
                          },
                        }}
                      >
                        {socialMediaOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            <img
                              src={option.icon}
                              alt={option.label}
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                          </MenuItem>
                        )
                        )}
                      </Select>
                    </SelectSocial>
                  </Box>))}
              <Box
                display={"flex"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
                width={"auto"}
                flexDirection={"column"}
                mx={"auto"}
                sx={{
                  marginTop: {
                    xs: "1rem",
                    sm: 0,
                  },
                }}
              >
                {searchBoxAccess && <SearchField
                  // label="Your Label"
                  // variant="outlined"
                  size="small"
                  outline="false"
                  placeholder="Search Influencers..."
                  onChange={handleSearch}
                  sx={{
                    borderRadius: 8, // Adjust the value as needed for your desired border radius
                    mx: "auto",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      padding: 1,
                      "&:hover fieldset": {
                        borderColor: "black", // Set the border color for hover state
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "black", // Set the border color for focused state
                      },
                    },
                  }}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ShowResultBtn
                          variant="contained"
                          onClick={handleButtonClick}
                          sx={{
                            padding: matches ? "1rem 0" : "10px 20px",
                            "& span": {
                              display: matches ? "none" : "inline",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 1,
                            }}
                          >
                            <SearchIcon
                              style={{
                                fontSize: 15,
                              }}
                            />
                            <span>Show Result</span>
                          </Box>
                        </ShowResultBtn>
                      </InputAdornment>
                    ),
                  }}
                />}
                {searchBoxAccess &&
                  <Grid item size={12} justifyContent={'center'} alignContent={'center'} align="center" style={{ marginTop: 10 }}>
                    {/* size dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-size">Size</InputLabel>
                      <Select
                        labelId="demo-select-small-size"
                        id="demo-select-size"
                        label="Size"
                        onChange={handleChangeAdv}
                        value=""
                        style={{ height: 40, borderRadius: 20 }}
                      >
                        <Box
                          sx={{
                            m: 1,
                          }}
                        >
                          <em>
                            {selectedSocial == "instagram"
                              ? "Followers Size"
                              : "Subscribers Size"}
                          </em>
                        </Box>

                        {/* <TextField
                        sx={{ width: "7rem", margin: "0 10px" }}
                        id="outlined-basic"
                        label="Min"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={filter.sizeMin}
                        onChange={(e) => {
                          const MinSize = e.target.value;
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            sizeMin: MinSize,
                          }));
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            const MinSize = e.target.value.trim();
                            if (MinSize) {
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                size: [
                                  ...(prevFilter.size || []),
                                  `>= ${MinSize}`,
                                ],
                              }));
                            }
                          }
                        }}
                      />
                      <TextField
                        sx={{ width: "7rem", margin: "0 10px" }}
                        id="outlined-basic"
                        label="Max"
                        variant="outlined"
                        size="small"
                        type="number"
                        value={filter.sizeMax}
                        onChange={(e) => {
                          const maxSize = e.target.value;
                          setFilter((prevFilter) => ({
                            ...prevFilter,
                            sizeMax: maxSize,
                          }));
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            const maxSize = e.target.value.trim();
                            if (maxSize) {
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                size: [
                                  ...(prevFilter.size || []),
                                  `<= ${maxSize}`,
                                ],
                              }));
                            }
                          }
                        }}
                      /> */}
                        <Box sx={{ marginBottom: "10px" }}>
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.sizeMin}
                            onChange={(e) => {
                              const minSize = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                sizeMin: minSize,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const minSize = e.target.value.trim();
                                if (minSize) {
                                  const maxSize = filter.sizeMax;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    size: [
                                      ...prevFilter.size,
                                      `${minSize}<=${maxSize}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.sizeMax}
                            onChange={(e) => {
                              const maxSize = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                sizeMax: maxSize,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const maxSize = e.target.value.trim();
                                if (maxSize) {
                                  const minSize = filter.sizeMin;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    size: [
                                      ...prevFilter.size,
                                      `${minSize}<=${maxSize}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                        </Box>
                      </Select>
                    </FormControl>
                    {/* location dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Location
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="location"
                        value=""
                        name="location"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="Location"
                          onChange={(event, value) => {
                            if (value) {
                              const newLocation = value;
                              setFilter((prevFilter) => {
                                const currentLocations = Array.isArray(
                                  prevFilter.location
                                )
                                  ? prevFilter.location
                                  : [];
                                return {
                                  ...prevFilter,
                                  location: [
                                    ...new Set([
                                      ...currentLocations,
                                      newLocation,
                                    ]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={locations}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl>
                    {/* age dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">Age</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="age"
                        value=""
                        name="age"
                        onChange={handleChangeAdv}
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: 100,
                        }}
                      >
                        <Box sx={{ m: 1 }}>
                          <em>Age</em>
                        </Box>
                        <Box sx={{ marginBottom: "10px" }}>
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Min"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.ageMin}
                            onChange={(e) => {
                              const minAge = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                ageMin: minAge,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const minAge = e.target.value.trim();
                                if (minAge) {
                                  const maxAge = filter.ageMax;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    age: [
                                      ...prevFilter.age,
                                      `${minAge}<=${maxAge}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                          <TextField
                            sx={{ width: "7rem", margin: "0 10px" }}
                            id="outlined-basic"
                            label="Max"
                            variant="outlined"
                            size="small"
                            type="number"
                            value={filter.ageMax}
                            onChange={(e) => {
                              const maxAge = e.target.value;
                              setFilter((prevFilter) => ({
                                ...prevFilter,
                                ageMax: maxAge,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                const maxAge = e.target.value.trim();
                                if (maxAge) {
                                  const minAge = filter.ageMin;
                                  setFilter((prevFilter) => ({
                                    ...prevFilter,
                                    age: [
                                      ...prevFilter.age,
                                      `${minAge}<=${maxAge}`,
                                    ],
                                  }));
                                }
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <Autocomplete
                            id="demo-select"
                            name="age"
                            onChange={(event, value) => {
                              if (value) {
                                const newAge = value;
                                setFilter((prevFilter) => {
                                  const currentAge = Array.isArray(prevFilter.age)
                                    ? prevFilter.age
                                    : [];
                                  return {
                                    ...prevFilter,
                                    age: [...new Set([...currentAge, newAge])],
                                  };
                                });
                              }
                            }}
                            style={{
                              height: 40,
                              borderRadius: 20,
                              width: "100%",
                              padding: 10,
                              marginBottom: 15,
                            }}
                            options={age}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search..."
                                sx={{ margin: "0 auto" }}
                                variant="outlined"
                                size="small"
                              />
                            )}
                          />
                        </Box>
                      </Select>
                    </FormControl>
                    {/* gender dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">Gender</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        name="gender"
                        label="gender"
                        value=""
                        onChange={handleChangeAdv}
                        style={{ height: 40, width: 100, borderRadius: 20 }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Prefer not to say">
                          Prefer not to say
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {/* category dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="category"
                        value=""
                        name="category"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="category"
                          onChange={(event, value) => {
                            if (value) {
                              const newCategory = value;
                              setFilter((prevFilter) => {
                                const currentCategory = Array.isArray(
                                  prevFilter.category
                                )
                                  ? prevFilter.category
                                  : [];
                                return {
                                  ...prevFilter,
                                  category: [
                                    ...new Set([...currentCategory, newCategory]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={category}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl>
                    {/* language dropdown filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Language
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="language"
                        value=""
                        name="language"
                        onChange={handleChangeAdv}
                        style={{ height: 40, borderRadius: 20, width: 120 }}
                      >
                        <Autocomplete
                          id="demo-select"
                          name="language"
                          onChange={(event, value) => {
                            if (value) {
                              const newLanguage = value;
                              setFilter((prevFilter) => {
                                const currentLanguage = Array.isArray(
                                  prevFilter.language
                                )
                                  ? prevFilter.language
                                  : [];
                                return {
                                  ...prevFilter,
                                  language: [
                                    ...new Set([...currentLanguage, newLanguage]),
                                  ],
                                };
                              });
                            }
                          }}
                          style={{
                            height: 40,
                            borderRadius: 20,
                            width: 150,
                            padding: 5,
                            marginBottom: 5,
                          }}
                          options={language}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search..."
                              sx={{ margin: "0 auto" }}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </Select>
                    </FormControl>
                    {/* keyword filter */}
                    <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Keyword
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label="keyword"
                        value=""
                        name="keyword"
                        onChange={handleChangeAdv}
                        style={{
                          height: 40,
                          borderRadius: 20,
                          width: 120,
                        }}
                      >
                        <TextField
                          label="Search..."
                          variant="outlined"
                          size="small"
                          placeholder="Search by Keywords"
                          value={searchKey}
                          sx={{ margin: "5px" }}
                          onChange={(e) => {
                            const keyword = e.target.value;
                            setSearchKey(keyword);
                          }}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              const newKeyword = e.target.value.trim();
                              if (newKeyword) {
                                setFilter((prevFilter) => {
                                  const currentKeywords = Array.isArray(
                                    prevFilter.keyword
                                  )
                                    ? prevFilter.keyword
                                    : [];
                                  return {
                                    ...prevFilter,
                                    keyword: [
                                      ...new Set([
                                        ...currentKeywords,
                                        newKeyword,
                                      ]),
                                    ], // Ensure unique values
                                  };
                                });
                                setSearchKey("");
                              }
                            }
                          }}
                        />
                      </Select>
                    </FormControl>
                    {Object.keys(filter).length > 0 && <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
                      <Tooltip title="Clear Filters">
                        <IconButton
                          size="small"
                          onClick={() => setFilter({})}
                          sx={{ color: "red" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </FormControl>}
                  </Grid>}
              </Box>
            </Box>
          </Grid>

          {Object.keys(filter).length > 0 && <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
              flexWrap: "wrap",
              height: "max-content",
            }}
            item
            md={12}
            align="center"
            margin="1rem 0rem "
          >
            {Object.keys(filter).map(
              (key) =>
                Array.isArray(filter[key]) &&
                filter[key]?.map((value, index, name) => (
                  <FormControl>
                    <InputLabel id="demo-simple-select-autowidth-label">
                      {key}
                    </InputLabel>
                    <Select
                      key={`${key}-${index}`}
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={value}
                      onChange={handleSearch}
                      autoWidth
                      label={key}
                      disabled
                      style={{
                        height: 40,
                        borderRadius: 20,
                        width: "max-content",
                        padding: "0px",
                        marginRight: 0,
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <button
                            style={{
                              padding: 0,
                              height: 20,
                              marginRight: "15px",
                              backgroundColor: "white",
                              zIndex: 5,
                              border: "none",
                              borderRadius: 20,
                            }}
                            variant="outlined"
                            onClick={() => handleDelete(key, index)}
                          >
                            X
                          </button>
                        </InputAdornment>
                      }
                    >
                      <MenuItem
                        style={{ padding: "0px" }}
                        sx={{
                          padding: "0",
                        }}
                        value={value}
                      >
                        {value}
                      </MenuItem>
                    </Select>
                  </FormControl>
                  // <Chip
                  //   key={`${key}-${index}`}
                  //   size="small"
                  //   label={value}
                  //   style={{
                  //     fontWeight: "lighter",
                  //     borderRadius: 20,
                  //     margin: "0px 1px",
                  //     padding: "0px 5px",
                  //   }}
                  //   onDelete={() => handleDelete(key, index)}
                  // />
                ))
            )}
          </Box>}
        </ChildContainer>
      </Box >
    </>
  );
};

export default SearchBar;
