import { createSlice } from "@reduxjs/toolkit";

let curuser = JSON.parse(localStorage.getItem("currUser"));

const initialState = {
    user: curuser ? curuser : null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state = initialState, action) => {
            state.user = action.payload;
        }
    }
})

export const { setUser } = userSlice.actions;

export default userSlice.reducer;