import React, { useEffect, useState } from "react";

import {
  Box,
  styled,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Modal,
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import { InsertLinkOutlined, CancelOutlined } from "@mui/icons-material";

import whatsappIcon from "../../../images/WhatsApp.png";
import gmailIcon from "../../../images/Gmail.png";
import ShareCollection from "../../collection/ShareCollection";
import GearDropdown from "./GearDropdown";
import Mail from "./Mail";




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: "80%",
  overflow: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

function MailModel() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <img
          src={
            "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
          }
          alt=""
          width={30}
        />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Mail />
        </Box>
      </Modal>
    </div>
  );
}

const ShareProfile = ({ close, name, id, baseUrl }) => {
  const [link, setLink] = useState(
    `${baseUrl}/public-content/influencer-profile/${id}`
  );
  const [isCopy, setIsCopy] = useState(false);

  const shareLink =
    "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png";

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Text successfully copied to clipboard!");
        setIsCopy(true);
        // You can add additional logic here if the copy was successful
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
        // You can add error handling logic here if the copy failed
      });
  };



  const mailOpen = () => {
    close();

  }


  return (
    <Grid container p={5}>
      <Grid item md={10} xs={10}>
        <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
          Share <span style={{ color: "#3dbf73ff" }}>@{name}'s</span> Profile
        </Typography>
      </Grid>
      <Grid item md={2} xs={2} align="end">
        <Tooltip title="Close" arrow placement="left">
          <IconButton onClick={close}>
            <CancelOutlined />
          </IconButton>
        </Tooltip>
      </Grid>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

      }} >
        <IconButton>
          <img
            src={
              "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
            }
            alt=""
            width={50}
          />
        </IconButton>
        <MailModel />

      </Box>
      <Grid
        item
        md={12}
        xs={12}
        mb={1}
        mt={2}
        display={"flex"}
        alignItems={"center"}
        gap={2}
      >
        <Typography fontWeight={"bold"}>Or share this Link </Typography>
        <InsertLinkOutlined />
        <GearDropdown />
      </Grid>
      <Grid item md={9} xs={9}>
        <Box
          sx={{
            overflow: "hidden",
            color: "#828282ff",
          }}
        >
          <code>{link} </code>
        </Box>
      </Grid>
      <Grid
        item
        md={3}
        xs={3}
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            background: "#3dbe72ff",
            boxShadow: "none",
            textTransform: "none",
            borderRadius: "10px",
            "&:hover": {
              background: "#3dbe72ff",
            },
          }}
          onClick={handleCopyClick}
        >
          {isCopy ? "Copied" : "Copy"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ShareProfile;
