import React from 'react'
import { Box, Typography, Avatar } from '@mui/material'
import Grid from '@mui/material/Grid2';

const dp = "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjCYj2c5wohiGczEHwY7jEoSErXHr6Sl1XxDZ6DHS4OEpd8B_RqTVgVpzkGeKitVPbuLNzv2Sl237FtR3J-sMdxosAqQWPcQG9ykoiv40sS4o0GYRmeyJadONIxrbDztuyNYOuDmkbn_ZUENRhSnnsI7Rlk51Qj2zugP7aARSXDtbLKer3WDSJV5JjVwt4/s1080/Attitude%20Girls%20DP%20For%20WhatsApp%20high%20level%20editing.jpg"

const RecentUpdate = () => {
    return (
        <Box sx={{ marginTop: 3, bgcolor: '#f2f7ffff', padding: 4, borderRadius: 4 }}>
            <Grid container>
                <Grid item md={12}>
                    <Typography
                        sx={{ fontWeight: 'bold', fontSize: '1.5rem' }}
                    >Recent Updates</Typography>
                </Grid>
            </Grid>
            <Grid container mt={2} >
                <Grid item md={1} align='center'>
                    <Avatar alt="Remy Sharp" src={dp} />
                </Grid>
                <Grid item md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography><span>Rohit Kumar</span> uploaded a reel on Instagram</Typography>
                </Grid>
                <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>5 sec ago.</Typography>
                </Grid>
            </Grid>
            <Grid container mt={2} >
                <Grid item md={1} align='center'>
                    <Avatar alt="Remy Sharp" src={dp} />
                </Grid>
                <Grid item md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography><span>Rohit Kumar</span> uploaded a reel on Instagram</Typography>
                </Grid>
                <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>5 sec ago.</Typography>
                </Grid>
            </Grid>
            <Grid container mt={2} >
                <Grid item md={1} align='center'>
                    <Avatar alt="Remy Sharp" src={dp} />
                </Grid>
                <Grid item md={8} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography><span>Rohit Kumar</span> uploaded a reel on Instagram</Typography>
                </Grid>
                <Grid item md={3} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>5 sec ago.</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default RecentUpdate