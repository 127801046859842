import { Dialog, Box, Typography, IconButton, Tooltip, styled, Chip, Button, Checkbox } from '@mui/material'
import Grid from '@mui/material/Grid2';
import React, { useState } from 'react'
import { FileUploader } from "react-drag-drop-files";
import { CancelOutlined, DeleteOutlineOutlined, Add } from '@mui/icons-material'
import { borderRadius } from '@mui/system';

const fileTypes = ["JPG", "PNG", "GIF"];
const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.1rem',

}))

const HtmlLabel = styled('label')(`
    color : #828282ff;
    font-size : '1.1rem';

`)

const HtmlInput = styled('input')(`
    background-color : #dce7f7ff;
    border-radius : 8px;
    width : 100%;
    height : 40px;
    padding : 0px 20px;
    outlied : none;
    border : none;
`)

const HtmlTextArea = styled('textarea')(`
width : 100%;
padding : 10px 20px;
border-radius : 8px;
height : 120px;
background-color : #dce7f7ff;
border : none;
`)

const HtmlInputNum = styled('input')(`
    width : 80px;
    text-align : center;
   border : none;
    border-radius : 10px;
    margin-right : 8px;
    background-color : #dce7f7ff;
`)


const CreateCampaignDialog = ({ open, set }) => {
    const [file, setFile] = useState(null);
    const [logo, setLogo] = useState(null);

    const handleChange = (file) => {
        setFile(file);
        console.log(file);
    };

    const handleBrandLogo = (logo) => {
        setLogo(logo)
    }

    // const [isChecked, setIsChecked] = useState(false);

    // const handleCheckboxChange = () => {
    //     setIsChecked(!isChecked);
    // };

    const customDropZoneContent = (

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dashed #abababff', height: '120px', borderRadius: 2, cursor: 'pointer' }}>
            {
                file
                    ?
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography>{file.name}</Typography>

                        {/* <Tooltip title="Remove" size={'small'} arrow placement='right'>
                <IconButton size='small' onClick={() => setFile(null)}>
                    <DeleteOutlineOutlined/>
                </IconButton>
                </Tooltip> */}

                    </Box>
                    :
                    <p> Click to <span style={{ color: '#3dbf73ff', padding: '0px 5px' }}> Browse </span> File or Drag & Drop to upload</p>
            }

        </Box>
    );

    return (
        <Dialog open={open} onClose={() => set(false)} PaperProps={{ sx: { borderRadius: 3, border: '2px solid black', padding: 4 } }}>
            <Box >
                <Grid container >
                    <Grid item md={11} xs={11}>
                        <Typography sx={{ color: '#3dbf73ff', fontWeight: 'bold', fontSize: '1.2rem' }}>Create new Campaign</Typography>
                    </Grid>
                    <Grid item md={1} xs={1} align='end'>
                        <Tooltip title={'Close'} arrow placement='right'>
                            <IconButton size={'small'} onClick={() => set(false)}>
                                <CancelOutlined />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item md={12} xs={12} mt={2}>
                        <SectionTitle>1. Campaign Details</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                        <Box>
                            <HtmlLabel htmlFor="name">Campaign Name</HtmlLabel>
                        </Box>
                        <Box mt={1}>
                            <HtmlInput type="text" name="" id="name" placeholder='Enter the Campaign Name' />
                        </Box>
                    </Grid>

                    <Grid item md={12} xs={12} mt={2}>
                        <Box>
                            <HtmlLabel htmlFor="brandName">Brand Name</HtmlLabel>
                        </Box>
                        <Box mt={1}>
                            <HtmlInput type="text" name="" id="brandName" placeholder='Enter the Campaign Name' />
                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2}>
                        <Box>
                            <HtmlLabel htmlFor="logo">Brand Logo</HtmlLabel>
                        </Box>
                        <Box mt={1}>
                            <FileUploader
                                id={'logo'}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                                hoverTitle="Drop here"
                                children={customDropZoneContent}

                            />
                        </Box>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item md={12} xs={12} mt={5}>
                        <SectionTitle>2. Campaign Brief</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2} sx={{ padding: '0px 10px' }}>
                        {/* <Box>
                            <HtmlLabel htmlFor="name">Campaign Name</HtmlLabel>
                        </Box> */}
                        <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-evenly', border: '1px solid #abababff', p: 2, borderRadius: 2 }}>
                            <Chip
                                variant="outlined"
                                label="bold"
                                sx={{
                                    borderRadius: 2,

                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="italic"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="bullet list"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="numbered list"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="highlight"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>


                <Grid container>
                    <Grid item md={12} xs={12} mt={5}>
                        <SectionTitle>3. Campaign Brief</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2} sx={{ padding: '0px 10px' }}>
                        <form action="">
                            <fieldset style={{ padding: '15px', borderRadius: '10px' }}>
                                <legend>Product Details</legend>
                                <Box>
                                    <Box >
                                        <HtmlLabel htmlFor="productName">What is the product on offer?</HtmlLabel>
                                    </Box>
                                    <Box mt={1}>
                                        <HtmlInput type="text" name="" id="productName" placeholder='Enter the name of the product' />
                                    </Box>
                                </Box>
                                <Box mt={3}>
                                    <Box>
                                        <HtmlLabel htmlFor="amount">What is the value of the product?</HtmlLabel>
                                    </Box>
                                    <Box mt={1}>
                                        <HtmlInput type="text" name="" id="amount" placeholder='Enter the amount in INR' />
                                    </Box>
                                </Box>
                                <Box mt={3}>
                                    <Box>
                                        <HtmlLabel htmlFor="breif">What a description for the product :</HtmlLabel>
                                    </Box>
                                    <Box mt={1}>
                                        <HtmlTextArea type="text" name="" id="breif" placeholder='Kindly enter a breif about the campaign here...' rows={4} />
                                    </Box>
                                </Box>
                                <Box mt={3}>
                                    <Box>
                                        <HtmlLabel htmlFor="product">Kindly upload a picture of the product</HtmlLabel>
                                    </Box>
                                    <Box mt={1}>
                                        <FileUploader
                                            id={'logo'}
                                            handleChange={handleBrandLogo}
                                            name="file"
                                            types={fileTypes}
                                            hoverTitle="Drop here"
                                            children={customDropZoneContent}

                                        />
                                    </Box>
                                </Box>
                            </fieldset>
                        </form>

                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12} xs={12} mt={5}>
                        <SectionTitle>4. Deliverables</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2} sx={{ padding: '0px 10px' }}>
                        <Box>
                            <HtmlLabel htmlFor=''>Deliverable No.</HtmlLabel>
                        </Box>
                        <Box display={'flex'} mt={1}>
                            <HtmlInputNum type="number" name="" id="" placeholder='1' />
                            <HtmlInput type="text" name="" id="" placeholder='Select the typeof deliverable' />
                        </Box>
                        <Button startIcon={<Add />} fullWidth variant='outlined' sx={{ color: '#3dbf73ff', textTransform: 'none', borderColor: '#3dbf73ff', marginTop: 2, borderRadius: 2, '&:hover': { borderColor: '#3dbf73ff' } }}>Add another deliverable</Button>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12} xs={12} mt={5}>
                        <SectionTitle>5. Additional Requirements</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2} sx={{ padding: '0px 10px' }}>
                        {/* <Box>
                            <HtmlLabel htmlFor="name">Campaign Name</HtmlLabel>
                        </Box> */}
                        <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-evenly', border: '1px solid #abababff', p: 2, borderRadius: 2 }}>
                            <Chip
                                variant="outlined"
                                label="bold"
                                sx={{
                                    borderRadius: 2,

                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="italic"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="bullet list"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="numbered list"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                            <Chip
                                variant="outlined"
                                label="highlight"
                                sx={{
                                    borderRadius: 2
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item md={12} xs={12} mt={5}>
                        <SectionTitle>6. Creator Preferences</SectionTitle>
                    </Grid>
                    <Grid item md={12} xs={12} mt={2} sx={{ padding: '0px 10px' }}>
                        <Box>
                            <Box>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Number of perks</Typography>
                            </Box>
                            <Box>
                                <HtmlInput placeholder='Select number of perks' />
                            </Box>
                        </Box>
                        <Box>
                            <Box mt={4}>
                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>Creator Preferences</Typography>
                            </Box>
                            <Box>
                                <Box>
                                    <HtmlLabel>Creator Gender</HtmlLabel>
                                </Box>
                                <Box mt={1}>
                                    <Button size='small' variant='outlined' sx={{ textTransform: 'none', fontWeight: 'bold', color: '#3dbf73ff', borderColor: '#3dbf73ff', '&:hover': { borderColor: '#3dbf73ff' } }}>Male</Button>
                                    <Button size='small' variant='outlined' sx={{ textTransform: 'none', margin: '0px 10px', fontWeight: 'bold', color: '#3dbf73ff', borderColor: '#3dbf73ff', '&:hover': { borderColor: '#3dbf73ff' } }}>Female</Button>
                                    <Button size='small' variant='outlined' sx={{ textTransform: 'none', fontWeight: 'bold', color: '#3dbf73ff', borderColor: '#3dbf73ff', '&:hover': { borderColor: '#3dbf73ff' } }}>Both</Button>
                                </Box>
                            </Box>
                            <Box>
                                <Box mt={3}>
                                    <HtmlLabel>Creator Location</HtmlLabel>
                                </Box>
                                <Box mt={1}>
                                    <HtmlInput placeholder='Eg. Mumbai, Delhi etc.' />
                                </Box>
                            </Box>
                            <Box>
                                <Box mt={3}>
                                    <HtmlLabel>Influencer Type</HtmlLabel>
                                </Box>
                                <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#dce7f7ff', padding: '0px 15px', borderRadius: 2 }}>
                                    <Typography><span>Nano</span> (2k-10k Followers)</Typography>
                                    <Checkbox
                                        size='small'
                                        // checked={isChecked}
                                        // onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                </Box>
                                <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#dce7f7ff', padding: '0px 15px', borderRadius: 2 }}>
                                    <Typography><span>Micro</span> (10k-25k Followers)</Typography>
                                    <Checkbox
                                        size='small'
                                        // checked={isChecked}
                                        // onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                </Box>
                                <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#dce7f7ff', padding: '0px 15px', borderRadius: 2 }}>
                                    <Typography><span>Mid Tier</span> (25k-100k Followers)</Typography>
                                    <Checkbox
                                        size='small'
                                        // checked={isChecked}
                                        // onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                </Box>
                                <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', bgcolor: '#dce7f7ff', padding: '0px 15px', borderRadius: 2 }}>
                                    <Typography><span>Macro</span> (100k-250k Followers)</Typography>
                                    <Checkbox
                                        size='small'
                                        // checked={isChecked}
                                        // onChange={handleCheckboxChange}
                                        color="primary"
                                    />
                                </Box>
                            </Box>


                        </Box>
                    </Grid>
                    <Grid item md={12} xs={12} sx={{ padding: '0px 10px' }} mt={3}>
                        <SectionTitle>Creator Preference</SectionTitle>
                        <Typography mt={1} mb={1}>Sharing your phone number will help us reach faster, we won't spam you.</Typography>
                        <HtmlInput placeholder='Enter your phone number' />
                    </Grid>
                </Grid>
            </Box>

        </Dialog>
    )
}

export default CreateCampaignDialog