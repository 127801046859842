import React, { useState } from 'react'

import DataTable from 'react-data-table-component';

import { Tooltip, styled, IconButton, Box, AvatarGroup, Avatar, Typography, Button, Divider, Dialog, Drawer, ToggleButton, ToggleButtonGroup } from '@mui/material'

import Grid from '@mui/material/Grid2';
import { CancelOutlined, ViewListRounded, GridViewRounded } from '@mui/icons-material'

import CollectionDrawer from './CollectionDrawer.jsx';

import { collections } from './collectionData.js'

import SBicon from '../../images/sb.png'
import LISTicon from '../../images/lst.png'

import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

const avt1 = 'https://st.depositphotos.com/1308396/61552/i/450/depositphotos_615523558-stock-photo-view-sunrise-light-rear-car.jpg'
const avt2 = 'https://cdn.pixabay.com/photo/2015/04/19/08/32/marguerite-729510_640.jpg'
const avt3 = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3OOfrBLXPZKaVZ-nwC8p9_dYGFi3jY4ktjQ&usqp=CAU'

const customStyles = {
    head: {
        style: {
            fontSize: '13px', // Set the desired font size for the header
        },
    },
};

// const CustomStatus = ({row}) =>{
//     <span>Aproved</span>
// }
const findBackgroundColor = (status) => {
    switch (status) {
        case 'rejected': return 'red';
        case 'under Review': return '#FFA84C';
        case 'approved': return 'green';
        case 'Inactive': return '#FF2323';
    }

}

const CustomAverage = ({ row }) => (
    <span style={{ background: findBackgroundColor(row.status), padding: '2px 8px', color: '#FFFFFF', borderRadius: 10 }}>{row.status}</span>
)


const CustomName = ({ row }) => (
    <>

        <Box display={'flex'} alignItems={'center'} sx={{ pointerEvents: 'none', cursor: 'pointer' }}>
            <AvatarGroup>
                <Avatar alt="Remy Sharp" src={avt3} sx={{ position: 'relative', bottom: '-10px' }} />
                <Box display={'flex'} flexDirection={'column'}>
                    <Avatar alt="Travis Howard" src={avt2} sx={{ width: 28, height: 28, position: 'relative', left: '-5px', bottom: '-5px' }} />
                    <Avatar alt="Agnes Walker" src={avt1} sx={{ width: 28, height: 28, position: 'relative', left: '-15px', top: '-5px' }} />
                </Box>
                {/* <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" /> */}
            </AvatarGroup>
            <span>{row.name.toUpperCase()}</span>
        </Box>
    </>
)

const columns = [

    {
        name: 'Collection Name',
        selector: row => row.name,
        sortable: true,
        // width: '250px',
        cell: row => <CustomName row={row} />
    },
    {
        name: 'No. of Influencers',
        selector: row => row.noOfInfluencers,
        sortable: true,
        reorder: true,
        // width: '190px',
        // cell: row => <Customcategory row={row} />
    },
    {
        name: 'Brand',
        selector: row => row.brand,
        sortable: true,
        reorder: true,
        // width: '120px',
    },
    {
        name: 'Created On',
        selector: row => row.createdOn,
        sortable: true,
        reorder: true,

        // width: '120px',
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        reorder: true,

        cell: row => <CustomAverage row={row} />
    },
].map(column => ({
    ...column,
    style: {
        fontSize: '13px',
    }
}))

const MyLabel = styled('label')(`
    font-size : 13px;

`)

const InputField = styled('input')(`
    background : #dce7f7ff;
    border-radius : 8px;
    padding : 8px 10px;
    outlined : none;
    border : none;
    width : 100%;
    margin-top : 5px;
`)

const MultiLineInputField = styled('textarea')(`
background : #dce7f7ff;
    border-radius : 8px;
    padding : 8px 10px;
    outlined : none;
    border : none;
    width : 100%;
    margin-top : 5px;

`)

const CollectionListAndCardView = () => {

    const [data, setData] = useState(collections)
    const [view, setViews] = useState(false);
    const [openCollection, setOpenCollection] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [clickRow, setClickRow] = useState(null);

    const [view1, setView1] = useState('list')

    const handleViewChange = (event, newView) => {
        setView1(newView);
    };


    const handleRowClick = (row) => {
        setOpenDrawer(true)
    }

    return (
        <>
            {/* //Create a new Collection  */}

            <Dialog open={openCollection}
                onClose={() => setOpenCollection(false)}
                PaperProps={{
                    style: {
                        borderRadius: 8, // Set your desired border radius
                    },
                }}
            >

                <Grid container p={3}>

                    <Grid item md={11} xs={11}>
                        <Typography sx={{ color: 'rgb(61, 191, 115)', fontSize: '1.2rem', fontWeight: 'bold' }}>Create new Collection</Typography>
                    </Grid>

                    <Grid item md={1} xs={1}>
                        <Tooltip title="Close" arrow>
                            <IconButton size='small' onClick={() => setOpenCollection(false)}>
                                <CancelOutlined />
                            </IconButton>

                        </Tooltip>
                    </Grid>
                    <Grid item md={12} xs={12} mt={1}>
                        <MyLabel htmlFor="name">Name of Collection</MyLabel>
                        <br />
                        <InputField type="text" name="name" id="name" placeholder='Enter the Name of Collection' />
                    </Grid>
                    <Grid item md={12} xs={12} mt={1}>
                        <MyLabel htmlFor="name">Brand Name</MyLabel>
                        <br />
                        <InputField type="text" name="name" id="name" placeholder='Enter the Brand Name' />
                    </Grid>
                    <Grid item md={12} xs={12} mt={1}>
                        <MyLabel htmlFor="name">Description</MyLabel>
                        <br />
                        <MultiLineInputField rows={3} type="text" name="name" id="name" placeholder='Write description' />
                    </Grid>
                    <Grid item md={12} xs={12} align="end" mt={2}>
                        <Button size='small' variant='contained' sx={{ bgcolor: '#c5edd6ff', color: '#000000', textTransform: 'capitalize', fontWeight: 'bold', border: '2px solid #3dbf73ff', borderRadius: 8, padding: '3px 20px', boxShadow: 'none', '&:hover': { bgcolor: '#c5edd6ff', color: '#000000', boxShadow: 'none' } }}>Cancel</Button>

                        <Button size='small' variant='contained' sx={{ bgcolor: '#3dbf73ff', color: '#FFFFFF', textTransform: 'capitalize', fontWeight: 'bold', border: '2px solid #3dbf73ff', borderRadius: 8, padding: '3px 20px', boxShadow: 'none', marginLeft: 1, '&:hover': { bgcolor: '#c5edd6ff', color: '#000000', boxShadow: 'none' } }}>Create Collection</Button>
                    </Grid>

                </Grid>
            </Dialog>


            {/* //Drawer for Collection  */}
            <Drawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                anchor='right'
                PaperProps={{
                    sx: { width: { md: "40%", xs: '100%' } },
                }}
            >
                <CollectionDrawer setOpen={setOpenDrawer} />
            </Drawer>


            <Grid container sx={{ bgcolor: 'rgba(0,0,0,0.087)' }} mt={2} mb={1}>
                <Grid item md={12} pb={2} xs={12} pl={2} pt={2} sx={{ bgcolor: 'white' }}>

                    <ToggleButtonGroup
                        value={view1}
                        exclusive
                        onChange={handleViewChange}
                        aria-label="text alignment"
                        size='small'
                    >
                        <ToggleButton value="list"
                            style={{
                                color: view1 === 'list' ? '#3dbf73ff' : 'inherit', // Set the color to red when the button is active
                                border: view1 === 'list' ? '2px solid #3dbf73ff' : 'inherit'
                            }}
                        >
                            <ViewListRounded />
                        </ToggleButton>
                        <ToggleButton value="card"

                            style={{
                                color: view1 === 'card' ? '#3dbf73ff' : 'inherit', // Set the color to red when the button is active
                                border: view1 === 'card' ? '2px solid #3dbf73ff' : 'inherit'
                            }}
                        >
                            <GridViewRounded />
                        </ToggleButton>


                    </ToggleButtonGroup>

                </Grid>
                <Grid item md={9} xs={12} align='end' >

                    {/* <Btn size='small' variant='outlined' startIcon={<BookmarkBorderIcon />}>
                        Add to Collection
                    </Btn>
                    <Btn size='small' variant='outlined' startIcon={<AddRoundedIcon />}>
                        Add to Campaign
                    </Btn>
                    <Btn size='small' variant='outlined' startIcon={<VisibilityOffRoundedIcon />} >
                        Hide Influencers
                    </Btn> */}


                </Grid>


                <Grid item md={12} xs={12} >
                    <Grid container spacing={4} p={view && 3} >
                        {
                            (view1 == 'card') ?
                                data?.map((item) => (
                                    <Grid key={item.id} item md={3} xs={12}>
                                        <Grid container p={2} sx={{ bgcolor: '#FFFFFF', borderRadius: 5 }}>
                                            <Grid item xs={4}>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <AvatarGroup>
                                                        <Avatar alt="Remy Sharp" src={avt3} sx={{ position: 'relative', bottom: '-10px' }} />
                                                        <Box display={'flex'} flexDirection={'column'}>
                                                            <Avatar alt="Travis Howard" src={avt2} sx={{ width: 28, height: 28, position: 'relative', left: '-5px', bottom: '-5px' }} />
                                                            <Avatar alt="Agnes Walker" src={avt1} sx={{ width: 28, height: 28, position: 'relative', left: '-15px', top: '-5px' }} />
                                                        </Box>

                                                    </AvatarGroup>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Typography sx={{ fontWeight: 'bold' }}>FinFluencers</Typography>
                                                <Typography sx={{ fontSize: '.8rem' }}>Ankur Warikoo, Ashneer Grover... 159 Others</Typography>
                                            </Grid>
                                            <Grid item mt={2} xs={5} align={'center'}>
                                                <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>Follwers</Typography>
                                                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>159M</Typography>
                                            </Grid>
                                            <Divider orientation="vertical" variant="middle" flexItem />
                                            <Grid ml={.5} item mt={2} xs={5} align={'center'}>
                                                <Typography sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>Success Rate</Typography>
                                                <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>99.99%</Typography>
                                            </Grid>
                                            <Grid item xs={12} mt={3} align={'center'}>
                                                <Button onClick={() => setOpenCollection(true)} sx={{ width: '90%', boxShadow: 'none', textTransform: 'none', bgcolor: '#3DBE72', borderRadius: 10, '&:hover': { bgcolor: '#3DBE72', boxShadow: 'none', } }} size='small' variant='contained'>Add to Collection</Button>
                                            </Grid>
                                            <Grid item xs={12} mt={2} align={'center'}>
                                                <Button sx={{ width: '90%', boxShadow: 'none', textTransform: 'none', bgcolor: '#3DBE72', borderRadius: 10, '&:hover': { bgcolor: '#3DBE72', boxShadow: 'none', } }} size='small' variant='contained'>Add to Campaign</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                ))

                                :
                                // <Grid item md={12} xs={12}>
                                <DataTable
                                    columns={columns}
                                    data={data}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="100%"
                                    customStyles={customStyles}
                                    onRowClicked={handleRowClick}
                                />
                            // </Grid>
                        }

                    </Grid>

                </Grid>

            </Grid>
        </>


    )
}

export default CollectionListAndCardView