import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

// const SliderInputFieldGroup = styled(Box)(({ theme }) => ({
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "center",
//   marginTop: "15px",
// }));

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
  marginBottom: "10px",
}));

const TabsGroup1 = ({
  text,
  tabs,
  setTabsGroupFilterState,
  // setFollowersGrowthState,
  // setAvgReelsViewsState,
  setAveragePostedContentState,
  setViewsTimeframeState,
  clear,
  tooltipInfo,
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [sliderValue, setSliderValue] = useState(50);
  const [inputValue, setInputValue] = useState(50);
  const [value2, setValue2] = useState([0, 1000000]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1000000);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setTabsGroupFilterState({
      selectedTab: newValue,
      min: minValue,
      max: maxValue,
    });
    if (setViewsTimeframeState)
      setViewsTimeframeState({
        selectedTab: newValue,
        min: minValue,
        max: maxValue,
      });
    if (setAveragePostedContentState)
      setAveragePostedContentState({
        selectedTab: newValue,
        min: minValue,
        max: maxValue,
      });
  };

  // const handleSliderChange = (event, newValue) => {
  //   setSliderValue(newValue);
  //   setInputValue(newValue);
  //   setTabsGroupFilterState({ selectedTab: activeTab, value: inputValue });
  //   if (setFollowersGrowthState)
  //     setFollowersGrowthState({
  //       selectedValue: activeTab,
  //       value: inputValue,
  //     });
  //   else if (setAvgReelsViewsState)
  //     setAvgReelsViewsState({
  //       selectedValue: activeTab,
  //       value: inputValue,
  //     });
  //   else
  //     setViewsTimeframeState({
  //       selectedValue: activeTab,
  //       value: inputValue,
  //     });
  // };

  // const handleInputChange = (event) => {
  //   const newInputValue = parseInt(event.target.value, 10);
  //   if (!isNaN(newInputValue)) {
  //     setSliderValue(newInputValue);
  //     setInputValue(newInputValue);
  //     setTabsGroupFilterState({ selectedTab: activeTab, value: inputValue });
  //     if (setFollowersGrowthState)
  //       setFollowersGrowthState({
  //         selectedValue: activeTab,
  //         value: inputValue,
  //       });
  //     else if (setAvgReelsViewsState)
  //       setAvgReelsViewsState({
  //         selectedValue: activeTab,
  //         value: inputValue,
  //       });
  //     else
  //       setViewsTimeframeState({
  //         selectedValue: activeTab,
  //         value: inputValue,
  //       });
  //   }
  // };

  const handleChange2 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setValue2(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);

    if (setViewsTimeframeState)
      setViewsTimeframeState({ min: newValue[0], max: newValue[1] });
    else setAveragePostedContentState({ min: newValue[0], max: newValue[1] });
  };

  const handleMinChange = (event) => {
    const newMinValue =
      event.target.value === "" ? "" : parseInt(event.target.value, 10) || 0;
    setMinValue(newMinValue);
    setValue2([newMinValue, maxValue]);
  };

  const handleMaxChange = (event) => {
    const newMaxValue =
      event.target.value === ""
        ? ""
        : parseInt(event.target.value, 10) || 1000000;
    setMaxValue(newMaxValue);
    setValue2([minValue, newMaxValue]);
  };

  useEffect(() => {
    if (clear) {
      setActiveTab(tabs[0]);
      setSliderValue(50);
      setInputValue(50);
    }
  }, [clear]);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 15, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails>
        <Box sx={{ width: "100%", padding: "0 10px" }}>
          <Autocomplete
            value={activeTab}
            onChange={handleTabChange}
            options={tabs}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Time frame"
                size="small"
                sx={{
                  "&.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3dbe72",
                  },
                  "&:hover.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#3dbe72",
                  },
                }}
              />
            )}
          />
          <Slider
            getAriaLabel={() => "Minimum distance shift"}
            value={[minValue, maxValue]}
            onChange={handleChange2}
            valueLabelDisplay="auto"
            disableSwap
            style={{ color: "#3dbe72", padding: 0, width: "100%" }}
            size="small"
            min={0}
            max={1000000}
          />
          <MinMaxFieldGroup>
            <TextField
              variant="outlined"
              type="number"
              value={minValue}
              onChange={handleMinChange}
              style={{ marginRight: "10px" }}
              size="small"
              label="min"
              sx={{
                width: "50%",
                padding: 0,
                margin: 0,
                "&.MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
                "&:hover.MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                },
              }}
            />
            <SwapHorizIcon style={{ marginRight: 6, color: "#3dbe72" }} />
            <TextField
              variant="outlined"
              type="number"
              label="max"
              value={maxValue}
              onChange={handleMaxChange}
              size="small"
              sx={{
                width: "50%",
                "&.MuiOutlinedInput-notchedOutline": {
                  borderColor: "#3dbe72",
                  color: "#3dbe72",
                },
                "&:hover.MuiOutlinedInput-notchedOutline": {
                  color: "#3dbe72",
                  borderColor: "#3dbe72",
                },
              }}
            />
          </MinMaxFieldGroup>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TabsGroup1;
