import React, { useEffect, useState } from 'react'
import { Box, Button, TextField, Typography, styled, IconButton, InputAdornment, CircularProgress, Stack, ToggleButtonGroup, ToggleButton, alpha } from '@mui/material';

import { Instagram, YouTube, TaskAlt, } from '@mui/icons-material'

import { useNavigate, useSearchParams } from "react-router-dom";

import Logo from '../images/logo_CreatorHunt-white.png'
import insta from '../images/insta-sm.png'
import youtube from '../images/youtube-sm.png'

// APIs
import { addInstaAccount } from '../../service/addSocialMediaAPI'
import { getUserSocialAccountInfo } from '../../service/api';
import { useSnackbar } from 'notistack';

const Container = styled(Box)(({ theme }) => ({

    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',

    [theme.breakpoints.down('sm')]: {
        paddingLeft: '1rem'
    }


}))

const MyButton = styled(Button)(({ theme }) => ({

    color: 'Black',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '13px',
    background: '#3DBE7233',
    border: '1px solid #3DBE72',
    boxShadow: 'none',
    marginRight: 10,
    width: '150px',
    '&:hover': {
        background: '#3DBE7233',
        border: '1px solid #3DBE72',
        boxShadow: 'none',
    }
}))

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: 'white',
    marginRight: '8px', // Some spacing between spinner and text
}));

// UI Components
const FormTextField = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: 4,
        position: 'relative',
        fontSize: 16,
        margin: '0',
        padding: '0',
        transition: theme.transitions.create([
            'border-color',
            'box-shadow',
        ]),
        '& fieldset': {
            '& legend': {
                width: 'auto', // Adjust legend to not take extra space
                padding: '0',  // Ensure no padding on the legend
                display: 'none', // Hide the legend
            },
            top: 0,
            borderColor: theme.palette.primary.light,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
        '& .MuiInputBase-input': {
            padding: '10px 14px'
        }
    },
    '& .MuiInputLabel-root': {
        position: 'relative',
        transform: 'none',
        fontSize: '14px',
        fontWeight: 400,
        color: '#333',
        marginBottom: '4px',
        textAlign: 'left',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'primary', // Green border color
        padding: '0',
    },
    '& .MuiOutlinedInput-input': {
        padding: '10px 14px', // Adjust padding to match the image
    },
}));

// Styled ToggleButtonGroup
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    color: theme.palette.grey[800],
    // border: `1px solid ${theme.palette.primary.light}`,
    // boxShadow: `${alpha(theme.palette.primary.main, 0.1)} 0px 2px 4px`,
    '& .MuiToggleButtonGroup-grouped': {
        // border: '2px solid',
        '&.Mui-selected': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            border: '2px solid',
            borderColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.25),
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.65),
                // color: theme.palette.common.white,
            },
        },
        '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            color: theme.palette.action.disabled,
        },
    },
    '& .MuiToggleButton-root': {
        border: '1px solid ',
        borderColor: theme.palette.primary.light,
        borderRadius: 5,
        fontSize: 16,
        width: '48%',
        textTransform: 'none',
        // border: `2px solid red`,
        // color: theme.palette.primary.main,
        padding: '10px 14px',
        transition: theme.transitions.create([
            'border-color',
            'box-shadow',
        ], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));

const SignupForAddSocialMedia = () => {

    let navigate = useNavigate();

    let [searchParams, setSearchParams] = useSearchParams();

    // const [insta, setInsta] = useState('');
    // const [updateInstaLoader, setUpdateInstaLoader] = useState(false);
    // const [isInstaUpdate, setIsInstaUpdate] = useState({ status: false, message: "" });

    const [platform, setPlatform] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const [handle, setHandle] = useState('');
    const [avatar, setAvatar] = useState('');
    const [platformId, setPlatformId] = useState('');
    const [socialUname, setSocialUname] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const showNotification = (message, variant = 'default') => {
        enqueueSnackbar(message, { variant });
    };

    useEffect(() => {
        if (platform === 'youtube') {
            let ytHandle = searchParams.get('ythandle');
            if (ytHandle) {
                setHandle(ytHandle);
            }

            let ytAvatar = searchParams.get('ytavatar');
            if (ytAvatar) {
                setAvatar(ytAvatar);
            }

            let ytPlatformId = searchParams.get('ytid');
            if (ytPlatformId) {
                setPlatformId(ytPlatformId);
            }

            let ytUname = searchParams.get('ytuname');
            if (ytUname) {
                setSocialUname(ytUname);
            }
        }
    }, [searchParams, platform]);

    const handlePlatform = (
        event,
        platform,
    ) => {
        setPlatform(platform);
    };

    const handleSubmit = () => {
        console.log(platform, handle);
        let usermail = searchParams.get('useremail');
        let userid = searchParams.get('userid');
        let username = searchParams.get('username');

        if (platform === 'youtube') {
            let ytHandle = searchParams.get('ythandle');
            if (ytHandle != handle) {
                getUserSocialAccountInfo({ "ytHandle": handle }).then((res) => {
                    console.log(res);
                }).catch((err) => {
                    if (err.response.status === 401) {
                        navigate('/login');
                        showNotification('Please login to continue', { variant: 'error' })
                    } else if (err.response.status === 404) {
                        showNotification('Please enter a valid Youtube handle', { variant: 'error' });
                    } else {
                        showNotification(err);
                    }
                });
            }
        }

        if (platformId != '' && platformId != null && platformId != undefined) {
            let redirectlocation = `/confirm-social-media?useremail=${usermail}&userid=${userid}&username=${username}&platform=${platform}&handle=${handle}&socialuname=${socialUname}&platformid=${platformId}&profilephoto=${avatar}`;
            navigate(redirectlocation);
        }

    };

    // const handleInstagramId = (e) => {
    //     if (e.target.value == '') {
    //         setIsInstaUpdate({ status: false, message: "" })
    //     }
    //     setInsta(e.target.value)
    //     // console.log(insta);
    // }

    // const handleAddInstaAccount = async () => {
    //     setIsInstaUpdate({ status: false, message: "" })
    //     setUpdateInstaLoader(true);
    //     let payload = {
    //         "id": 21,
    //         "instagram_id": insta
    //     }
    //     const res = await addInstaAccount(payload);
    //     console.log(res);


    //     if (res.data.status === 201) {
    //         setIsInstaUpdate({ status: true, message: res.data.message })

    //     }
    //     setUpdateInstaLoader(false)
    // }

    return (
        <Container>
            <Box>
                <Box style={{ display: 'flex', justifyContent: 'center', padding: 0 }}>
                    <img src={Logo} alt="" width={170} />
                </Box>
                <Box>
                    <Typography style={{ fontWeight: 'bold', fontSize: 15, margin: '5px 0px' }}>Create Your Account</Typography>
                    <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Add a Social Media Platform for Influencer</Typography>
                </Box>
                <Stack style={{ margin: '2rem 0px' }} gap={2}>
                    <Box>
                        <Typography style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 10 }}>1. Add your Instagram Account</Typography>
                        {/* <p style={{ fontSize: 12, marginBottom: 5, color: '#3dbf73ff' }}>{isInstaUpdate.status && isInstaUpdate.message}</p> */}
                        <StyledToggleButtonGroup
                            value={platform}
                            exclusive
                            onChange={handlePlatform}
                            aria-label="Platform"
                        >
                            <ToggleButton value="youtube" aria-label="Youtube Platform">
                                <YouTube color='error' /> <Typography ml={0.6} component={'span'}>{'Youtube'}</Typography>
                            </ToggleButton>
                            <ToggleButton disabled value="instagram" aria-label="Instagram Platform">
                                <Instagram /> <Typography ml={0.6} component={'span'}>{'Instagram'}</Typography>
                            </ToggleButton>
                        </StyledToggleButtonGroup>
                    </Box>
                    <Box>
                        <Typography style={{ fontWeight: 'bold', fontSize: 15, marginBottom: 10 }}>2. Select an account</Typography>
                        <FormTextField
                            // {...field}
                            // margin="normal"
                            required
                            fullWidth
                            id="ytighandle"
                            // label="Full Name"
                            placeholder="Enter your Instagram/Youtube handle"
                            name="ytighandle"
                            autoComplete="handle"
                            variant='outlined'
                            disabled={!platform}
                            value={handle}
                            onChange={(e) => setHandle(e.target.value)}
                            // error={!!errors.name}
                            // helperText={errors.name?.message}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box textAlign={'end'}>
                        <Button variant='contained'
                            endIcon={isLoading && <LoadingSpinner size={25} />}
                            disabled={isLoading || !handle || !platform}
                            onClick={() => handleSubmit()}
                        >
                            Next
                        </Button>
                    </Box>

                </Stack>
            </Box>
        </Container>
    )
}

export default SignupForAddSocialMedia