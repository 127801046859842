import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;

export const getAllBrands = async () => {
  try {
    // const response = await axios.post(`${URL}/getallbrands`);
    const response = await axios.post(`${URL}/brand/list`);
    console.log("get all brands response: ", response);
    //   const data = response.data.users;
    const data = response.data.Brands;
    return data;
  } catch (error) {
    console.error(
      "error occurs while fetch all brands data : " + error.message
    );
  }
};
