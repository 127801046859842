import React, { useState } from 'react'

import { Typography, Box, styled, TextField, Select, MenuItem, FormControl, Divider } from '@mui/material'
import Grid from '@mui/material/Grid2';

import instaIcon from '../../../images/insta-select-icon.png'


import { InputLabel, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';



const SearchBar = styled(Box)(({ theme }) => ({


    display: 'flex',
    alignItems: 'center',
    height: 45,
    marginTop: 30,
    marginBottom: 65,
    boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    borderRadius: 30,
    width: '45%',
    // background : yellow;
    [theme.breakpoints.down('md')]: {
        width: '90%',
    }

}))

const KeywordsInputField = styled(TextField)(({ theme }) => ({

    width: '450px',
    border: 'none',
    outline: 'none',
    background: '#FFFFFF',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,


    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: 'transparent', // Remove hover border effect
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent', // Remove focused border effect
        },
        '&.Mui-disabled fieldset': {
            borderColor: 'transparent', // Remove border for disabled state
        },
        '& fieldset': {
            border: 'none', // Remove default border
        },
    },

}))





const SearchSection = () => {

    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSubmitSearch = (event) => {
        // Implement your search logic here using selectedCategory and searchTerm
        event.preventDefault();
    };

    return (
        <SearchBar >
            <FormControl style={{ borderTopLeftRadius: 30, borderBottomLeftRadius: 30 }} sx={{ m: 1 }} size="small" >

                <Select

                    size='small'

                    sx={{
                        boxShadow: "none",
                        fontSize: '1.3rem',


                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,

                        },
                        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                            border: 0,


                        },

                    }}

                    defaultValue='instagram'

                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 15, }} value="instagram">
                        <img src={instaIcon} alt="" style={{ marginRight: 10 }} width="15px" />
                        Instagram
                    </MenuItem>
                    <MenuItem value="youtube">Youtube</MenuItem>
                    <MenuItem value="facebook">Facebook</MenuItem>
                </Select>
            </FormControl>
            <Divider orientation="vertical" variant="middle" flexItem />
            <KeywordsInputField
                size='small'
                placeholder='Type Names, Categories, Topics, Bio Keywords, #Anything!'

                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleSubmitSearch}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />

        </SearchBar>
        // <>

        //     <form onSubmit={handleSubmitSearch}>
        //         <FormControl sx={{ m: 1, minWidth: 120 }}>
        //             <InputLabel>Category</InputLabel>
        //             <Select
        //                 value={selectedCategory}
        //                 onChange={handleCategoryChange}
        //             >
        //                 <MenuItem value="all">All</MenuItem>
        //                 <MenuItem value="products">Products</MenuItem>
        //                 <MenuItem value="articles">Articles</MenuItem>
        //             </Select>
        //         </FormControl>
        //         <TextField
        //             label="Search"
        //             variant="outlined"
        //             type="text"
        //             value={searchTerm}
        //             onChange={handleSearchInputChange}
        //             InputProps={{
        //                 endAdornment: (
        //                     <InputAdornment position="end">
        //                         <IconButton onClick={handleSubmitSearch}>
        //                             <SearchIcon />
        //                         </IconButton>
        //                     </InputAdornment>
        //                 ),
        //             }}
        //         />
        //     </form>

        // </>

    )
}

export default SearchSection