import axios from "axios";

// const URL = "https://grapsbackend.tdpvista.co.in/api/v1";
const URL = process.env.REACT_APP_BASE_URL;

const handleErrorGlobal = (errortitle, error) => {
  console.error(errortitle, error);
};

export const registerUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/register`, data);

    console.log("register res:", response);

    const token = response.data.authorisation.token;

    localStorage.setItem("authToken", token);

    return response;
  } catch (error) {
    handleErrorGlobal("Error while registering user: ", error);
    throw error;
  }
};

export const sendVerificationMail = async (data) => {
  const email = {
    email: data.email,
  };
  try {
    const response = await axios.post(`${URL}/auth/otp/email/send`, email);

    return response;
  } catch (error) {
    handleErrorGlobal("Error while sending verification mail: ", error);
    throw error;
  }
};

export const verifyEmail = async (data) => {
  try {
    const response = await axios.post(`${URL}/auth/otp/email/verfiy`, data);

    return response;
  } catch (error) {
    handleErrorGlobal("Error while verifying email: ", error);
    throw error;
  }
};

export const sendMobileVerificationOTP = async (data) => {
  try {
    const response = await axios.post(`${URL}/auth/otp/phone/send`, data);

    return response;
  } catch (error) {
    handleErrorGlobal("error while sending mobile verification OTP: " + error);
    throw error;
  }
};

export const verifyMobileOTP = async (data) => {
  try {
    const response = await axios.post(`${URL}/auth/otp/phone/verfiy`, data);

    return response;
  } catch (error) {
    handleErrorGlobal(
      "error while verifying mobile verification OTP: " + error
    );
    throw error;
  }
};

export const ytGenerateOTP = async (data) => {
  try {
    const response = await axios.post(
      `${URL}/verfication/yt/otp/genrate`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );

    console.log(response);

    return response;
  } catch (error) {
    handleErrorGlobal(
      "error while generating OTP for the YT social account verification: " +
        error
    );
    throw error;
  }
};

export const ytVerifyOTP = async (data) => {
  try {
    const response = await axios.post(`${URL}/verfication/yt/verfiy`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    console.log(response);

    return response;
  } catch (error) {
    handleErrorGlobal(
      "error while verifying OTP for the YT social account verification: " +
        error
    );
    throw error;
  }
};

export const getUserSocialAccountInfo = async (data) => {
  try {
    const response = await axios.post(`${URL}/info/yt/handle`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    console.log(response);

    return response;
  } catch (error) {
    handleErrorGlobal(
      "error while getting basic social account info: " + error
    );
    throw error;
  }
};

export const checkEmail = async (myemail) => {
  const email = {
    email: myemail,
  };
  try {
    const response = await axios.post(`${URL}/userifexists`, email);
    return response;
  } catch (error) {
    handleErrorGlobal("error while checking email: " + error);
    throw error;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/login`, data);
    console.log(response);

    if (response.status === 200) {
      const token = response.data.authorisation.token;

      localStorage.setItem("authToken", token);
      return response;
    } else if (response.data.status == 401) {
      return response;
    }
  } catch (error) {
    handleErrorGlobal("Error while logging in: ", error);
    throw error;
  }
};

export const registerBrand = async (brandData) => {
  try {
    console.log("register brand data input data: ", brandData);

    const response = await axios.post(`${URL}/registeredbrand`, brandData, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });

    console.log("register brand data: ", response);

    // Customize the logic based on the expected response from the API
    if (response.status === 201) {
      // Handle success scenario, if needed
      return response;
    }
    // Handle other status codes if needed
  } catch (error) {
    handleErrorGlobal("Error while registering brand: ", error);
    throw error;
  }
};

export const registerEmployee = async (empData) => {
  try {
    const response = await axios.post(`${URL}/registeremployee`, empData);
    console.log(response);

    // Customize the logic based on the expected response from the API
    if (response.data.status === 201) {
      // Handle success scenario, if needed
      return response;
    }
    // Handle other status codes if needed
  } catch (error) {
    handleErrorGlobal("Error while registering employee: ", error);
    throw error;
  }
};

export const sendEmail = async (data) => {
  try {
    const response = await axios.post(`${URL}/email/send`, data);
    console.log(response);

    return response;

    // Handle other status codes if needed
  } catch (error) {
    handleErrorGlobal("Error while sending email: ", error);
    throw error;
  }
};

export const getApprovedStatus = async ({ id, inf_val, status }) => {
  try {
    const response = await axios.post(
      `${URL}/collection/influencer/update/status/get`,
      {
        id,
        inf_val,
        status,
      }
    );
    return response;
  } catch (error) {
    handleErrorGlobal("Error while getting approved status: ", error);
    throw error;
  }
};
export const addNewColumn = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/add`, data);
    console.log(response);
    return response;
  } catch (error) {
    handleErrorGlobal("Error while adding new column: ", error);
    throw error;
  }
};
export const addNewColumnData = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/cell/add`, data);
    console.log("response/customcolumn/cell/add", response);
    return response;
  } catch (error) {
    handleErrorGlobal("Error while adding new column data: ", error);
    throw error;
  }
};
export const getColumnHead = async () => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/get`);
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error while getting column heads: " + error);
  }
};
export const updateColumnHead = async (data) => {
  try {
    const response = await axios.post(`${URL}/customcolumn/head/update`, data);
    console.log(response);
    return response;
  } catch (error) {
    handleErrorGlobal("Error while updating column head: ", error);
    throw error;
  }
};

export const shareCollection = async (data) => {
  try {
    const response = await axios.post(`${URL}/shareaccess/create`, data.data);
    console.log(response);
    return response;
  } catch (error) {
    handleErrorGlobal("Error while sharing collection: ", error);
    throw error;
  }
};

export const getCollectionShareAcces = async (data) => {
  try {
    console.log("daata", data);
    const response = await axios.post(`${URL}/shareaccess/get`, data);
    console.log("responseresponse", response);
    return response.data;
  } catch (error) {
    handleErrorGlobal("Error while getting collection share access: ", error);
    throw error;
  }
};

export const updateCollectionShareAcces = async (data) => {
  try {
    const response = await axios.post(`${URL}/shareaccess/update`, data);
    return response;
  } catch (error) {
    handleErrorGlobal("Error while updating collection share access: ", error);
    throw error;
  }
};

export const genConfirmInstaOTP = async (id) => {
  try {
    const response = await axios.post(`${URL}/generateotpinstagram`, id);
    return response;
  } catch (error) {
    handleErrorGlobal(
      "Error while generating otp for insta verification : " + error.message
    );
    throw error;
  }
};

// export const genConfirmYoutubeOTP = async (id)  => {
//     try{
//         const response = await axios.post(`${URL}/generateotpinstagram`, id)
//         return response;
//     }
//     catch (error) {
//         console.error("Error while generating otp for insta verification : " + error.message);
//     }
// }
