import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        // any arial font 
        fontFamily: `'Google Sans' , sans-serif`,
    },
    palette: {
        primary: {
            main: '#3DBE72',
            contrastText: '#fff',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
            font-family: 'Google Sans';
            font-style: normal;
            font-weight: 400;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-Regular.ttf') format('truetype');
            }
            @font-face {
            font-family: 'Google Sans';
            font-style: italic;
            font-weight: 400;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-Italic.ttf') format('truetype');
            }
            @font-face {
            font-family: 'Google Sans';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-Medium.ttf') format('truetype');
            }
            @font-face {
            font-family: 'Google Sans';
            font-style: italic;
            font-weight: 500;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-MediumItalic.ttf') format('truetype');
            }
            @font-face {
            font-family: 'Google Sans';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-Bold.ttf') format('truetype');
            }
            @font-face {
            font-family: 'Google Sans';
            font-style: italic;
            font-weight: 700;
            font-display: swap;
            src: url('../src/fonts/GoogleSans-BoldItalic.ttf') format('truetype');
            }
        `
        },
    },
});



export default theme;
