import React from "react";
import { Box, Typography, Paper } from "@mui/material";
import Grid from '@mui/material/Grid2';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 2,
    },
    paper: {
        padding: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    brandLogo: {
        width: 40,
        height: 40,
        borderRadius: "50%",
        marginRight: 2,
    },
    brandDetails: {
        display: "flex",
        flexDirection: "column",
    },
    brandName: {
        fontWeight: "bold",
    },
    warningText: {
        color: "red",
        fontWeight: "bold",
        marginBottom: 2,
    },
}));

const BrandMentions = ({
    userdata,
    option
}) => {
    const classes = useStyles();

    const brands = [
        { name: "bookscape.com", views: "140K total views", logo: "logo1.png" },
        { name: "flipkart.com", views: "140K total views", logo: "logo2.png" },
        { name: "t.ly", views: "140K total views", logo: "logo3.png" },
        { name: "RG Vikramjeet", views: "140K total views", logo: "logo4.png" },
    ];

    return (
        <Box className={classes.root}>
            <Typography variant="h6" className={classes.warningText}>
                BRAND MENTIONS In THE PAST {userdata["Brand Mentions (In the past months)"]}
            </Typography>
            <Typography variant="h4" color="error">
                Total Brand Mentions: {userdata["Brand Mentions"] || 0}
            </Typography>
            {/* <Typography variant="body1">
                The influencer posts advertisements too rarely, much less frequently
                than influencers of similar size. This makes it difficult to predict
                the effectiveness and impact of your advertisement
            </Typography> */}

            {/* <Grid container spacing={2} mt={2}>
                {brands.map((brand, index) => (
                    <Grid item xs={6} key={index}>
                        <Paper className={classes.paper}>
                            <img
                                src={brand.logo}
                                alt={brand.name}
                                className={classes.brandLogo}
                            />
                            <Box className={classes.brandDetails}>
                                <Typography className={classes.brandName}>
                                    {brand.name}
                                </Typography>
                                <Typography>{brand.views}</Typography>
                            </Box>
                        </Paper>
                    </Grid>
                ))}
            </Grid> */}
        </Box>
    );
};

export default BrandMentions;
