import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL;

export const getAllLocations = async () => {
  try {
    const response = await axios.post(`${URL}/additional/available/locations`);
    console.log("response from server location api: ", response.data);
    if (response.status === 200) {
      const data = response.data;
      if (data) return data;
      else {
        console.error("Error: locations array is empty");
        return false;
      }
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return false;
    }
  } catch (error) {
    console.error("Error occured while fetching locations" + error.message);
  }
};
