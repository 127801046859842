import { memo, useEffect, useMemo, useState } from "react";
import React from "react";

// MUI Components
import { Box, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";

// Icons
import { ExpandMore, HelpOutlineRounded } from "@mui/icons-material";
import NumericTextField from "../customInputComponents/textfield";

const SingleTextfield = memo(({ title, selector, tooltipInfo, setDataMethod, data, clearIt, placeholder = "" }) => {
    const [value, setValue] = useState('');

    useMemo(() => {
        if (data) {
            setValue(data.toString());
        }
        console.log("incoming data memo running");
    }, [data]);

    useEffect(() => {
        if (value !== '') {
            setDataMethod(value.toString().replace(/'/g, '"'));
        } else {
            setDataMethod(null);
        }
    }, [value]);

    const clearMinMax = () => {
        setValue('');
    };

    useEffect(() => {
        clearIt(() => clearMinMax);
    }, [clearIt]);

    return (
        <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <IconButton style={{ color: "#3DBE72" }}>
                        <Tooltip title={tooltipInfo}>
                            <HelpOutlineRounded
                                style={{ fontSize: 14, padding: 0, margin: 0 }}
                            />
                        </Tooltip>
                    </IconButton>
                    <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, textAlign: "start" }}>
                <NumericTextField
                    value={value}
                    onChange={setValue}
                    placeholder={placeholder}
                    fullWidth
                />
            </AccordionDetails>
        </Accordion>
    );
}, (prevProps, nextProps) => {
    return prevProps.setDataMethod === nextProps.setDataMethod && prevProps.clearIt === nextProps.clearIt;
});

export default SingleTextfield;
