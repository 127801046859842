import React, { useEffect, useState } from "react";
import {
  TextField,
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";


const SearchDropdownFilter = ({ text, options, clear, setBrand }) => {
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (clear) setValue("");
  }, [clear]);

  useEffect(() => {
    if (value) setBrand(value);
  }, [value]);
  return (
    <>
      <Autocomplete
        value={value}
        sx={{
          width: {
            md: 250,
            xs: 150,
          },
          mr: 2,
          mt: 2,
        }}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        id="controllable-states-demo"
        options={options.map((option) => option.name)}
        renderInput={(params) => (
          <TextField {...params} placeholder="Enter Brand Name" label={text} />
        )}
      />
    </>
  );
};

export default SearchDropdownFilter;
