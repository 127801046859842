import { memo, useEffect, useMemo, useState } from "react";
import React from "react";

// MUI Components
import { Box, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";

// Icons
import { ExpandMore, HelpOutlineRounded } from "@mui/icons-material";

// Custom Components
import CustomMultiSelectWithTextfield from "../customInputComponents/multiSelectWithTextfield";

const MultiSelectWithAdd = memo(({ title, selector, tooltipInfo, label, placeholder, setDataMethod, data, clearIt, predefinedOptions = [] }) => {

    predefinedOptions = predefinedOptions.map((option, index) => {
        return { label: option, value: option };
    });

    // State to manage selected values
    const [selectedValues, setSelectedValues] = useState([]);

    useMemo(() => {
        if (data) {
            let updatedData = data.map((option, index) => {
                return { label: option, value: option };
            });
            setSelectedValues(updatedData);
        }
        console.log("incoming data memo running");
    }, [data]);

    const handleChange = (newValues) => {
        console.log('Selected values:', newValues);
        setSelectedValues(newValues);
    };

    useEffect(() => {
        selectedValues.length > 0 ? setDataMethod(selectedValues.map((option, index) => {
            return option.value;
        })) : setDataMethod(null);
    }, [selectedValues]);

    const clearMultiSelect = () => {
        setSelectedValues([]);
    };

    useEffect(() => {
        clearIt(() => clearMultiSelect);
    }, [clearIt]);

    return (
        <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <IconButton style={{ color: "#3DBE72" }}>
                        <Tooltip title={tooltipInfo}>
                            <HelpOutlineRounded
                                style={{ fontSize: 14, padding: 0, margin: 0 }}
                            />
                        </Tooltip>
                    </IconButton>
                    <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, textAlign: "start" }}>
                <CustomMultiSelectWithTextfield
                    options={predefinedOptions}
                    value={selectedValues}
                    onChange={handleChange}
                    label={label}
                    placeholder={placeholder}
                    // Optional props
                    error={false}
                    helperText=""
                    required={true}
                    fullWidth={true}
                    size="small"
                />
            </AccordionDetails>
        </Accordion>
    );
}, (prevProps, nextProps) => {
    return prevProps.setDataMethod === nextProps.setDataMethod && prevProps.clearIt === nextProps.clearIt;
});

export default MultiSelectWithAdd;