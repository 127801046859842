import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;

export const updateInfluencerCollection = async (data) => {
  try {
    const response = await axios.post(
      `${URL}/collection/updateinfluncer`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    const responseData = response.data;

    if (responseData) {
      console.log(responseData);
      return responseData;
    }
  } catch (error) {
    console.error(
      "error occurs while updating the influencer collection : " + error.message
    );
  }
};
