import React, { useState } from "react";
import { Box, IconButton, Typography, Menu, MenuItem, Input, List, ListItem, ListItemButton, Button } from "@mui/material";
import { ArrowDownwardSharp, ArrowUpward, DragIndicator, Search, Add } from "@mui/icons-material";

const HeaderRenderer = ({ columnName, columnTitle, handleHideColumn, handleShowAllColumns, handleAddColumn, handleSort, order, isCustomColumn }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchedUnderAdd, setSearchedUnderAdd] = useState([]);
    const [customColumn, setCustomColumn] = useState("");
    const [addOrDrag, setAddOrDrag] = useState("");
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);

    const handleSubMenuOpen = (event) => {
        setSubMenuAnchorEl(event.currentTarget);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };

    const handleMenuOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSearchedUnderAdd([]);
    };

    const addColumn = (columnName) => {
        handleAddColumn(columnName);
        handleSubMenuClose(); // Close the submenu after adding the column
    };

    const handleHideClick = () => {
        handleHideColumn(columnName);
    };

    const handleAddingColumnHead = async (columnName) => {
        // let columnId
        // const data = {
        //     columnName: columnName,
        //     for_table: "influencers",
        //     created_by: JSON.stringify(currUser),
        //     status: "show",
        //     for_id: "influencers",
        //     is_deleted: "false",
        // }
        // console.log("datacurrUser", data);
        // try {
        //     const response = await addNewColumn(data);
        //     console.log("response", response);
        //     columnId = response.data.head.id;
        // } catch (e) {
        //     console.log("error", e);
        // } finally {
        //     try {
        //         const response = await getColumnHead()
        //         console.log("responseresponse", response);
        //     } catch (e) {
        //         console.log("error", e);
        //     }
        //     handleAddColumn({ columnName, columnId, currUser });
        //     setCustomColumn("");
        // }
    }

    return (
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                display: "flex",
                // flexGrow: 1,
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                cursor: "pointer",
                // marginX: "0.5rem",
            }}
        >
            {/* <IconButton
                size="small"
                sx={{ p: 0 }}
                onClick={(e) => {
                    handleMenuOpen(e);
                    setAddOrDrag("Add");
                }}
            >
                <Add style={{
                    opacity: isHovered ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                }} />
            </IconButton> */}
            <IconButton
                size="small"
                sx={{ p: 0 }}
                onClick={(e) => {
                    handleMenuOpen(e);
                    setAddOrDrag("Drag");
                }}
            >
                <DragIndicator style={{
                    opacity: isHovered ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                }} />
            </IconButton>
            <Typography textTransform={'capitalize'} variant="body1" fontWeight={"bold"}>{columnTitle}</Typography>
            <IconButton
                style={{
                    opacity: isHovered ? 1 : 0,
                    transition: "opacity 0.3s ease-in-out",
                }}
                size="small"
                onClick={() => {
                    handleSort(columnName);
                }}
            >
                {order ? <ArrowDownwardSharp /> : <ArrowUpward />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {addOrDrag == "Add" ? (
                    <Box margin={"1rem"}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Input
                                placeholder="Search"
                                startAdornment={<Search />}
                                sx={{
                                    width: "200px",
                                    borderRadius: 20,
                                    // border: "1px solid #3DBE72",
                                }}
                                onChange={(e) => {
                                    let searched = [];
                                    // let searched = combinedColumns.filter((combinedColumns) =>
                                    //     combinedColumns.columnName.props.columnName
                                    //         .toLowerCase()
                                    //         .includes(e.target.value.toLowerCase())
                                    // );
                                    if (e.target.value === "") {
                                        searched = [];
                                        setCustomColumn("");
                                    }
                                    if (e.target.value != "" && searched.length == 0) {
                                        setCustomColumn(e.target.value);
                                    }
                                    setSearchedUnderAdd(searched);
                                }}
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {customColumn != "" ? (
                                    <Button
                                        onClick={() => {
                                            handleAddingColumnHead(customColumn);
                                        }}
                                    >
                                        Add
                                    </Button>
                                ) : null}
                            </Box>
                        </Box>
                        <Box>
                            <List>
                                {searchedUnderAdd.map((columns) => (
                                    <ListItem>
                                        <ListItemButton>
                                            {searchedUnderAdd.length > 0
                                                ? columns.columnName
                                                : "No data found"}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                ) : (
                    <div>
                        <MenuItem onClick={handleHideClick}>Hide</MenuItem>
                        <MenuItem onClick={handleShowAllColumns}>
                            Show all hidden columns
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </Box>
    );
};

export default HeaderRenderer;