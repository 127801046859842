import React from 'react'

import { Box, Typography, FormControl, Select, InputLabel, ListItemIcon, MenuItem, styled } from '@mui/material'
import Grid from '@mui/material/Grid2';
import ImageIcon from '@mui/icons-material/Image';
import MovieIcon from '@mui/icons-material/Movie';
import { Form } from 'react-router-dom';


const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
  };

  const Bar = styled(FormControl)(({theme}) => ({
    width : '100%'
  }))

const SelectComponent = () => {

    const [selectedOption, setSelectedOption] = React.useState('option1');

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <Bar size='small'>
            {/* <InputLabel id="select-label">Select an option</InputLabel> */}
            <Select
                labelId="select-label"
                id="select"
                value={selectedOption}
                onChange={handleChange}
                style={{background : '#eeeeee', borderRadius : 20}}
            // startAdornment={
            //   <ListItemIcon>
            //     <ImageIcon />
            //   </ListItemIcon>
            // }
            >
                {/* <MenuItem value="" disabled>
          <em>None</em>
        </MenuItem> */}
                <MenuItem value="option1" style={menuItemStyle}>
                    <ListItemIcon>
                        <MovieIcon />
                    </ListItemIcon>Option 1</MenuItem>
                <MenuItem value="option2" style={menuItemStyle}>
                    <ListItemIcon>
                        <MovieIcon />
                    </ListItemIcon>Option 2</MenuItem>
                <MenuItem value="option3" style={menuItemStyle}>
                    <ListItemIcon>
                        <MovieIcon />
                    </ListItemIcon>Option 3</MenuItem>
            </Select>
        </Bar>

    )
}

export default SelectComponent