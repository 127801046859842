import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  InputAdornment,
  Dialog,
  Avatar,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useParams } from "react-router-dom";
// import { Box } from '@mui/system'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountAnalytics from "./AccountAnalytics";
import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";
import ShareIcon from "../../../images/directionRight.png";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import Bio from "./Bio";
import BasicTabs from "./BasicTabs";
import { getUserByID } from "../../../../service/user/getUserByID";
import {
  AddRounded,
  Instagram,
  Search,
  ShareRounded,
  YouTube,
} from "@mui/icons-material";
import Card1 from "./Card1";
import Card2 from "./Card2";
import Card3 from "./Card3";
import Card4 from "./Card4";
import CustomCarousel from "./CustomCarousel";
import CustomCarousel1 from "./CustomCarousel1";
import ShareCollection from "../../collection/ShareCollection";
import ShareInfluencers from "./ShareInfluencers";
import ProfileCarousel from "./ProfileCarousel";
import { useSelector } from "react-redux";

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: "white",
  background: "black",
  fontSize: ".8rem",
  padding: "2px 5px",
  "&:hover": {
    color: "white",
    background: "black",
  },
}));

const Profile = (props) => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decodedId : ", decodedId);


  const getUser = async () => {
    const user = await getUserByID({ id: decodedId });
    console.log("user from profile page : ", user);
    setUser(user);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("Search Term Updated:", event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const IsOpen = useSelector((state) => state.sharedState.isOpen);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // const handleResize = () => {
  //   setScreenWidth(window.innerWidth);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  // const [shareCollectionDrawer, setShareCollectionDrawer] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  function removeEmailsAndPhones(text) {
    // Regular expression to match email addresses
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Regular expression to match phone numbers (generic pattern for digits with optional separators)
    const phonePattern = /(\+?\d{1,4}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/g;

    // Replace email addresses and phone numbers with an empty string
    return text?.replace(emailPattern, '')?.replace(phonePattern, '');
  }

  function formatNumber(num) {
    try {
      const strNum = num.toString();

      if (strNum.endsWith("000000")) {
        return strNum.replace(/000000$/, 'M'); // Replace trailing six zeros with 'M'
      } else if (strNum.endsWith("000")) {
        return strNum.replace(/000$/, 'K'); // Replace trailing three zeros with 'K'
      } else {
        return num.toString(); // Return the number as is
      }

    } catch (error) {
      return "error"
    }

  }


  return (
    <Box container padding={2} paddingX={6}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: 2,
          gap: 2,
        }}
      >
        {/* <TextField
          size="small"
          outline="false"
          placeholder="Search Influencers..."
          value={searchTerm} // Bind the searchTerm state to the TextField
          onChange={handleSearchChange} // Handle changes in the TextField
          sx={{
            width: "50%",
            borderRadius: 8,
            borderColor: "black",
            "& .MuiOutlinedInput-root": {
              borderRadius: 8,
              "&:hover fieldset": {
                borderColor: "black",
              },
              "&.Mui-focused fieldset": {
                borderColor: "black",
              },
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
        {/* <Button
          sx={{
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            textTransform: "none",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "2px",
            width: "40%",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
        // onClick={handleOpen}
        >
          Add to campaign
        </Button> */}
        <Button
          sx={{
            paddingX: 8,
            fontWeight: "bold",
            color: "#3dbf73ff",
            borderRadius: 20,
            borderColor: "#3dbf73ff",
            "&:hover": {
              borderColor: "#3dbf73ff",
              borderWidth: "2px",
            },
            borderWidth: "1px",
          }}
          variant="outlined"
          startIcon={<AddRounded />}
        // onClick={handleOpen}
        >
          Add to collection
        </Button>
        <Button
          variant="contained"
          sx={{
            paddingX: 4,
            background: "#1f2933ff",
            fontWeight: "bold",
            borderRadius: 8,
            boxShadow: "none",
            "&:hover": {
              background: "#1f2933ff",
            },
          }}
        >
          Edit
        </Button>
        <IconButton sx={{ color: "#3dbf73ff" }}>
          <ShareRounded
            onClick={handleClickOpen}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          />
        </IconButton>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: { borderRadius: 5, border: "2px solid black" },
          }}
        >
          <ShareInfluencers sharePopUp={setOpen} />
        </Dialog>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          paddingX={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {user?.photo ? (
            <Avatar
              src={user?.photo}
              alt={user?.name}
              sx={{ width: 150, height: 150, borderRadius: "50%", border: 3, borderColor: "#3DBE72" }}
            />
          ) : (
            <Avatar
              src={user?.photo_link}
              alt={user?.name}
              sx={{ width: 150, height: 150, borderRadius: "50%", border: 3, borderColor: "#3DBE72" }}
            />
          )}
        </Box>
        <Box sx={{ padding: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                }}
              >
                {user?.name}
              </Typography>
            </Box>
            <Box>
              <IconButton href={`https://www.youtube.com/channel/${user?.channel_id}`} target="_blank" rel="noopener noreferrer" sx={{ color: "#FF0000" }}>
                <YouTube sx={{
                  width: 32,
                  height: 32,
                }} />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.3rem",
                color: "#3dbf73ff",
                fontWeight: "bold",
              }}
            >
              {user?.handle ? user.handle : ""}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Subscribers:</b> {user?.subscriber ? formatNumber(user.subscriber)?.trim() : 0}
              {/* Followers
                  Location
                  Account type of the profile, such as Personal, Creator or Business
                  Gender of the profile
                  Bio
                  Content Categories
                  Hashtags */}
            </Typography>
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Location:</b> {user?.location ? user.location?.trim() : ""}
            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Account: {user.AccountType ? user.AccountType : "Account"}
            </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Gender:</b> {user?.gender ? user.gender?.trim() : ""}
            </Typography>
            {/* <Typography
                    sx={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    {user.Bio ? user.Bio : "Bio"}
                  </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Category:</b> {user?.topics ? JSON.parse(user?.topics?.replace(/'/g, '"'))[0]?.trim() : ""}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Tags:</b> {user?.Hashtags?.split(", ")?.length > 0 ? user?.Hashtags?.split(", ").join(", ") : ""}
              <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                {user?.keywords ? (() => {
                  const parsedTopics = JSON.parse(user?.keywords?.replace(/'/g, '"'));
                  return parsedTopics.length > 0 ? parsedTopics.slice(1, 3).join(', ') : "";
                })() : ""}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box >
      <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 3,

          marginTop: 4,
        }}
      >
        <Typography>
          {user?.description ? removeEmailsAndPhones(user.description) : "Bio"}
        </Typography>

        <Typography sx={{ fontWeight: "bold" }}>
          {user?.["List of top hashtags used by the creator"] ? user["List of top hashtags used by the creator"] : ""}
        </Typography>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 5,
          marginTop: 10,
          mx: "auto",
          overflow: "hidden",
          maxWidth: IsOpen ? "92vw" : "78vw",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {!IsOpen ? (
          <ProfileCarousel
            value={
              screenWidth > 1442
                ? 5
                : screenWidth > 1024
                ? 5
                : screenWidth > 768
                ? 3
                : 1
            }
          />
        ) : (
          <ProfileCarousel value={5} />
        )}
      </Box> */}
      <Grid item size={12}>
        <BasicTabs userdata={user} />
      </Grid>
    </Box >
  );
};

export default Profile;
