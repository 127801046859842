import {
  Button,
  IconButton,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useState } from "react";
import {
  CloudDownloadOutlined,
  HelpOutlineOutlined,
  FiberManualRecord,
} from "@mui/icons-material";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AccountAnalytics from "./AccountAnalytics";
import AssignedCampaign from "./AssignedCampaign";
import CollectionAnalytics from "./CollectionAnalytics";
import { BarChart } from "@mui/x-charts/BarChart";
import CollectionChartsAnalytics from "./CollectionChartsAnalytics";
import Stack from "@mui/material/Stack";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

// bar chart data

const languageData = [
  { data: [35, 25, 20, 10, 10] },
];

const languageSubData1 = [
  { label: "Hindi", value: 3500 },
  { label: "English", value: 2500 },
  { label: "Marathi", value: 2000 },
  { label: "Tamil", value: 1000 },
  { label: "Gujrati", value: 1000 },
];

const languageSubData2 = ["Hindi", "English", "Marathi", "Tamil", "Gujrati"];

const audienceDataa = [
  { data: [4.20] },
];

const audienceSubData1 = [
  { label: "Overall", value: 4200 },
]

const audienceSubData2 = ["Overall"];

const ageData = [
  { data: [60, 25, 15] },
];

const ageSubData1 = [
  { label: "18-24", value: 6000 },
  { label: "25-35", value: 2500 },
  { label: "36-45", value: 1500 },
];
const ageSubData2 = [
  "18-24",
  "25-35",
  "36-45",
];

const locationData = [
  { data: [55, 20, 15, 5, 5] },
];

const locationSubData1 = [
  { label: "India", value: 5500 },
  { label: "Canada", value: 2000 },
  { label: "UK", value: 1500 },
  { label: "Australia", value: 500 },
  { label: "UAE", value: 500 },
];

const locationSubData2 = [
  "India",
  "Canada",
  "UK",
  "Australia",
  "UAE",
];

const interestData = [
  { data: [5500, 3000, 1500] },
];

const interestSubData1 = [
  { label: "Makeup", value: 5500 },
  { label: "Beauty", value: 3000 },
  { label: "Tutorials", value: 1500 },
];

const interestSubData2 = ["Makeup", "Beauty", "Tutorials"];

function ChartsOverview({ data, subdata1, subdata2 }) {
  return (
    <>
      <BarChart
        series={data}
        height={290}
        xAxis={[{ data: subdata2, scaleType: "band" }]}
        margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
      />
      {subdata1.map((subdata) => {
        return <CustomizedProgressBars data={subdata} />;
      })}
    </>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
  },
}));

function CustomizedProgressBars({ data }) {
  return (
    <Stack spacing={2} sx={{ flexGrow: 1, padding: "10px 40px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography>{data.label}</Typography>
        <Typography>{data.value / 100} %</Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={data.value / 100} />
    </Stack>
  );
}

// pie chart data

const data2 = [
  { label: "Male", value: 3800 },
  { label: "Female", value: 6200 },
  // { label: "Lesbian", value: 1398 },
  // { label: "Gay", value: 2400 },
  // { label: "Binary", value: 3908 },
  // { label: "Non-binary", value: 4800 },
];

function PieChart1() {
  return (
    <Box>
      <PieChart
        series={[
          {
            data: data2,
            cx: 250,
            cy: 80,
            innerRadius: 40,
            outerRadius: 80,
            color: [
              { "#FF69B4": "#FF69B4" },
              { "#33CC33": "#33CC33" },
              { "#6666FF": "#6666FF" },
              { "#CCCCCC": "#CCCCCC" },
              { "#FF9900": "#FF9900" },
              { "#111": "#111" },
            ],
          },
        ]}
        height={170}
        slotProps={{
          legend: { hidden: true },
        }}
      />
      {data2.map((data) => {
        return <CustomizedProgressBars data={data} />;
      })}
    </Box>
  );
}

const AnalyticsYoutube = () => {
  // analyticsYoutube data
  const overviewData = [
    {
      title: "Total Influencers of the collection",
      desc: "15",
    },
    {
      title: "Total Subscribers of the collection",
      desc: "1.5M",
    },
    {
      title: "Estimated Total Reach of the collection (Long)",
      desc: "1.1M",
    },
    {
      title: "Estimated Total Reach of the collection (Shorts)",
      desc: "8M",
    },
    {
      title: "Estimated Total Likes of the collection (Long)",
      desc: "108k",
    },
    {
      title: "Estimated Total Likes of the collection (Shorts)",
      desc: "110k",
    },
    {
      title: "Estimated Total Comments of the collection (Shorts)",
      desc: "9.7k",
    },
    {
      title: "Estimated Total Comments of the collection (Shorts)",
      desc: "12k",
    },
  ];

  const EstimatedEngmData = [
    {
      title: "Estimated Total Engagement Rate of the collection",
      desc: "14.5",
    },
    {
      title: "Estimated Total Engagement (Long)",
      desc: "7.2",
    },
    {
      title: "Estimated Total Engagement (Shorts)",
      desc: "1.3",
    },
    {
      title: "Total Campaign Engagement",
      desc: "239k",
    },
  ];

  const audienceData = [
    {
      title: "Language of the audience of collection",
      text: "Language percentage of the audience of all the influencers of the collection ",
      chart: (
        <ChartsOverview
          data={languageData}
          subdata1={languageSubData1}
          subdata2={languageSubData2}
        />
      ),
    },
    {
      title: "Gender of the audience of collection",
      text: "Gender percentage of the audience of all the influencers of the collection",
      chart: <PieChart1 />,
    },
    {
      title: "Age group of the audience of collection",
      text: "Age Group percentage of the audience of all the influencers of the collection",
      chart: (
        <ChartsOverview
          data={ageData}
          subdata1={ageSubData1}
          subdata2={ageSubData2}
        />
      ),
    },
    {
      title: "Location of the audience of collection",
      text: "Location percentage of the audience of all the influencers of the collection (By Country & City)",
      chart: (
        <ChartsOverview
          data={locationData}
          subdata1={locationSubData1}
          subdata2={locationSubData2}
        />
      ),
    },
    {
      title: "Interest of the audience of collection",
      text: "Interest percentage of the audience of all the influencers of the collection",
      chart: (
        <ChartsOverview
          data={interestData}
          subdata1={interestSubData1}
          subdata2={interestSubData2}
        />
      ),
    },
    {
      title: "Audience Overlap",
      text: "Percentage of audience overlap of all the influencers (Number/Text/Data)",
      chart: (
        <ChartsOverview
          data={audienceDataa}
          subdata1={audienceSubData1}
          subdata2={audienceSubData2}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%", padding: 2 }}>
        <Paper elevation={1}>
          <Grid container p={3} mb={3}>
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                Estimate Reach Youtube
              </Typography>
            </Grid>
            <Grid item md={8} xs={12} mt={2}>
              <Grid container>
                <Grid item md={3} xs={3} align={"center"}>
                  <div style={{ width: 120, height: 120 }}>
                    <CircularProgressbar
                      value={66}
                      text="33.45k"
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#3dbf73ff`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d8f2e3ff",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "black",
                          // Text size
                          fontSize: "16px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                  <Typography sx={{ fontWeight: "bold", color: "grey", mt: 1 }}>
                    Long Video Reach
                  </Typography>
                </Grid>
                <Grid item md={3} xs={3} align={"center"}>
                  <div style={{ width: 120, height: 120 }}>
                    <CircularProgressbar
                      value={66}
                      text="33.45k"
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#3dbf73ff`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d8f2e3ff",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "black",
                          // Text size
                          fontSize: "16px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                  <Typography sx={{ fontWeight: "bold", color: "grey", mt: 1 }}>
                    Shorts Reach
                  </Typography>
                </Grid>
                {/* <Grid item md={3} xs={3} align={"center"}>
                  <div style={{ width: 120, height: 120 }}>
                    <CircularProgressbar
                      value={66}
                      text="33.45k"
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#3dbf73ff`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d8f2e3ff",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "black",
                          // Text size
                          fontSize: "16px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                  <Typography sx={{ fontWeight: "bold", color: "grey", mt: 1 }}>
                    Story Reach
                  </Typography>
                </Grid> */}
                <Grid item md={3} xs={3} align={"center"}>
                  <div style={{ width: 120, height: 120 }}>
                    <CircularProgressbar
                      value={66}
                      text="33.45k"
                      styles={{
                        // Customize the root svg element
                        root: {},
                        // Customize the path, i.e. the "completed progress"
                        path: {
                          // Path color
                          stroke: `#3dbf73ff`,
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Customize transition animation
                          transition: "stroke-dashoffset 0.5s ease 0s",
                          // Rotate the path
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                          // Trail color
                          stroke: "#d8f2e3ff",
                          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                          strokeLinecap: "butt",
                          // Rotate the trail
                          transform: "rotate(0.25turn)",
                          transformOrigin: "center center",
                        },
                        // Customize the text
                        text: {
                          // Text color
                          fill: "black",
                          // Text size
                          fontSize: "16px",
                        },
                        // Customize background - only used when the `background` prop is true
                        background: {
                          fill: "#3e98c7",
                        },
                      }}
                    />
                  </div>
                  <Typography sx={{ fontWeight: "bold", color: "grey", mt: 1 }}>
                    Avg. Shorts plays
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item md={4} xs={12} mt={2}>
                        <label htmlFor="feedback">Feedback</label>
                        <br />
                        <textarea placeholder='please write your feedback here...' rows={4} type="text" name="" id="feedback" style={{ borderRadius: 8, marginTop: 8, width : '100%', border : 'none', background :'#dfe6e0', padding : '15px' }} />
                    </Grid> */}
          </Grid>
        </Paper>
        {/* <AccountAnalytics /> */}
        <CollectionAnalytics heading="Overview" data={overviewData} />
        <CollectionAnalytics
          heading="Estimated Engagement"
          data={EstimatedEngmData}
        />
        <CollectionChartsAnalytics heading="Audience" data={audienceData} />
        <AssignedCampaign />
      </Box>
    </>
  );
};

export default AnalyticsYoutube;
