import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;

export const createNewCollection = async (data) => {
  try {
    const response = await axios.post(`${URL}/collection/create`, data);
    console.log("new collection fdsc ", response);

    if (response.status === 201) {
      const data = response.data.Collection;
      if (data) {
        console.log("created collection is : ", data);
        return data;
      } else {
        console.error("Error: collections is empty");
        return false;
      }
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while create new collections : " + error.message
    );
  }
};

export const updateCollection2 = async (collectionData) => {
  try {
    const response = await axios.post(
      `${URL}/updatecollection`,
      collectionData
    );
    if (response.status === 200) {
      if (response?.data?.collection) {
        console.log("updated collection is : ", response?.data?.collection);
        return response?.data?.collection;
      } else return false;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while updating the collection data : " + error.message
    );
  }
};

export const updateCollection = async (data) => {
  try {
    const response = await axios.post(`${URL}/updatecollection`, data);
    if (response.data.status == 200) {
      const data = response.data.collection;
      if (data) {
        console.log("updated collection is : ", data);
        return data;
      } else {
        console.error("Error: collections is empty");
        return false;
      }
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return false;
    }
  } catch (error) {
    console.error("error occurs while updating collections : " + error.message);
  }
};
