import { createSlice } from '@reduxjs/toolkit';

const permissionsList = [
    "findInfluencer/filter/yt",
    "findInfluencer/searchbox",
    "findInfluencer/filter/ig",
    "findInfluencer/download/csv",
    "findInfluencer/download/pdf",
    "findInfluencer/advancefilter/yt/influencer",
    "findInfluencer/advancefilter/yt/audience",
    "findInfluencer/advancefilter/yt/engagement",
    "findInfluencer/advancefilter/yt/growth",
    "findInfluencer/table/customcolumn",
    "findInfluencer/table/customcolumn/add",
    "findInfluencer/table/customcolumn/view",
    "findInfluencer/table/customcolumn/edit",
    "findInfluencer/table/action/addtocampagin",
    "findInfluencer/table/action/addtocollection",
    "findInfluencer/table/action/hide",
    "findInfluencer/table/action/edit",
    "findInfluencer/table/action/share",
    "findInfluencer/popup/analytics",
    "findInfluencer/popup/addtocampaign",
    "findInfluencer/popup/addtocollection",
    "findInfluencer/popup/description"
];


const initialState = {
    roles: null,
    permissions: [...permissionsList],
    loading: false,
    error: null,
};

const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRoles(state, action) {
            state.roles = action.payload;
        },
        setPermissions(state, action) {
            state.permissions = action.payload;
        },
    },
});

export const { setRoles, setPermissions } = rolesSlice.actions;

export default rolesSlice.reducer;
