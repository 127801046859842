import React from 'react';
import { TextField, Box } from '@mui/material';
import { SwapHoriz } from "@mui/icons-material";

const MinMaxInput = ({
    minValue,
    maxValue,
    onMinChange,
    onMaxChange,
    minPlaceholder = 'min',
    maxPlaceholder = 'max',
    disabled = false,
    size = 'small',
    error = false,
    fullWidth = false,
    allowDecimals = false,
    allowNegative = false,
}) => {
    // Validate number input
    const validateNumber = (value) => {
        if (value === '') return true;
        if (allowDecimals) {
            return allowNegative
                ? /^-?\d*\.?\d*$/.test(value)
                : /^\d*\.?\d*$/.test(value);
        }
        return allowNegative
            ? /^-?\d*$/.test(value)
            : /^\d*$/.test(value);
    };

    // Handle min value change
    const handleMinChange = (event) => {
        const value = event.target.value;
        if (validateNumber(value)) {
            onMinChange(value);
        }
    };

    // Handle max value change
    const handleMaxChange = (event) => {
        const value = event.target.value;
        if (validateNumber(value)) {
            onMaxChange(value);
        }
    };

    // Validate min-max relationship
    const getError = (isMin) => {
        if (error) return true;
        if (minValue === '' || maxValue === '') return false;

        const min = parseFloat(minValue);
        const max = parseFloat(maxValue);

        if (isNaN(min) || isNaN(max)) return false;
        return isMin ? min > max : max < min;
    };

    return (
        <Box
            display="flex"
            gap={1}
            alignItems="center"
            sx={{ width: fullWidth ? '100%' : 'auto' }}
        >
            <TextField
                value={minValue}
                onChange={handleMinChange}
                placeholder={minPlaceholder}
                size={size}
                disabled={disabled}
                error={getError(false)}
                slotProps={{
                    htmlInput: {
                        inputMode: 'numeric',
                        pattern: allowDecimals
                            ? (allowNegative ? '-?[0-9]*[.]?[0-9]*' : '[0-9]*[.]?[0-9]*')
                            : (allowNegative ? '-?[0-9]*' : '[0-9]*'),
                    }
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '4px',
                    },
                    width: fullWidth ? '100%' : '120px',
                }}
            />
            <SwapHoriz
                size={20}
                sx={{ color: "#3dbe72" }}
            />
            <TextField
                value={maxValue}
                onChange={handleMaxChange}
                placeholder={maxPlaceholder}
                size={size}
                disabled={disabled}
                error={getError(false)}
                slotProps={{
                    htmlInput: {
                        inputMode: 'numeric',
                        pattern: allowDecimals
                            ? (allowNegative ? '-?[0-9]*[.]?[0-9]*' : '[0-9]*[.]?[0-9]*')
                            : (allowNegative ? '-?[0-9]*' : '[0-9]*'),
                    }
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '4px',
                    },
                    width: fullWidth ? '100%' : '120px',
                }}
            />
        </Box>
    );
};

export default MinMaxInput;