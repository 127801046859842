import React from 'react'

import { useState } from 'react';
import { Typography, Box, Icon, IconButton, Button, Tooltip, Dialog, styled, TextField, List, ListItem, ListItemButton, ListItemText, InputAdornment, Divider, AvatarGroup, Avatar } from '@mui/material'

import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom'

import DP from '../../images/dp-lg.png'
import dp from '../../images/dp.png'
import insta from '../../images/insta-xsm.png'
import insta_sm from '../../images/insta-sm.png'
import youtube_sm from '../../images/youtube-sm.png'
import whatsapp from '../../images/WhatsApp.png'
import gmail from '../../images/Gmail.png'
import TurnRightRoundedIcon from '@mui/icons-material/TurnRightRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/Search';

import AccountAnalytics from '../collection/AccountAnalytics';

const TemptDP = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU'";

const PublicInfluencerProfile = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showDp, setShowDp] = useState(false);



    // const handleClickDP = () => {
    //     setShowDp(true)
    // }

    const handleClickDP = () => {
        setShowDp(true)
    }


    return (
        <Box style={{ padding: 20, }}>

            <Dialog open={showDp}>

                <img src={TemptDP} alt="" />

                <IconButton size='small' onClick={() => setShowDp(false)}>
                    <Tooltip title='Close' arrow

                        componentsProps={{
                            arrow: {
                                sx: {
                                    //   backgroundColor: 'red',
                                    color: 'red'
                                },
                            },
                            tooltip: {
                                sx: {
                                    color: "white",
                                    backgroundColor: "red",
                                    // fontSize: "0.9rem",

                                }

                            }
                        }}
                    >
                        <CancelOutlinedIcon />
                    </Tooltip>
                </IconButton>
            </Dialog>


            <Tooltip title='close'
                arrow
                componentsProps={{
                    arrow: {
                        sx: {
                            //   backgroundColor: 'red',
                            color: 'red'
                        },
                    },
                    tooltip: {
                        sx: {
                            color: "white",
                            backgroundColor: "red",
                            // fontSize: "0.9rem",

                        }

                    }
                }}
            >
                <IconButton onClick={() => {
                    // close(false)
                }} style={{ position: 'absolute', right: 20, padding: 0 }}>
                    <CloseRoundedIcon style={{ padding: 0 }} />
                </IconButton>
            </Tooltip>


            <Typography style={{ color: '#3DBE72', fontWeight: 'bold', fontSize: '1.6rem', marginBottom: '1.5rem' }} >Find Influencer</Typography>

            <Grid container sx={{ marginTop: '30px' }}>
                <Grid item md={1.3} xs={3}>


                    <Box
                        position="relative"
                        onMouseEnter={() => setShowOverlay(true)}
                        onMouseLeave={() => setShowOverlay(false)}
                        onClick={handleClickDP}
                        style={{ cursor: 'pointer', height: 150, width: 150 }}
                    >


                        <img

                            src={TemptDP}
                            alt=""
                            width="100%"
                            style={{ borderRadius: 8 }}
                        />
                        {showOverlay && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '40%',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    // borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 1,


                                }}
                            >
                                {/* Your overlay content goes here */}
                                <span style={{ color: 'green', fontSize: '0.8rem', fontWeight: 'bold' }}>View</span>
                            </div>
                        )}


                    </Box>

                </Grid>
                <Grid item md={10} sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '20px', justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Box>
                            <Box style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem' }}>{"Abhishek Malviya"}</Typography>
                                <Typography style={{ fontSize: 10, color: '#7b7b7b' }}>{"10M"} total audience (India)</Typography>
                            </Box>
                        </Box>
                        <Box style={{ marginLeft: 10 }} >
                            <IconButton style={{ color: "#3DBE72", padding: 5, border: '1px solid #3DBE72' }} aria-label="add to shopping cart">
                                <TurnRightRoundedIcon style={{ fontSize: 29, }} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: 15, marginBottom: 5 }}>
                        {/* <Button onClick={() => setShowCampaign(true)} variant="outlined" style={{ padding: '5px 20px', borderRadius: 20, fontSize: '11px', color: "#3DBE72", fontWeight: 'bold', textTransform: 'none', marginRight: '10px', '&:hover': { borderColor: '#3DBE72' } }}>Add to Campaign</Button> */}

                        <Button size='small' variant="contained" style={{ borderRadius: 20, fontSize: '11px', color: "white", fontWeight: 'bold', background: '#3DBE72', textTransform: 'none', boxShadow: 'none' }}>Share</Button>

                        {/* <IconButton onClick={() => setShowCollection(true)} style={{ color: "#3DBE72" }} aria-label="add to shopping cart">
                            <AddCircleRoundedIcon style={{ padding: 0, fontSize: 25 }} />
                        </IconButton> */}
                    </Box>
                    <Box>
                        <IconButton size='small'>
                            <img src={insta_sm} alt="" />
                        </IconButton>
                        <IconButton size='small'>
                            <img src={youtube_sm} alt="" width={25} />
                        </IconButton>
                        <IconButton size='small'>
                            <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png'} alt="" width={20} />
                        </IconButton>
                        <IconButton size='small'>
                            <img src={'https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png'} alt="" width={25} />
                        </IconButton>
                    </Box>
                </Grid>

            </Grid>


            <Box style={{ background: '#F2F7FF', borderRadius: 20, padding: 30, marginTop: 30 }}>
                <Box style={{ display: 'flex', alignItems: 'center', }} >
                    <img src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU'} alt="" width="40px" style={{ borderRadius: 50 }} />
                    <Box style={{ marginLeft: 15 }}>
                        <Typography style={{ fontWeight: 'bold', fontSize: '12px' }}>@{'abhishek_malviya'}</Typography>
                        <Typography style={{ fontSize: '10px' }}>{10} Million Followers</Typography>
                    </Box>
                </Box>
                <Typography style={{ fontSize: 12, margin: '10px 0' }}>Live life on your own terms #jointhemvmt Watches, Eyewear & Premium Accessori
                    es For her @mvmtforher Shop our Site</Typography>

                <Typography style={{ fontWeight: 'bold', fontSize: 12 }}  > #Live #life #jointhemvmt #Watches #Eyewear #Premium #Accessori
                    es </Typography>


            </Box>

            <AccountAnalytics avglikes={'3500'} avgcomments={'100k'} er={'18.2%'} />


        </Box>
    )
}

export default PublicInfluencerProfile