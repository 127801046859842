import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Drawer,
  Button,
  IconButton,
  styled,
  Tooltip,
  CircularProgress,
  Typography,
  Chip,
  Avatar,
  Checkbox,
  Dialog,
  TextField,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  AvatarGroup,
  ListItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DataTable from "react-data-table-component";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

// Icons
import CSVicon from "../../images/csv.png";
import PDFicon from "../../images/pdf.png";
import SBicon from "../../images/sb.png";
import LISTicon from "../../images/lst.png";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import {
  Add,
  AddTaskOutlined,
  CancelOutlined,
  Delete,
  Email,
  Phone,
  Reorder,
  Search,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

// Components
import CSVExportButton from "../subcomponents/findInfluencer/CSVExportButton ";
import TotalSelectedInfluencers from "../subcomponents/findInfluencer/TotalSelectedInfluencers";
import CustomMaterialPagination from "./customPagination";
import CustomActions from "../subcomponents/findInfluencer/CustomActions";
import HeaderRenderer from "./columnHeader/findInfluencerHeaderRenderer";
import { render } from "@testing-library/react";
import InfluencerProfile from "../subcomponents/findInfluencer/InfluencerProfile";
import {
  getAllCollection,
  getBrandList,
} from "../../../service/collections api/getAllCollection";
import { getUserByID } from "../../../service/user/getUserByID";
import { Link } from "react-router-dom";
import { createNewCollection } from "../../../service/collections api/createCollection";
import { updateInfluencerCollection } from "../../../service/collections api/updateInfluencer";
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import AddNewBrand from "../collection/AddNewBrand";

const TemptDP =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

// Custom UI Components
const Btn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  textTransform: "none",
  fontWeight: "bold",
  marginLeft: "10px",
  "&:hover": { borderColor: "#3DBE72" },
}));

const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
  color: "#FFFFFF",
}));

const MyInput = styled("input")(`
  background : #dce7f7ff;
  outlined : none;
  border : none;
  width : 100%;
  padding : 15px 10px;
  border-radius : 8px;
  font-size : 15px;
  margin-top : 5px;
`);

const MyTextArea = styled("textarea")(`
background : #dce7f7ff;
  outlined : none;
  border : none;
  width : 100%;
  padding : 15px 10px;
  border-radius : 8px;
  font-size : 15px;
  margin-top : 5px;
`);

const Mylabel = styled("label")(`
  color : #828282ff;
`);

const CustomAccounts = ({ row, setOpen }) => (
  <Box
    sx={{ pointerEvents: "none" }}
    display="flex"
    alignItems="center"
    mt={1}
    mb={1}
    width="max-content"
  >
    <Avatar
      alt={row.name}
      src={row.photo_link}
      sx={{ width: 50, height: 50 }}
    />
    <Box
      width={"100%"}
      sx={{
        textWrap: "nowrap",
      }}
    >
      <Typography ml={1} fontWeight={500} fontSize="16px">
        {row.name}
      </Typography>
      <Typography ml={1} color="gray" fontSize="14px">
        {row.handle}
      </Typography>
    </Box>
  </Box>
);
const Customcategory = ({ row }) => (
  <Box
    sx={{ pointerEvents: "none" }}
    display="flex"
    alignItems="center"
    mt={1}
    mb={1}
    width="auto"
  >
    <Chip
      sx={{ pointerEvents: "none", fontSize: "12px" }}
      label={row["Influencer Category"]}
      color="success"
      size="small"
    />
  </Box>
);
const CustomER = ({ row }) => (
  <span sx={{ pointerEvents: "none" }}>{row["Engagement Rate"]}</span>
);
const CustomSubscribers = ({ row }) => (
  <span sx={{ pointerEvents: "none" }}>{row.Subscribers}</span>
);
const AverageLike = ({ row }) => (
  <span sx={{ pointerEvents: "none" }}>{row["Channel Audience Likers"]}</span>
);
const AverageComment = ({ row }) => (
  <span sx={{ pointerEvents: "none" }}>{row["Average Comments"]}</span>
);

// Custom Styles
const customDatatableStyles = {
  head: {
    style: {
      fontSize: "13px", // Set the desired font size for the header
    },
  },
  // headRow: {
  //     style: {
  //         borderTopStyle: 'solid',
  //         borderTopWidth: '1px',
  //         borderTopColor: 'green',
  //     },
  // },
  // headCells: {
  //     style: {
  //         '&:not(:last-of-type)': {
  //             borderRightStyle: 'solid',
  //             borderRightWidth: '1px',
  //             borderRightColor: 'green',
  //         },
  //     },
  // },
  // cells: {
  //     style: {
  //         '&:not(:last-of-type)': {
  //             borderRightStyle: 'solid',
  //             borderRightWidth: '1px',
  //             borderRightColor: '#d8d8d8',
  //         },
  //     },
  // },
  rows: {
    style: {
      "&:hover": {
        ".rdt_TableCell:first-child": {
          visibility: "visible",
        },
      },
    },
  },
};

const FindInfluencerDatatable = ({
  fetchingInProgress = false,
  columnHeaders = [],
  fetchInfluencers,
  selectedSocial,
  influencerData,
  fetchWithFilter,
  isFilterApplied,
}) => {
  const user = useSelector((state) => state.user);
  const id = user?.user?.id;
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [influencerProfileGlipseOpen, setInfluencerProfileGlipseOpen] =
    useState(false);
  const [influencersView, setInfluencersView] = useState(true); // true for list view and false for card view
  const [selectedRowArr, setSelectedRowArr] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [isRowsSelected, setIsRowsSelected] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const IsOpen = useSelector((state) => state.sharedState.isOpen || false);
  const [downloading, setDownloading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [TotalHidden, setTotalHidden] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const [sortOrder, setSortOrder] = useState(false);
  const [showCollection, setShowCollection] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [pending, setPending] = useState(false);
  const [allCollection, setAllCollection] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [view, setView] = useState("1");
  const [aCollection, setaCollection] = useState({});
  const [createdBy, setCreatedBy] = useState({});

  const [loading, setLoading] = useState(false);
  // const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });

  const [collection, setCollection] = useState({
    brand_id: null,
    campaign_id: 1,
    status: "lorem",
    user_id: null,
    name: "",
    description: "",
    influencer_id: null,
    // assigned_brand_id: 1,
    // created_by: id,
  });

  const [searchText, setSearchText] = useState("");

  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [brandName, setBrandName] = useState("");

  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);

  const [newCollection, setNewCollection] = useState({});

  const [addBrandDialog, setAddBrandDialog] = useState(false);

  const [encodedNewCollectionId, setEncodedNewCollectionId] = useState("");

  const [allBrands, setAllBrands] = useState([]);

  const [collectionDesc, setCollectionDesc] = useState("");

  const [collectionName, setCollectionName] = useState("");

  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isAddedSuccessful, setIsAddedSuccessful] = useState(false);

  const [brandId, setBrandId] = useState(null);

  let influencerIds = selectedRowArr?.map(({ id }) => id);

  const handleListItemClick = (name, id) => {
    setBrandName(() => name);
    setBrandId(id);
    console.log("brand name ", brandName);
    console.log("brand id ", brandId);

    // setCollection((prevCollection) => {
    //   console.log("Updated collection:", {
    //     ...prevCollection,
    //     influencer_id: influencerIds,
    //     // assigned_brand_id: id,
    //     user_id: user?.user?.id,
    //     brand_id: brandId,
    //     name: collectionName,
    //     description: collectionDesc,
    //   });
    //   return {
    //     ...prevCollection,
    //     influencer_id: influencerIds,
    //     // assigned_brand_id: id,
    //     user_id: user?.user?.id,
    //     brand_id: brandId,
    //     name: collectionName,
    //     description: collectionDesc,
    //   };
    // });

    console.log("Clicked on:", name, "with ID:", id);
    console.log("payload collection :", collection);
  };

  const openAddBrandDialog = () => {
    setAddBrandDialog(true);
  };
  const closeAddBrandDialog = () => {
    setAddBrandDialog(false);
  };

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleDescChange = (event) => {
    console.log(event.target.value);
    setCollectionDesc(() => event.target.value);
  };

  const handleNameChange = (event) => {
    console.log(event.target.value);
    setCollectionName(() => event.target.value);
  };

  const tableRef = useRef(null);

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);

  useEffect(() => {
    // setSelectedRowArr([]);
    console.log("selected rows: ", selectedRowArr);

    if (influencerData?.data) {
      setInfluencers(influencerData.data);
      setTotalRows(influencerData.total);
      setPage(influencerData["current_page"]);
      setRowsPerPage(influencerData["per_page"]);
    }
  }, [influencerData, selectedRowArr]);

  // Influencer CRUD Functions
  const handleRemoveInfluencer = () => {};

  //get all collections;
  const getCollections = useCallback(async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      setAllCollection(response.collection);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  }, []);

  const closeAddToCollection = () => {
    setShowCollection(false);
    setIsAdded(false);
  };

  const closeAddToCampaign = () => {
    setShowCampaign(false);
    setIsAdded(false);
  };

  const handleAddToCollectionDialogOpen = () => {
    // Open the dialog
    setShowCollection(true);

    // Call the API function when the dialog is opened
    getCollections();
  };

  const handleAddToCampaignDialogOpen = () => {
    // Open the dialog
    setShowCampaign(true);

    // Call the API function when the dialog is opened
    // getCollections();
  };

  const getACollectionById = (collectionId) => {
    const selectedCollection = allCollection?.find(
      (collection) => collection.id === collectionId
    );
    return selectedCollection;
  };

  const getCreatedBy = async () => {
    try {
      // const id_ = {
      //   id: id,
      // };
      console.log("get user by id ki id: ", selectedItemId);
      const res = await getUserByID(selectedItemId);
      console.log("created by", res);
      setCreatedBy(selectedItemId);
      console.log("created by state : ", createdBy);
      // if (aCollection.created_by) {
      //     console.log('got it ');
      // }
    } catch (e) {
      console.error(
        "error occurs while getting name of created by : ",
        e.message
      );
    }
  };

  const detailedCollection = async (id) => {
    const data = getACollectionById(id);

    console.log("Get collection by id data: ", data);

    setaCollection(() => data);

    let created_by_id = data?.id;
    console.log("detailed collection - created by : ", created_by_id);

    setSelectedItemId(() => created_by_id);

    // getCreatedBy();
    const res = await getCollectionById(created_by_id);
    console.log("get collection by ID: ", res);
  };

  const handleNewCollection = (newCollection) => {
    setAllCollection([...allCollection, newCollection]);
  };

  useEffect(() => {
    if (isSucessful) getCollections();

    if (aCollection) {
      getCreatedBy();
      // Perform any action that depends on aCollection immediately after it updates
      console.log("aCollection updated:", aCollection);
    }
  }, [isSucessful, createdBy, aCollection]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError({ message: "", status: false });
    console.log("Collection data:--- ", collection);
    // setCollection((prevCollection) => {
    //   return {
    //     ...prevCollection,
    //     influencer_id: influencerIds,
    //     // assigned_brand_id: id,
    //     user_id: user?.user?.id,
    //     brand_id: brandId,
    //     description: collectionDesc,
    //     name: collectionName,
    //   };
    // });

    const updatedCollection = {
      ...collection,
      influencer_id: influencerIds,
      user_id: user?.user?.id,
      brand_id: brandId,
      description: collectionDesc,
      name: collectionName,
    };
    try {
      console.log("collection data create new collection: ", updatedCollection);
      const response = await createNewCollection(updatedCollection);
      setNewCollection(response);
      // Encryption
      // Base64 encode function
      const encodeId = (id) => btoa(id.toString());
      const encodedId = encodeId(response.id);
      setEncodedNewCollectionId(encodedId);
      console.log("Encoded ID:", encodedId);
      // handleNewCollection(response);
      // getCollection();
      // setAllCollection(preCollection => [...preCollection, response])

      setIsSuccessful(true);
      handleNewCollection(response);
      setError({ message: "", status: false });
    } catch (e) {
      setError({ message: "Collection already exists.", status: true });
      console.error("Error while submiting new collection data : " + e);

      setIsSuccessful(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setCreateCollectionDialog(false);
    setIsBrandFocus(false);
    setBrandName("");
    setCollectionName("");
    setCollection({
      name: "",
      description: "",
      // assigned_brand_id: null,
      // created_by: id,
      influencer_id: null,
      brand_id: null,
      user_id: null,
      campaign_id: 1,
      status: "lorem",
    });
    // handleNewCollection(newCollection);
    // getCollection();
  };

  const getBrands = async () => {
    try {
      const response = await getBrandList();
      const data = response.data.Brands;
      setAllBrands(data);
    } catch (e) {
      console.error("Error while fetching all the collections" + e.message);
    } finally {
      console.log("api hit.");
    }
  };

  const handleBrandChange = (e) => {
    const { value } = e.target;
    setBrandName(() => value);

    console.log("brandName", brandName);
    console.log("brand id", id);
    // setIsBrandFocus(false);

    // setFilteredBrand
    // setFilteredBrand(filteredBrands)
  };

  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  // DataTable Functions
  const handleClearRows = () => {
    // Toggle the state so React Data Table changes to clearSelectedRows are triggered
    setToggleClearRows(!toggledClearRows);
  };
  const handleRowClick = (row) => {
    // Update the selectedRow state when a row is clicked
    setSelectedRow(row);
    setInfluencerProfileGlipseOpen(true);

    // You can perform additional actions or navigate to a detail page, etc.
    console.log("Clicked Row:", row);
  };
  const handleHideColumn = (columnName) => {
    setHiddenColumns([...hiddenColumns, columnName]);
  };
  const handleShowAllColumns = () => {
    setHiddenColumns([]);
  };
  const handleAddColumn = (columnName) => {};

  const handleSelectRow = (e, row) => {
    if (e) e.stopPropagation();

    const isSelected = selectedRowArr.some(
      (selectedRow) => selectedRow.id === row.id
    );
    console.log("row", row.id, "se");
    console.log(isSelected);

    // Update the selectedRows state based on whether the row is currently selected
    if (isSelected) {
      // If the row is already selected, remove it from the selectedRows array
      setSelectedRowArr(
        selectedRowArr.filter((selectedRow) => {
          console.log("selected row id", selectedRow.id, "row id", row.id);

          return selectedRow.id !== row.id;
        })
      );
    } else {
      // If the row is not selected, add it to the selectedRows array
      setSelectedRowArr([...selectedRowArr, row]);
    }
    // Check if any rows are selected
  };

  const handleSort = (name) => {
    let sortedData = [...influencers];

    if (name === "sr_no") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.id - b.id)
        : sortedData.sort((a, b) => b.id - a.id);
    } else if (name === "Name") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.name.localeCompare(b.name))
        : sortedData.sort((a, b) => b.name.localeCompare(a.name));
    } else if (name === "Accounts") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.name.localeCompare(b.name))
        : sortedData.sort((a, b) => b.name.localeCompare(a.name));
    } else if (name === "Categories") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.categories.localeCompare(b.categories))
        : sortedData.sort((a, b) => b.categories.localeCompare(a.categories));
    } else if (name === "Subscribers") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.Subscribers - b.Subscribers)
        : sortedData.sort((a, b) => b.Subscribers - a.Subscribers);
    } else if (name === "ER%") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.er - b.er)
        : sortedData.sort((a, b) => b.er - a.er);
    } else if (name === "AVG.Likes") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.average - b.average)
        : sortedData.sort((a, b) => b.average - a.average);
    } else if (name === "AVG.Comments") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.average - b.average)
        : sortedData.sort((a, b) => b.average - a.average);
    } else if (name === "Actions") {
      sortedData = sortOrder
        ? sortedData.sort((a, b) => a.actions - b.actions)
        : sortedData.sort((a, b) => b.actions - a.actions);
    }

    setSortOrder(!sortOrder);
    setInfluencers(sortedData);
    console.log("sorted data", sortedData);
  };

  const handleChangePage = (page) => {
    fetchInfluencers(page, rowsPerPage);
  };
  const handleChangeRowsPerPage = (newRowsPerPage) => {
    fetchInfluencers(1, newRowsPerPage);
  };

  // Additional Utility Functions
  const downloadTableAsPDF = useCallback(async () => {
    setDownloading(true);

    try {
      await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure table updates have taken effect

      const table = tableRef.current;

      if (!table) {
        console.error("Table element not found.");
        setDownloading(false);
        return;
      }

      // Use html2canvas to capture the expanded table
      const canvas = await html2canvas(table);

      // Create a PDF document
      const pdf = new jsPDF("p", "mm", "a4"); // Portrait, millimeters, A4 size
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;

      while (heightLeft > 0) {
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
        position -= pageHeight;
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }

      pdf.save("table.pdf");
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setDownloading(false);
    }
  }, [tableRef]);

  function formatNumber(num) {
    try {
      const strNum = num;

      if (strNum.endsWith("000000")) {
        return strNum.replace(/000000$/, "M"); // Replace trailing six zeros with 'M'
      } else if (strNum.endsWith("000")) {
        return strNum.replace(/000$/, "K"); // Replace trailing three zeros with 'K'
      } else {
        return num; // Return the number as is
      }
    } catch (error) {
      return "error";
    }
  }

  const columnHeaderProperties = useMemo(() => {
    return new Map([
      [
        "sr_no",
        {
          name:
            selectedRowArr.length > 0 ? (
              <Checkbox
                sx={{ display: "flex", alignItems: "center", mx: "auto" }}
                checked={selectedRowArr.length === influencers.length}
                onChange={(e) => {
                  e.stopPropagation();
                  if (e.target.checked) {
                    setSelectedRowArr(influencers);
                  } else {
                    setSelectedRowArr([]);
                  }
                }}
              />
            ) : (
              <HeaderRenderer
                columnName={"sr_no"}
                columnTitle={"Sr.No."}
                handleHideColumn={handleHideColumn}
                handleShowAllColumns={handleShowAllColumns}
                handleAddColumn={handleAddColumn}
              />
            ),
          selector: (row) => row.id,
          center: true,
          minWidth: "100px",
          maxWidth: "auto",
          grow: 1,
          width: "160px",
          cell: (row) => {
            // Determine if the current row is selected
            const isSelected = selectedRowArr.some(
              (selectedRow) => selectedRow.id === row.id
            );
            // Show the checkbox if the row is hovered or selected
            if (hoveredRow === row.id || isSelected) {
              return (
                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={isSelected}
                    // inputProps={{ "aria-label": "select all desserts" }}
                    // Prevent the checkbox from triggering row selection

                    onClick={(e) => {
                      // Prevent default action
                      handleSelectRow(e, row);
                      // Determine if the row is already selected
                    }}
                  />
                </Box>
              );
            } else {
              return (
                <Box display="flex" alignItems="center">
                  <Typography sx={{ pointerEvents: "none" }}>
                    {row.id}{" "}
                  </Typography>
                </Box>
              );
            }
          },
          omit: hiddenColumns.includes("Sr.No."),
        },
      ],
      [
        "account",
        {
          name: (
            <HeaderRenderer
              columnName={"acocunt"}
              columnTitle={"Accounts"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          minWidth: "220px",
          maxWidth: "auto",
          grow: 1,
          width: "360px",
          cell: (row) => <CustomAccounts row={row} setOpen={handleRowClick} />,
          omit: hiddenColumns.includes("account"),
        },
      ],
      [
        "gender",
        {
          name: (
            <HeaderRenderer
              columnName={"gender"}
              columnTitle={"Gender"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "100px",
          maxWidth: "auto",
          grow: 1,
          width: "160px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {generalizedDataRenderer(row.gender)}
            </Typography>
          ),
          omit: hiddenColumns.includes("gender"),
        },
      ],
      [
        "age",
        {
          name: (
            <HeaderRenderer
              columnName={"age"}
              columnTitle={"Age"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "100px",
          maxWidth: "auto",
          grow: 1,
          width: "120px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {generalizedDataRenderer(row.age)}
            </Typography>
          ),
          omit: hiddenColumns.includes("age"),
        },
      ],
      [
        "city",
        {
          name: (
            <HeaderRenderer
              columnName={"city"}
              columnTitle={"City"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "150px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {generalizedDataRenderer(row.city)}
            </Typography>
          ),
          omit: hiddenColumns.includes("city"),
        },
      ],
      [
        "email",
        {
          name: (
            <HeaderRenderer
              columnName={"email"}
              columnTitle={"Email"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "100px",
          maxWidth: "auto",
          grow: 1,
          width: "130px",
          cell: (row) => {
            if (!row.email) return null;

            let parsedEmails = JSON.parse(row?.email?.replace(/'/g, '"'));

            if (Array.isArray(parsedEmails)) {
              if (parsedEmails.length === 0) return null;
              else {
                return (
                  <Box
                    sx={{ overflow: "hidden" }}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent={"center"}
                    gap={1}
                    paddingY={1}
                  >
                    {parsedEmails?.map((email, index) => (
                      <Tooltip title="email">
                        <IconButton href={`mailto:${email}`} target="_blank">
                          <Email />
                        </IconButton>
                      </Tooltip>
                    ))}
                  </Box>
                );
              }
            }

            return row.email;
          },
          omit: hiddenColumns.includes("email"),
        },
      ],
      [
        "phone",
        {
          name: (
            <HeaderRenderer
              columnName={"phone"}
              columnTitle={"Phone"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "150px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => (
            <Typography width={"100%"} textAlign={"center"}>
              {generalizedDataRenderer(row.phone)}
            </Typography>
          ),
          omit: hiddenColumns.includes("phone"),
        },
      ],
      [
        "subscriber",
        {
          name: (
            <HeaderRenderer
              columnName={"subscriber"}
              columnTitle={"Subscribers"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "140px",
          maxWidth: "auto",
          grow: 1,
          width: "180px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {formatNumber(row.subscriber)}
            </Typography>
          ),
          omit: hiddenColumns.includes("subscriber"),
        },
      ],
      [
        "topics",
        {
          name: (
            <HeaderRenderer
              columnName={"topics"}
              columnTitle={"Influencer Interests"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          wrap: true,
          center: true,
          minWidth: "140px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => {
            if (!row.topics) return null;

            let parsedtopics = JSON.parse(row?.topics?.replace(/'/g, '"'));

            if (Array.isArray(parsedtopics)) {
              return (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexWrap={"wrap"}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  gap={1}
                  paddingY={1}
                  sx={{ pointerEvents: "none", overflow: "hidden" }}
                >
                  {parsedtopics.map((topic, index) => (
                    <Chip
                      key={"influencer-topic" + index}
                      component={"span"}
                      sx={{
                        borderRadius: 2,
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                      }}
                      label={topic}
                    />
                  ))}
                </Box>
              );
            }

            return row.topics;
          },
          omit: hiddenColumns.includes("topics"),
        },
      ],
      [
        "influencer_category",
        {
          name: (
            <HeaderRenderer
              columnName={"influencer_category"}
              columnTitle={"Influencer Category"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          wrap: true,
          center: true,
          minWidth: "140px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => {
            if (!row.influencer_category) return null;

            let parsedCategories = row?.influencer_category
              ?.replace(/'/g, '"')
              .split(",");

            if (Array.isArray(parsedCategories)) {
              return (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexWrap={"wrap"}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  gap={1}
                  paddingY={1}
                  sx={{ pointerEvents: "none", overflow: "hidden" }}
                >
                  {parsedCategories.map((category, index) => (
                    <Chip
                      key={"influencer-category" + index}
                      component={"span"}
                      sx={{
                        borderRadius: 2,
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                      }}
                      label={category?.trim()}
                    />
                  ))}
                </Box>
              );
            }

            return row.influencer_category;
          },
          omit: hiddenColumns.includes("influencer_category"),
        },
      ],
      [
        "verified_email",
        {
          name: (
            <HeaderRenderer
              columnName={"verified_email"}
              columnTitle={"Verified Email"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "120px",
          maxWidth: "auto",
          grow: 1,
          width: "160px",
          cell: (row) => {
            if (!row.verified_email) return null;

            // let parsedEmails = JSON.parse(row?.email?.replace(/'/g, '"'));

            // if (Array.isArray(parsedEmails)) {
            //     if (parsedEmails.length === 0) return null;
            //     else {
            return (
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent={"center"}
                gap={1}
                paddingY={1}
              >
                {/* {parsedEmails?.map((email, index) => ( */}
                <Tooltip title="email">
                  <IconButton
                    href={`mailto:${row.verified_email}`}
                    target="_blank"
                  >
                    <Email />
                  </IconButton>
                </Tooltip>
                {/* ))} */}
              </Box>
            );
            // }
            // }

            return row.verified_email;
          },
          omit: hiddenColumns.includes("verified_email"),
        },
      ],
      [
        "verified_phone",
        {
          name: (
            <HeaderRenderer
              columnName={"verified_phone"}
              columnTitle={"Verified Phone"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "120px",
          maxWidth: "auto",
          grow: 1,
          width: "160px",
          cell: (row) => {
            if (!row.verified_phone) return null;
            const cleanedNumber = row?.verified_phone?.replace(/\D/g, "");
            return (
              <Box
                display={"flex"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Tooltip title="Verifed_phone">
                  <IconButton href={`tel:${cleanedNumber}`}>
                    <Phone />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
          omit: hiddenColumns.includes("verified_phone"),
        },
      ],
      [
        "verified_whatsapp",
        {
          name: (
            <HeaderRenderer
              columnName={"verified_whatsapp"}
              columnTitle={"Verified WhatsApp"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "140px",
          maxWidth: "auto",
          grow: 1,
          width: "180px",
          cell: (row) => {
            if (!row.verified_whatsapp) return null;
            const cleanedNumber = row?.verified_whatsapp?.replace(/\D/g, "");

            // Encode the message for URL compatibility
            const encodedMessage = encodeURIComponent("");

            // Create the WhatsApp link
            const whatsappLink = `https://wa.me/${cleanedNumber}${`?text=${encodedMessage}`}`;
            return (
              <Box
                display={"flex"}
                flexGrow={1}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Tooltip title="verified_whatsapp">
                  <IconButton href={whatsappLink} target="_blank">
                    <WhatsApp sx={{ color: "#25D366" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          },
          omit: hiddenColumns.includes("verified_whatsapp"),
        },
      ],
      [
        "state",
        {
          name: (
            <HeaderRenderer
              columnName={"state"}
              columnTitle={"State"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "150px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {generalizedDataRenderer(row.state)}
            </Typography>
          ),
          omit: hiddenColumns.includes("state"),
        },
      ],
      [
        "language",
        {
          name: (
            <HeaderRenderer
              columnName={"language"}
              columnTitle={"Language"}
              handleHideColumn={handleHideColumn}
              handleShowAllColumns={handleShowAllColumns}
              handleAddColumn={handleAddColumn}
              handleSort={handleSort}
              order={sortOrder}
            />
          ),
          center: true,
          minWidth: "150px",
          maxWidth: "auto",
          grow: 1,
          width: "200px",
          cell: (row) => (
            <Typography
              sx={{ pointerEvents: "none" }}
              width={"100%"}
              textAlign={"center"}
            >
              {generalizedDataRenderer(row.language)}
            </Typography>
          ),
          omit: hiddenColumns.includes("language"),
        },
      ],
      // ["actions", {
      //     name: (
      //         <HeaderRenderer
      //             columnName={"actions"}
      //             columnTitle={"Actions"}
      //             handleHideColumn={handleHideColumn}
      //             handleShowAllColumns={handleShowAllColumns}
      //             handleAddColumn={handleAddColumn}
      //             handleSort={handleSort}
      //             order={sortOrder}
      //         />
      //     ),
      //     omit: hiddenColumns.includes("actions"),
      //     cell: (row) => (
      //         <CustomActions
      //             setSharePopup={setShareCollectionDrawer}
      //             row={row}
      //             combinedColumns={combinedColumns}
      //         />
      //     ),
      //     center: true,
      //     reorder: true,
      // }]
    ]);
  }, [hiddenColumns, hoveredRow, influencers, selectedRowArr, sortOrder]);

  const [headerColumns, setHeaderColumns] = useState([]);

  function generalizedDataRenderer(data) {
    if (data === null) return "";

    switch (typeof data) {
      case "string":
        // Check for JSON-like strings (e.g., "[]", "['Item']")
        try {
          const parsed = JSON.parse(data.replace(/'/g, '"')); // Replace single quotes for parsing
          return Array.isArray(parsed)
            ? `${parsed.map(generalizedDataRenderer).join(", ")}`
            : `${data}`;
        } catch (e) {
          return `${data}`;
        }

      case "number":
        return `${data}`;

      case "boolean":
        return `${data ? "Yes" : "No"}`;

      case "object":
        if (Array.isArray(data)) {
          return `${data.map(generalizedDataRenderer).join(", ")}`;
        } else {
          return `{ ${Object.entries(data)
            .map(([key, value]) => `${key}: ${generalizedDataRenderer(value)}`)
            .join(", ")} }`;
        }

      case "undefined":
        return "";

      default:
        return "";
    }
  }

  const StaticHiddenColumns = [
    "id",
    "created_at",
    "updated_at",
    "photo_link",
    "name",
    "channel_id",
    "handle",
  ];

  useEffect(() => {
    let columnHeaderLoaded = [];
    if (columnHeaders?.length > 0) {
      if (columnHeaderProperties.has("sr_no")) {
        columnHeaderLoaded.push(columnHeaderProperties.get("sr_no"));
      }
      if (columnHeaderProperties.has("account")) {
        columnHeaderLoaded.push(columnHeaderProperties.get("account"));
      }
      columnHeaders.forEach((column) => {
        if (!StaticHiddenColumns.includes(column)) {
          if (columnHeaderProperties.has(column)) {
            columnHeaderLoaded.push(columnHeaderProperties.get(column));
          } else {
            let ColumnTitle = column.replace(/_/g, " ");
            columnHeaderLoaded.push({
              name: (
                <HeaderRenderer
                  columnName={column}
                  columnTitle={ColumnTitle}
                  handleHideColumn={handleHideColumn}
                  handleShowAllColumns={handleShowAllColumns}
                  handleAddColumn={handleAddColumn}
                  handleSort={handleSort}
                  order={sortOrder}
                />
              ),
              center: true,
              wrap: false,
              cell: (row) => (
                <Typography
                  textAlign={"center"}
                  whiteSpace={"nowrap"}
                  sx={{ pointerEvents: "none" }}
                >
                  {generalizedDataRenderer(row[column])}
                </Typography>
              ),
              reorder: true,
              omit: hiddenColumns.includes(column),
            });
          }
        }
      });
    }
    setHeaderColumns(columnHeaderLoaded);
  }, [columnHeaderProperties, columnHeaders]);

  const [selectedItem, setSelectedItem] = useState(null);

  const [topics, setTopics] = useState([]);

  const handleItemClick = (index) => {
    setSelectedItem(selectedRowArr[index]);
    setTopics(selectedItem?.topics?.match(/[a-zA-Z]+/g));
  };

  // add to collection - update influencer api
  const handleAddInfluencers = async () => {
    const data = {
      collection_id: createdBy,
      influencer_id: influencerIds,
    };

    console.log("handle add influencer log: ", data);

    try {
      const res = await updateInfluencerCollection(data);
      console.log("update influencer collection: ", res);
      setIsSuccessful(true);
      setIsAddedSuccessful(true);
    } catch (error) {
      console.error("Error updating influencer collection: ", error);
    }
  };

  return (
    <Box height={"100%"} width={"100%"}>
      {/* influencer profile in the right side drawer  */}
      <Drawer
        anchor="right"
        open={influencerProfileGlipseOpen}
        onClose={() => setInfluencerProfileGlipseOpen(false)}
      >
        <InfluencerProfile
          selectedSocial={selectedSocial}
          close={setInfluencerProfileGlipseOpen}
          row={selectedRow}
        />
      </Drawer>
      {/* collections dialog box  */}
      <Dialog
        open={showCollection}
        onClose={() => closeAddToCollection()}
        style={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Box container>
          <Box
            item
            md={12}
            px={3}
            pt={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              Collection
            </Typography>
            <Tooltip title="Close" arrow placement="left">
              <IconButton onClick={() => closeAddToCollection()}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Box>

          <Box display={"flex"} item md={12}>
            <Box
              item
              md={6}
              px={3}
              pb={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent="space-between"
              style={{ background: "white" }}
            >
              <Box container>
                <Box item md={12}>
                  <TextField
                    size="small"
                    placeholder="Search here..."
                    sx={{
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      background: "#FFFFFF",
                      marginTop: 2,
                      marginBottom: 5,
                      boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                        paddingRight: 0.4,
                        "&:hover fieldset": {
                          borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setSearchText(e.target.value)}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      orientation="vertical"
                      value={view}
                      exclusive
                      onChange={handleChange}
                      size="small"
                      sx={{
                        border: "none",
                        maxHeight: "280px",
                        overflowY: "scroll",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "transparent transparent", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Microsoft Edge
                      }}
                    >
                      {allCollection?.map(({ name, id }, index) => (
                        <ToggleButton
                          key={index}
                          value={id}
                          aria-label="list"
                          sx={{
                            border: "none",
                            textTransform: "none",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                          onClick={() => detailedCollection(id)}
                          // onClick={() => {
                          //   handleItemClick(index);
                          // }}
                        >
                          {name}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box item md={12} p={2} sx={{ marginTop: "auto" }}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  style={{
                    background: "rgba(61, 190, 114, 0.3)",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                    border: "2px solid rgba(61, 190, 114, 1)",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                  onClick={openCreateCollectionDialog}
                >
                  Create new Collection
                </Button>
              </Box>
            </Box>

            <Box item md={6} display={"flex"}>
              <Box
                container
                px={3}
                pb={3}
                sx={{
                  borderLeft: "3px solid black",
                  height: "90%",
                  marginRight: 1,
                  marginY: "auto",
                }}
              >
                <Box item md={12} display="flex" alignItems={"center"}>
                  <AvatarGroup
                    max={4}
                    total={selectedRowArr?.length}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* {selectedRowArr?.map((data) => (
                      <Avatar
                        sx={{ width: 80, height: 80 }}
                        alt={data.name}
                        src={data.photo_link}
                      />
                    ))} */}
                    <Avatar
                      sx={{ width: 60, height: 60 }}
                      alt="Travis Howard"
                      src={TemptDP}
                    />
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      alt="Agnes Walker"
                      src={TemptDP}
                    />
                    <Avatar
                      sx={{ width: 50, height: 50 }}
                      alt="Trevor Henderson"
                      src={TemptDP}
                    />
                  </AvatarGroup>
                  <Box ml={2}>
                    <Typography fontSize="1.5rem" fontWeight="bold">
                      {aCollection?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box item md={12} mt={2}>
                  {aCollection?.topics?.map((topic) => {
                    return (
                      <Button
                        style={{
                          textTransform: "none",
                          // color: "#3DBE72",
                          color: "#424242",
                          borderColor: "#3DBE72",
                          marginRight: 10,
                          background: "rgba(61, 190, 114, 0.3)",
                        }}
                        size="small"
                        variant="contained"
                      >
                        {topic}
                      </Button>
                    );
                  })}
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Assigned to Collection
                  </Typography>
                  <Typography>{aCollection?.campaign?.name}</Typography>
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Created by
                  </Typography>
                  <Typography>
                    {createdBy ? createdBy.name : "not found"}
                  </Typography>
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Bio
                  </Typography>
                  <Typography>
                    {aCollection?.description}
                    {/* Live life on your own tems #jointhemvmt Watches, Eyewear & Premium Accessories For her @mvmforher Shop our Site */}
                  </Typography>
                  <span>
                    #Live #life #jointhemvmt #Watches #Eyewear #Premium
                    #Accessories
                  </span>
                </Box>
                {isAddedSuccessful ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    mt={4}
                  >
                    <Typography
                      sx={{
                        fontWeight: "semibold",
                        fontSize: "1rem",
                        color: "#3dbe72ff",
                      }}
                    >
                      Collection Successfully Added.
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      {/* <Button
                        size="small"
                        variant="contained"
                        sx={{
                          bgcolor: "#3dbe72ff",
                          // color : '#3dbe72ff',
                          fontWeight: "bold",
                          // border: '1px solid black',
                          boxShadow: "none",
                          textTransform: "none",
                          border: "1px solid #3dbe72ff",
                          marginRight: 1,
                          "&:hover": {
                            bgcolor: "#3dbe72ff",
                            color: "white",
                            borderColor: "black",
                          },
                        }}
                        onClick={() => handleClose()}
                      >
                        Close
                      </Button> */}

                      <Link
                        to={`/dashboard/collection/${encodedNewCollectionId}`}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{
                            // bgcolor: '#3dbe72ff',
                            color: "#3dbe72ff",
                            fontWeight: "bold",
                            borderColor: "#3dbe72ff",
                            boxShadow: "none",
                            textTransform: "none",
                            marginRight: 1,
                            transition: ".4s",
                            "&:hover": {
                              bgcolor: "#3dbe72ff",
                              color: "white",
                              borderColor: "black",
                            },
                          }}
                        >
                          Go to Collection
                        </Button>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <Box item md={12} align="end" pt={3} p={3}>
                    <Button
                      size="medium"
                      variant="contained"
                      style={{
                        background: "#3DBE72",
                        borderRadius: 20,
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "5px 20px",
                      }}
                      onClick={() => handleAddInfluencers()}
                    >
                      Add to Collection
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {/* campaign dialog box */}
      <Dialog
        open={showCampaign}
        onClose={() => closeAddToCampaign()}
        style={{ borderRadius: 20 }}
        maxWidth="md"
      >
        <Box container>
          <Box
            item
            md={12}
            px={3}
            pt={3}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
              Campaign
            </Typography>
            <Tooltip title="Close" arrow placement="left">
              <IconButton onClick={() => closeAddToCampaign()}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Box>

          <Box display={"flex"} item md={12}>
            <Box
              item
              md={6}
              px={3}
              pb={3}
              display={"flex"}
              flexDirection={"column"}
              justifyContent="space-between"
              style={{ background: "white" }}
            >
              <Box container>
                <Box item md={12}>
                  <TextField
                    size="small"
                    placeholder="Search here..."
                    sx={{
                      borderRadius: 8, // Adjust the value as needed for your desired border radius
                      background: "#FFFFFF",
                      marginTop: 2,
                      marginBottom: 5,
                      boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                        paddingRight: 0.4,
                        "&:hover fieldset": {
                          borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                        },
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <ToggleButtonGroup
                      orientation="vertical"
                      value={view}
                      exclusive
                      onChange={handleChange}
                      size="small"
                      sx={{
                        border: "none",
                        maxHeight: "280px",
                        overflowY: "scroll",
                        scrollbarWidth: "thin", // For Firefox
                        scrollbarColor: "transparent transparent", // For Firefox
                        msOverflowStyle: "none", // For Internet Explorer and Microsoft Edge
                      }}
                    >
                      {allCollection?.map(({ name, id }, index) => (
                        <ToggleButton
                          key={index}
                          value={id}
                          aria-label="list"
                          sx={{
                            border: "none",
                            textTransform: "none",
                            fontSize: "1rem",
                            fontWeight: "bold",
                          }}
                          onClick={() => detailedCollection(id)}
                          // onClick={() => {
                          //   handleItemClick(index);
                          // }}
                        >
                          {name}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                </Box>
              </Box>
              <Box item md={12} pt={3} px={3} sx={{ marginTop: "auto" }}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  style={{
                    background: "rgba(61, 190, 114, 0.3)",
                    borderRadius: 20,
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 20px",
                    border: "2px solid rgba(61, 190, 114, 1)",
                    color: "#000000",
                    fontWeight: "bold",
                  }}
                >
                  Create new campaign
                </Button>
              </Box>
            </Box>

            <Box item md={6} display={"flex"}>
              <Box
                container
                px={3}
                pb={3}
                sx={{
                  borderLeft: "3px solid black",
                  height: "90%",
                  marginRight: 1,
                  marginY: "auto",
                }}
              >
                <Box item md={12} display="flex" alignItems={"center"}>
                  <Avatar
                    sx={{ width: 80, height: 80 }}
                    alt="Remy Sharp"
                    src={selectedItem?.photo_link}
                  />

                  <Box ml={2}>
                    <Typography fontSize="1.3rem" fontWeight="bold">
                      {selectedItem?.name}
                    </Typography>
                    <Typography fontSize="1.1rem">description</Typography>
                  </Box>
                </Box>
                <Box item md={12} mt={2}>
                  {topics?.map((topic) => {
                    return (
                      <Button
                        style={{
                          textTransform: "none",
                          // color: "#3DBE72",
                          color: "#424242",
                          borderColor: "#3DBE72",
                          marginRight: 10,
                          background: "rgba(61, 190, 114, 0.3)",
                        }}
                        size="small"
                        variant="contained"
                      >
                        {topic}
                      </Button>
                    );
                  })}
                  {/* <Button
                    style={{
                      textTransform: "none",
                      // color: "#3DBE72",
                      color: "#424242",
                      borderColor: "#3DBE72",
                      marginRight: 10,
                      background: "rgba(61, 190, 114, 0.3)",
                    }}
                    size="small"
                    variant="contained"
                  >
                    Infotainment
                  </Button> */}
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Assigned to Campaign
                  </Typography>
                  <Box display={"flex"} gap={3} alignItems={"center"}>
                    Deliverables
                  </Box>
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Created Preferences
                  </Typography>
                  <Box display={"flex"} gap={3} alignItems={"center"}>
                    creator preferences
                  </Box>
                </Box>

                <Box item md={12} mt={3}>
                  <Typography
                    fontSize={"1.3rem"}
                    mb={1}
                    sx={{ fontWeight: "bold" }}
                  >
                    Campaign Budget
                  </Typography>
                  <Typography fontSize={"1.3rem"} sx={{ fontWeight: "bold" }}>
                    Budget
                  </Typography>
                </Box>

                <Box item md={12} align="end" p={3}>
                  <Button
                    size="medium"
                    variant="contained"
                    style={{
                      background: "#3DBE72",
                      borderRadius: 20,
                      textTransform: "none",
                      boxShadow: "none",
                      padding: "5px 20px",
                    }}
                    // onClick={() => handleAddInfluencers()}
                  >
                    {isAdded ? "Added to Campaign" : "Add to Campaign"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {/* Create Collection Diaglog  */}
      <Dialog
        open={createCollectionDialog}
        onClose={handleClose}
        // 👇 Props passed to Paper (modal content)
        PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
      >
        {isSucessful ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 3,
            }}
          >
            <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: "#3dbe72ff",
                marginTop: 2,
              }}
            >
              Collection Successfully Created.
            </Typography>

            <Box
              mt={4}
              sx={{
                display: "flex",
                justifyContent: "end",
                width: "100%",
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: "#3dbe72ff",
                  // color : '#3dbe72ff',
                  fontWeight: "bold",
                  // border: '1px solid black',
                  boxShadow: "none",
                  textTransform: "none",
                  border: "1px solid #3dbe72ff",
                  marginRight: 1,
                  "&:hover": {
                    bgcolor: "#3dbe72ff",
                    color: "white",
                    borderColor: "black",
                  },
                }}
                onClick={() => handleClose()}
              >
                Close
              </Button>

              <Link to={`/dashboard/collection/${encodedNewCollectionId}`}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    // bgcolor: '#3dbe72ff',
                    color: "#3dbe72ff",
                    fontWeight: "bold",
                    borderColor: "#3dbe72ff",
                    boxShadow: "none",
                    textTransform: "none",
                    marginRight: 1,
                    transition: ".4s",
                    "&:hover": {
                      bgcolor: "#3dbe72ff",
                      color: "white",
                      borderColor: "black",
                    },
                  }}
                >
                  Go to Collection
                </Button>
              </Link>
            </Box>
          </Box>
        ) : (
          <Grid container p={4}>
            <Grid
              item
              size={{ md: 11, xs: 11 }}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.2rem",
                  color: "#4caf50",
                }}
              >
                Create new Collection
              </Typography>
              {error.status && (
                <Box>
                  <p style={{ color: "#e81e61ff", fontSize: 13 }}>
                    {error.message}
                  </p>
                </Box>
              )}
            </Grid>
            <Grid
              item
              size={{ md: 1, xs: 1 }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Tooltip title="Close" arrow placement="left">
                <IconButton
                  size="small"
                  onClick={() => {
                    setCreateCollectionDialog(false);
                    handleClose();
                  }}
                >
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item size={12}>
              <form onFocus={() => getBrands()} onSubmit={handleSubmit}>
                <Box mt={2}>
                  <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                  <Box>
                    <MyInput
                      type="text"
                      name="name"
                      id="collection"
                      placeholder="Enter the Name of collection"
                      onChange={(e) => handleNameChange(e)}
                      required
                      onFocus={() => setIsBrandFocus(false)}
                      value={collectionName}
                    />
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="brand">Brand Name</Mylabel>
                  <Box position={"relative"}>
                    <MyInput
                      type="text"
                      name="brand_name"
                      id="brand"
                      placeholder="search the brand "
                      required
                      onChange={(e) => handleBrandChange(e)}
                      onFocus={() => {
                        setIsBrandFocus(true);
                      }}
                      // onFocusCapture={() => setIsBrandFocus(true)}
                      // onBlur={() => setIsBrandFocus(false)}
                      value={brandName}
                    />
                    <IconButton
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                      onClick={openAddBrandDialog}
                    >
                      <Tooltip title="Add a new Brand" arrow>
                        <Add />
                      </Tooltip>
                    </IconButton>
                    {isBrandFocus && (
                      <Box
                        sx={{
                          padding: "5px 10px",
                          position: "absolute",
                          width: "100%",
                          background: "white",
                          marginTop: 1,
                          borderRadius: 2,
                          maxHeight: "140px",
                          overflowY: "scroll",
                          zIndex: 10,
                        }}
                      >
                        {allBrands?.reverse()?.map(({ name, id }) => {
                          return (
                            <ListItem
                              key={id}
                              component="div"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                handleListItemClick(name, id);
                                setIsBrandFocus(false);
                              }}
                            >
                              {name}
                            </ListItem>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={2}>
                  <Mylabel htmlFor="description">Description</Mylabel>
                  <Box>
                    <MyTextArea
                      rows={3}
                      type="text"
                      name="description"
                      id="description"
                      placeholder="Write short description"
                      onChange={(e) => handleDescChange(e)}
                      required
                      // onFocus={() => {
                      //   setIsBrandFocus(false);
                      // }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      textTransform: "none",
                      borderRadius: 10,
                      fontWeight: "bold",
                      marginRight: 1,
                      background: "#c5edd6ff",
                      borderWidth: "2px",
                      color: "black",
                      borderColor: "#3dbf73ff",
                      "&:hover": {
                        borderWidth: "2px",
                        borderColor: "#3dbf73ff",
                      },
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    sx={{
                      bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                      boxShadow: "none",
                      fontWeight: "bold",
                      borderRadius: 10,
                      // padding: '10px 30px',
                      "&:hover": {
                        bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                      },
                    }}
                    endIcon={
                      loading ? (
                        <LoadingSpinner size={25} />
                      ) : isSucessful ? (
                        <AddTaskOutlined />
                      ) : (
                        error.status && <WarningAmberOutlined />
                      )
                    }
                    disabled={loading || isSucessful}
                  >
                    {isSucessful ? "Created" : "Create Collection"}
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
      </Dialog>

      {/* add brand dialog  */}
      <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
        <AddNewBrand close={closeAddBrandDialog} />
      </Dialog>

      <Box
        marginBottom={2}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          transition: "width 0.4s ease-in-out",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box>
            <Tooltip title="Cards view" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => setInfluencersView(false)}
              >
                <img src={SBicon} alt="" width={30} />
                {/* <WindowOutlined sx={{fontSize : 30, color : 'green'}}/> */}
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Tooltip title="List view" arrow placement="top">
              <IconButton size="small" onClick={() => setInfluencersView(true)}>
                <img src={LISTicon} alt="" width={30} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            {selectedRowArr.length > 0 && (
              <CSVExportButton
                data={selectedRowArr}
                filename="influencers_data"
              />
            )}
          </Box>
          <Box>
            <Tooltip title="Download PDF" arrow placement="top">
              <IconButton
                disabled={downloading}
                onClick={downloadTableAsPDF}
                size="small"
              >
                <img src={PDFicon} alt="" width={30} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Box>
          {selectedRowArr?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Box>
                <Btn
                  size="small"
                  variant="outlined"
                  startIcon={<BookmarkBorderIcon />}
                  onClick={handleAddToCollectionDialogOpen}
                >
                  Add to Collection
                </Btn>
              </Box>
              <Box>
                <Btn
                  size="small"
                  variant="outlined"
                  startIcon={<AddRoundedIcon />}
                  onClick={handleAddToCampaignDialogOpen}
                >
                  Add to Campaign
                </Btn>
              </Box>
              <Box>
                <Btn
                  size="small"
                  variant="outlined"
                  startIcon={<VisibilityOffRoundedIcon />}
                >
                  Hide Influencers
                </Btn>
              </Box>
              <Box>
                <Btn
                  size="small"
                  variant="outlined"
                  startIcon={<Delete />}
                  onClick={() => handleRemoveInfluencer()}
                >
                  Remove Influencers
                </Btn>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {/* <TestComponent/> */}
      {influencersView ? (
        <>
          {true && (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "center",
                flexGrow: 1,
                mx: "auto",
                marginBottom: 3,
              }}
              ref={tableRef}
              id="influencertable"
            >
              <Box
                sx={{
                  minWidth: "80vw",
                  maxWidth: "90vw",
                  width: isMiniOpen ? "90vw" : "80vw",
                  overflowX: "auto",
                  mx: "auto",
                }}
              >
                <DataTable
                  // title="Influencers"
                  // responsive
                  responsive
                  columns={headerColumns}
                  data={influencers}
                  fixedHeader
                  fixedHeaderScrollHeight={downloading ? "100%" : "550PX"}
                  pagination
                  paginationServer
                  // paginationComponent={CustomMaterialPagination}
                  // onRowMouseEnter={(row,event)=>console.log(row,event)}
                  onRowClicked={handleRowClick}
                  customStyles={customDatatableStyles}
                  // sortIcon={<CustomSortIcon />}"
                  progressPending={fetchingInProgress}
                  scrollbarColor="rgba(61, 190, 114, 1)"
                  selectableRowsHighlight
                  pointerOnHover
                  highlightOnHover
                  progressComponent={<CircularProgress />}
                  // onSelectedRowsChange={handleSelectRow}
                  clearSelectedRows={toggledClearRows}
                  paginationPerPage={rowsPerPage}
                  paginationDefaultPage={page}
                  paginationTotalRows={totalRows}
                  paginationRowsPerPageOptions={[10, 25, 50, 100, 200]}
                  paginationResetDefaultPage={true}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  // sortIcon={<CustomSortIcon />}
                  onRowMouseEnter={(row, event) => setHoveredRow(row.id)}
                  onRowMouseLeave={() => setHoveredRow(null)}
                />
              </Box>
              {selectedRowArr?.length > 0 && (
                <Box
                  sx={{
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.35)",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    transition: "width 1s ease-in-out",
                    border: "1px solid black",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                    marginTop: -4,
                  }}
                >
                  <IconButton
                    onClick={() => setTotalHidden(!TotalHidden)}
                    sx={{ float: "left", marginY: "auto" }}
                  >
                    {TotalHidden ? (
                      <VisibilityOffRoundedIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>

                  {TotalHidden ? null : (
                    <TotalSelectedInfluencers selectedRowArr={selectedRowArr} />
                  )}
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        // Card view
        <>Card View</>
      )}
    </Box>
  );
};

const Template = (args) => <FindInfluencerDatatable {...args} />;

export const FixedHeader = Template.bind({});

FixedHeader.args = {
  fixedHeader: true,
  fixedHeaderScrollHeight: "300px",
};

export default FindInfluencerDatatable;
