import { useEffect, useMemo, useState } from "react";

// MUI Components
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

// Icons
import AdvIcon from "../../images/cog.png";
import { CloseRounded, DeleteOutlineOutlined } from "@mui/icons-material";

// Filter Components
import MinMaxFilterComponent from "../filterComponents/minmax";
import MultiSelectWithAdd from "../filterComponents/multiSelectWithTextfieldAdd";
import MulltiCheckbox from "../filterComponents/multiCheckbox";
import SingleCheckbox from "../filterComponents/singleCheckbox";
import ForInMinMaxFilterComponent from "../filterComponents/forInMinMax";
import SingleTextfield from "../filterComponents/singleTextField";
import ForMinMaxFilterComponent from "../filterComponents/forMinMax";

// Styled Components
const FilterCategoryHeader = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const YTAdvancedFilters = ({
  open,
  set,
  selectedSocial,
  fetchInfluencers,
  fetchWithFilters,
  setFilterState,
  filterStateParent,
  setFilterData,
  filterDataParent,
  essentialData,
}) => {
  // Essential Data
  const {
    locationList,
    genderList,
    languageList,
    categoryList,
    verifiedList,
    contactInfoList,
    verifiedContactList,
    socialPlatformsList,
    inTimeList,
  } = essentialData;

  // const [filterApplied, setFilterApplied] = useState(false);

  // Filter States
  // influencer
  const [subcribersSize, setSubcribersSize] = useState(null);
  const [location, setLocation] = useState(null);
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState(null);
  const [language, setLanguage] = useState(null);
  const [youtubeCategory, setYoutubeCategory] = useState(null);
  const [category, setCategory] = useState(null);
  const [verified, setVerified] = useState(null);
  const [contactInfo, setContactInfo] = useState(null);
  const [verifiedContact, setVerifiedContact] = useState(null);
  const [socialPlatforms, setSocialPlatforms] = useState(null);
  const [influencerInterest, setInfluencerInterest] = useState(null);
  const [mentions, setMentions] = useState(null);
  const [hashtags, setHashtags] = useState(null);
  const [advancedKeyWords, setAdvancedKeyWords] = useState(null);
  const [madeForKids, setMadeForKids] = useState(null);
  const [inChannelName, setInChannelName] = useState(null);
  // audience
  // growth
  const [videoSubsRatio, setVideoSubsRatio] = useState(null);
  const [likesViewsRatio, setLikesViewsRatio] = useState(null);
  const [commentViewsRatio, setCommentViewsRatio] = useState(null);
  const [videoUploadGap, setVideoUploadGap] = useState(null);
  const [videoShortsRatio, setVideoShortsRatio] = useState(null);
  const [avgPostedContentLong, setAvgPostedContentLong] = useState(null);
  const [avgPostedContent, setAvgPostedContent] = useState(null);
  const [avgPostedContentShort, setAvgPostedContentShort] = useState(null);
  const [viewsInTimeframe, setViewsInTimeframe] = useState(null);
  // performance = engagement
  const [engagementRate, setEngagementRate] = useState(null);
  const [avgLikes, setAvgLikes] = useState(null);
  const [avgComments, setAvgComments] = useState(null);
  const [commentRate, setCommentRate] = useState(null);
  const [avgViewsPerLongVideo, setAvgViewsPerLongVideo] = useState(null);
  const [avgViewsPerShortVideo, setAvgViewsPerShortVideo] = useState(null);
  const [mostRecentVideoDays, setMostRecentVideoDays] = useState(null);
  const [totalVideos, setTotalVideos] = useState(null);
  const [sponsoredVideo, setSponseredVideo] = useState(null);
  const [sponsoredVideoPerformance, setSponsoredVideoPerformance] =
    useState(null);
  const [totalViews, setTotalViews] = useState(null);
  const [channelCreationDate, setChannelCreationDate] = useState(null);
  const [hasShorts, setHasShorts] = useState(null);
  const [mostlyPosttedContentType, setMostlyPosttedContentType] =
    useState(null);

  // useEffect(() => {
  //     if (open) {
  //         console.log("filterDataParent", filterStateParent);

  //         if (filterDataParent) {
  //             setSubcribersSize(filterDataParent?.sub_size);
  //         }
  //     }
  // }, [open]);

  // Filters Clear State
  // influencers
  const [clearSubsSize, setClearSubsSize] = useState();
  const [clearLocation, setClearLocation] = useState();
  const [clearAge, setClearAge] = useState();
  const [clearGender, setClearGender] = useState();
  const [clearLanguage, setClearLanguage] = useState();
  const [clearYoutubeCategory, setClearYoutubeCategory] = useState();
  const [clearCategory, setClearCategory] = useState();
  const [clearVerified, setClearVerified] = useState();
  const [clearContactInfo, setClearContactInfo] = useState();
  const [clearVerifiedContact, setClearVerifiedContact] = useState();
  const [clearSocialPlatforms, setClearSocialPlatforms] = useState();
  const [clearInfluencerInterest, setClearInfluencerInterest] = useState();
  const [clearMentions, setClearMentions] = useState();
  const [clearHashtags, setClearHashtags] = useState();
  const [clearAdvancedKeyWords, setClearAdvancedKeyWords] = useState();
  const [clearMadeForKids, setClearMadeForKids] = useState();
  const [clearInChannelName, setClearInChannelName] = useState();
  // audience
  // performance
  const [clearEngagementRate, setClearEngagementRate] = useState();
  const [clearAvgLikes, setClearAvgLikes] = useState();
  const [clearAvgComments, setClearAvgComments] = useState();
  const [clearCommentRate, setClearCommentRate] = useState();
  const [clearAvgViewsPerLongVideo, setClearAvgViewsPerLongVideo] = useState();
  const [clearAvgViewsPerShortVideo, setClearAvgViewsPerShortVideo] =
    useState();
  const [clearMostRecentVideoDays, setClearMostRecentVideoDays] = useState();
  const [clearTotalVideos, setClearTotalVideos] = useState();
  const [clearSponseredVideo, setClearSponseredVideo] = useState();
  const [clearSponsoredVideoPerformance, setClearSponsoredVideoPerformance] =
    useState();
  const [clearTotalViews, setClearTotalViews] = useState();
  const [clearChannelCreationDate, setClearChannelCreationDate] = useState();
  const [clearHasShorts, setClearHasShorts] = useState();
  const [clearMostlyPosttedContentType, setClearMostlyPosttedContentType] =
    useState();
  // growth
  const [clearVideoSubsRatio, setClearVideoSubsRatio] = useState();
  const [clearLikesViewsRatio, setClearLikesViewsRatio] = useState();
  const [clearCommentViewsRatio, setClearCommentViewsRatio] = useState();
  const [clearVideoUploadGap, setClearVideoUploadGap] = useState();
  const [clearVideoShortsRatio, setClearVideoShortsRatio] = useState();
  const [clearAvgPostedContentLong, setClearAvgPostedContentLong] = useState();
  const [clearAvgPostedContent, setClearAvgPostedContent] = useState();
  const [clearAvgPostedContentShort, setClearAvgPostedContentShort] =
    useState();
  const [clearViewsInTimeframe, setClearViewsInTimeframe] = useState();

  // Influencer Filters
  const InfluencerFilters = [
    {
      title: "Subscribers Size",
      selector: "sub_size",
      tooltipInfo: "Filter influencers with subscribers size range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setSubcribersSize}
          data={subcribersSize}
          clearIt={setClearSubsSize}
        />
      ),
    },
    {
      title: "Influencer Location",
      selector: "location",
      tooltipInfo: "Filter influencers with locations",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setLocation}
          data={location}
          clearIt={setClearLocation}
          label={"Select Locations"}
          placeholder={"Enter Location name"}
          predefinedOptions={locationList}
        />
      ),
    },
    {
      title: "Influencer Age",
      selector: "Age",
      tooltipInfo: "Filter influencers according to their age range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAge}
          data={age}
          clearIt={setClearAge}
        />
      ),
    },
    {
      title: "Gender",
      selector: "gender",
      tooltipInfo: "Filter influencers according to their gender",
      component: (args) => (
        <MulltiCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setGender}
          data={gender}
          clearIt={setClearGender}
          label={"Select Gender"}
          predefinedOptions={genderList}
        />
      ),
    },
    {
      title: "Influencer Language",
      selector: "language",
      tooltipInfo: "Filter influencers according to their language",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setLanguage}
          data={language}
          clearIt={setClearLanguage}
          label={"Select Languages"}
          placeholder={"Enter language"}
          predefinedOptions={languageList}
        />
      ),
    },
    {
      title: "Youtube Category",
      selector: "Youtube Category",
      tooltipInfo: "Filter influencers according to their youtube category",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setYoutubeCategory}
          data={youtubeCategory}
          clearIt={setClearYoutubeCategory}
          label={"Select Category"}
          placeholder={"Enter Category"}
          predefinedOptions={categoryList}
        />
      ),
    },
    {
      title: "Influencer Category",
      selector: "Category",
      tooltipInfo: "Filter influencers according to their category",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setCategory}
          data={category}
          clearIt={setClearCategory}
          label={"Select Category"}
          placeholder={"Enter Category"}
          predefinedOptions={categoryList}
        />
      ),
    },
    {
      title: "Verified",
      selector: "Verified",
      tooltipInfo: "Filter influencers according to their Verification status",
      component: (args) => (
        <SingleCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setVerified}
          data={verified}
          clearIt={setClearVerified}
          label={"Select Verification Status"}
          predefinedOptions={verifiedList}
        />
      ),
    },
    {
      title: "Contact Info",
      selector: "Contact Info",
      tooltipInfo: "Filter influencers according to their contact info",
      component: (args) => (
        <MulltiCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setContactInfo}
          data={contactInfo}
          clearIt={setClearContactInfo}
          label={"Select Contact Info"}
          predefinedOptions={contactInfoList}
        />
      ),
    },
    {
      title: "Verified Contact",
      selector: "Verified Contact",
      tooltipInfo: "Filter influencers according to their verified contact",
      component: (args) => (
        <MulltiCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setVerifiedContact}
          data={verifiedContact}
          clearIt={setClearVerifiedContact}
          label={"Select Verified Contact"}
          predefinedOptions={verifiedContactList}
        />
      ),
    },
    {
      title: "Social Platforms",
      selector: "Social",
      tooltipInfo: "Filter influencers according to their social platforms",
      component: (args) => (
        <MulltiCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setSocialPlatforms}
          data={socialPlatforms}
          clearIt={setClearSocialPlatforms}
          label={"Select Social Platforms"}
          predefinedOptions={socialPlatformsList}
        />
      ),
    },
    {
      title: "Influencer Interests",
      selector: "Influencer Interest",
      tooltipInfo: "Filter influencers with interests",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setInfluencerInterest}
          data={influencerInterest}
          clearIt={setClearInfluencerInterest}
          label={"Select Interests"}
          placeholder={"Enter Interest"}
          predefinedOptions={[]}
        />
      ),
    },
    {
      title: "Mentions",
      selector: "Mentions",
      tooltipInfo: "Filter influencers with their mentions",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setMentions}
          data={mentions}
          clearIt={setClearMentions}
          label={"Select Mentions"}
          placeholder={"Enter Mention"}
          predefinedOptions={[]}
        />
      ),
    },
    {
      title: "Hashtags",
      selector: "Hashtags",
      tooltipInfo: "Filter influencers with different hashtags",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setHashtags}
          data={hashtags}
          clearIt={setClearHashtags}
          label={"Select Hashtags"}
          placeholder={"(e.g. #hashtag)"}
          predefinedOptions={[]}
        />
      ),
    },
    {
      title: "Made for Kids",
      selector: "Made for Kids",
      tooltipInfo: "Filter influencers with their content made for kids",
      component: (args) => (
        <SingleCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setMadeForKids}
          data={
            madeForKids == true
              ? "yes"
              : madeForKids == false
              ? "no"
              : madeForKids
          }
          clearIt={setClearMadeForKids}
          label={"Select Made for Kids"}
          predefinedOptions={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
            { value: "NA", label: "N/A" },
          ]}
        />
      ),
    },
    {
      title: "In Channel Name",
      selector: "In Channel Name",
      tooltipInfo: "Filter influencers with their channel name",
      component: (args) => (
        <MultiSelectWithAdd
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setInChannelName}
          data={inChannelName}
          clearIt={setClearInChannelName}
          label={"Select words in Channel Name"}
          placeholder={"Enter Channel Name"}
          predefinedOptions={[]}
        />
      ),
    },
  ];

  // Audience Filters
  const AudienceFilters = [];

  // Growth Filters
  const GrowthFilters = [
    {
      title: "Video Subs Ratio",
      selector: "Video/Subs",
      tooltipInfo: "Filter influencers with video subs ratio",
      component: (args) => (
        <SingleTextfield
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setVideoSubsRatio}
          data={videoSubsRatio}
          clearIt={setClearVideoSubsRatio}
          placeholder={"Enter Video/Subs Ratio in %"}
        />
      ),
    },
    {
      title: "Likes Views Ratio",
      selector: "Like/views",
      tooltipInfo: "Filter influencers with likes views ratio",
      component: (args) => (
        <SingleTextfield
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setLikesViewsRatio}
          data={likesViewsRatio}
          clearIt={setClearLikesViewsRatio}
          placeholder={"Enter Likes/Views Ratio in %"}
        />
      ),
    },
    {
      title: "Comment Views Ratio",
      selector: "Comment/views",
      tooltipInfo: "Filter influencers with comment views ratio",
      component: (args) => (
        <SingleTextfield
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setCommentViewsRatio}
          data={commentViewsRatio}
          clearIt={setClearCommentViewsRatio}
          placeholder={"Enter Comment/Views Ratio in %"}
        />
      ),
    },
    {
      title: "Video upload gap",
      selector: "video upload gap",
      tooltipInfo: "Filter influencers with video upload gap",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setVideoUploadGap}
          data={videoUploadGap}
          clearIt={setClearVideoUploadGap}
        />
      ),
    },
    {
      title: "Video Shorts Ratio",
      selector: "Video/Shorts",
      tooltipInfo: "Filter influencers with video shorts ratio",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setVideoShortsRatio}
          data={videoShortsRatio}
          clearIt={setClearVideoShortsRatio}
        />
      ),
    },
    {
      title: "Average Posted Content",
      selector: "Avg Posted Content",
      tooltipInfo: "Filter influencers with average posted content",
      component: (args) => (
        <ForMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgPostedContent}
          data={avgPostedContent}
          clearIt={setClearAvgPostedContent}
          inLabel={"Months"}
        />
      ),
    },
    {
      title: "Average Posted Content Long",
      selector: "Avg Posted Content Length Long",
      tooltipInfo: "Filter influencers with average posted content long",
      component: (args) => (
        <ForMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgPostedContentLong}
          data={avgPostedContentLong}
          clearIt={setClearAvgPostedContentLong}
          inLabel={"Mintues"}
        />
      ),
    },
    {
      title: "Average Posted Content Short",
      selector: "Avg Posted Content Length Shorts",
      tooltipInfo: "Filter influencers with average posted content short",
      component: (args) => (
        <ForMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgPostedContentShort}
          data={avgPostedContentShort}
          clearIt={setClearAvgPostedContentShort}
          inLabel={"Seconds"}
        />
      ),
    },
    {
      title: "Views in Timeframe",
      selector: "Views in timeframe",
      tooltipInfo: "Filter influencers with views in timeframe",
      component: (args) => (
        <ForMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setViewsInTimeframe}
          data={viewsInTimeframe}
          clearIt={setClearViewsInTimeframe}
          inLabel={"Mintues"}
        />
      ),
    },
  ];

  // Performance Filters
  const PerformanceFilters = [
    {
      title: "Engagement Rate",
      selector: "Er",
      tooltipInfo: "Filter influencers with engagement rate range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setEngagementRate}
          data={engagementRate}
          clearIt={setClearEngagementRate}
        />
      ),
    },
    {
      title: "Average Likes",
      selector: "Avg Likes",
      tooltipInfo: "Filter influencers with average likes range",
      component: (args) => (
        <ForInMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgLikes}
          data={avgLikes}
          clearIt={setClearAvgLikes}
          predefinedOptions={inTimeList}
        />
      ),
    },
    {
      title: "Average Comments",
      selector: "Avg Comments",
      tooltipInfo: "Filter influencers with average comments range",
      component: (args) => (
        <ForInMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgComments}
          data={avgComments}
          clearIt={setClearAvgComments}
          predefinedOptions={inTimeList}
        />
      ),
    },
    {
      title: "Comment Rate",
      selector: "Comment Rate",
      tooltipInfo: "Filter influencers with comment rate range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setCommentRate}
          data={commentRate}
          clearIt={setClearCommentRate}
        />
      ),
    },
    {
      title: "Average Views(long Videos)",
      selector: "Avg View per long Videos",
      tooltipInfo: "Filter influencers with average views range on long videos",
      component: (args) => (
        <ForInMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgViewsPerLongVideo}
          data={avgViewsPerLongVideo}
          clearIt={setClearAvgViewsPerLongVideo}
          predefinedOptions={inTimeList}
        />
      ),
    },
    {
      title: "Average Views(short Videos)",
      selector: "Avg View per Shorts Videos",
      tooltipInfo:
        "Filter influencers with average views range on short videos",
      component: (args) => (
        <ForInMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setAvgViewsPerShortVideo}
          data={avgViewsPerShortVideo}
          clearIt={setClearAvgViewsPerShortVideo}
          predefinedOptions={inTimeList}
        />
      ),
    },
    {
      title: "Most Recent Video Days",
      selector: "Most Recent Video Days",
      tooltipInfo: "Filter influencers with most recent video days range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setMostRecentVideoDays}
          data={mostRecentVideoDays}
          clearIt={setClearMostRecentVideoDays}
        />
      ),
    },
    {
      title: "Total Videos",
      selector: "Total Videos",
      tooltipInfo: "Filter influencers with total videos range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setTotalVideos}
          data={totalVideos}
          clearIt={setClearTotalVideos}
        />
      ),
    },
    {
      title: "Sponsored Video",
      selector: "Sponored Video",
      tooltipInfo: "Filter influencers with sponsored videos",
      component: (args) => (
        <ForInMinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setSponseredVideo}
          data={sponsoredVideo}
          clearIt={setClearSponseredVideo}
          predefinedOptions={inTimeList}
        />
      ),
    },
    {
      title: "Sponored Video Performance",
      selector: "Sponored Video Performance",
      tooltipInfo: "Filter influencers with sponsored videos performance",
      component: (args) => (
        <SingleTextfield
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setSponsoredVideoPerformance}
          data={sponsoredVideoPerformance}
          clearIt={setClearSponsoredVideoPerformance}
        />
      ),
    },
    {
      title: "Total Views",
      selector: "TotalViews",
      tooltipInfo: "Filter influencers with total views range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setTotalViews}
          data={totalViews}
          clearIt={setClearTotalViews}
        />
      ),
    },
    {
      title: "Channel Creation Date",
      selector: "Channel Creation Date",
      tooltipInfo: "Filter influencers with total views range",
      component: (args) => (
        <MinMaxFilterComponent
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setChannelCreationDate}
          data={channelCreationDate}
          clearIt={setClearChannelCreationDate}
        />
      ),
    },
    {
      title: "Has Shorts",
      selector: "has shorts",
      tooltipInfo: "Filter influencers with shorts videos",
      component: (args) => (
        <SingleCheckbox
          selector={args?.selector}
          title={args?.title}
          tooltipInfo={args?.tooltipInfo}
          key={args?.selector}
          setDataMethod={setHasShorts}
          data={
            hasShorts == true ? "yes" : madeForKids == false ? "no" : hasShorts
          }
          clearIt={setClearHasShorts}
          label={"Select Has Shorts"}
          predefinedOptions={[
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
          ]}
        />
      ),
    },
    // {
    // Todo - title: "Mostly Posted Content Type",
    // },
  ];

  // Responsive
  const matches = useMediaQuery("(max-width:600px)");

  // Advance Filters Action
  const handleShowResults = () => {
    // setFilterState(filterApplied);
    fetchWithFilters();
  };
  const clearAllFilters = () => {
    // influencer
    clearSubsSize();
    clearLocation();
    clearAge();
    clearGender();
    clearLanguage();
    clearYoutubeCategory();
    clearCategory();
    clearVerified();
    clearContactInfo();
    clearVerifiedContact();
    clearSocialPlatforms();
    clearInfluencerInterest();
    clearMentions();
    clearHashtags();
    // clearAdvancedKeyWords();
    clearMadeForKids();
    clearInChannelName();
    // engagement
    clearEngagementRate();
    clearAvgLikes();
    clearAvgComments();
    clearCommentRate();
    clearAvgViewsPerLongVideo();
    clearAvgViewsPerShortVideo();
    clearMostRecentVideoDays();
    clearTotalVideos();
    clearSponseredVideo();
    clearSponsoredVideoPerformance();
    clearTotalViews();
    clearChannelCreationDate();
    clearHasShorts();
    // clearMostlyPosttedContentType();
    // growth
    clearVideoSubsRatio();
    clearLikesViewsRatio();
    clearCommentViewsRatio();
    clearVideoUploadGap();
    clearVideoShortsRatio();
    clearAvgPostedContentLong();
    clearAvgPostedContent();
    clearAvgPostedContentShort();
    clearViewsInTimeframe();
    fetchInfluencers(1, 25);
  };
  const handleClose = () => {
    set(false);
  };

  const filterData = useMemo(() => {
    return {
      sub_size: subcribersSize,
      location: location,
      Age: age,
      gender: gender,
      language: language,
      "Youtube Category": youtubeCategory,
      Category: category,
      Verified: verified,
      "Contact Info": contactInfo,
      "Verified Contact": verifiedContact,
      Social: socialPlatforms,
      "Influencer Interest": influencerInterest,
      Mentions: mentions,
      Hashtags: hashtags,
      "Made for Kids":
        madeForKids == "yes" ? true : madeForKids == "no" ? false : madeForKids,
      "In Channel Name": inChannelName,
      Er: engagementRate,
      "Avg Likes": avgLikes,
      "Avg Comments": avgComments,
      "Comment Rate": commentRate,
      "Avg View per long Videos": avgViewsPerLongVideo,
      "Avg View per Shorts Videos": avgViewsPerShortVideo,
      "Most Recent Video Days": mostRecentVideoDays,
      "Total Videos": totalVideos,
      "Sponored Video": sponsoredVideo,
      "Sponored Video Performance": sponsoredVideoPerformance,
      TotalViews: totalViews,
      "Channel Creation Date": channelCreationDate,
      "has shorts":
        hasShorts == "yes" ? true : hasShorts == "no" ? false : hasShorts,
      "Video/Subs": videoSubsRatio,
      "Like/views": likesViewsRatio,
      "Comment/views": commentViewsRatio,
      "video upload gap": videoUploadGap,
      "Video/Shorts": videoShortsRatio,
      "Avg Posted Content": avgPostedContent,
      "Avg Posted Content Length Long": avgPostedContentLong,
      "Avg Posted Content Length Shorts": avgPostedContentShort,
      "Views in timeframe": viewsInTimeframe,
    };
  }, [
    subcribersSize,
    location,
    age,
    gender,
    language,
    youtubeCategory,
    category,
    verified,
    contactInfo,
    verifiedContact,
    socialPlatforms,
    influencerInterest,
    mentions,
    hashtags,
    madeForKids,
    inChannelName,
    engagementRate,
    avgLikes,
    avgComments,
    commentRate,
    avgViewsPerLongVideo,
    avgViewsPerShortVideo,
    mostRecentVideoDays,
    totalVideos,
    sponsoredVideo,
    sponsoredVideoPerformance,
    totalViews,
    channelCreationDate,
    hasShorts,
    videoSubsRatio,
    likesViewsRatio,
    commentViewsRatio,
    videoUploadGap,
    videoShortsRatio,
    avgPostedContent,
    avgPostedContentLong,
    avgPostedContentShort,
    viewsInTimeframe,
  ]);

  useEffect(() => {
    const isFilterDataEmpty = Object.values(filterData).every(
      (value) => value === null
    );
    if (isFilterDataEmpty) {
      setFilterState(false);
    } else {
      setFilterState(true);
    }

    let cleanedFilterData = Object.fromEntries(
      Object.entries(filterData).filter(([_, value]) => value !== null)
    );

    console.log("filterData", filterData);
    console.log("filterState", isFilterDataEmpty);
    console.log("cleanedFilterData", cleanedFilterData);

    setFilterData(cleanedFilterData);
  }, [filterData]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: { width: "50%", maxHeight: "100%" },
        }}
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Box display={"flex"} height={"100%"} flexDirection={"column"}>
          {/* Header */}
          <Box
            position={"relative"}
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            height={"auto"}
            paddingY={2}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box position={"absolute"} right={0} padding={3} size={"auto"}>
              <Tooltip title="close" placement="right" arrow>
                <IconButton onClick={handleClose}>
                  <CloseRounded />
                </IconButton>
              </Tooltip>
            </Box>

            <Box
              display={"flex"}
              flexGrow={1}
              justifyContent={"center"}
              gap={1}
            >
              <img
                src={AdvIcon}
                alt=""
                width={40}
                style={{ display: matches ? "none" : "block" }}
              />
              <Typography
                fontWeight={"bold"}
                fontSize={matches ? "1.2rem" : "1.7rem"}
              >
                {`Advance Filters ${selectedSocial}`}
              </Typography>
            </Box>
          </Box>

          {/* Body - contains all the filters */}
          <Box
            overflow={"scroll"}
            display={"flex"}
            flexDirection={"column"}
            flexGrow={1}
            width={"100%"}
            paddingX={2}
            sx={{ overflowX: "visible" }}
          >
            <Grid container spacing={2}>
              <Grid paddingY={2} size={4}>
                <Grid size={12} textAlign={"center"}>
                  <FilterCategoryHeader>Influencer</FilterCategoryHeader>
                </Grid>
                <Grid mb={2} size={12}>
                  <Divider />
                </Grid>
                <Grid container size={12} rowSpacing={1}>
                  {InfluencerFilters?.map((filter) => (
                    <Grid size={12}>{filter.component(filter)}</Grid>
                  ))}
                </Grid>
              </Grid>

              <Grid paddingY={2} container gap={3} size={4}>
                {/* <Grid size={12}>
                                    <Grid size={12} textAlign={'center'}>
                                        <FilterCategoryHeader>Audience</FilterCategoryHeader>
                                    </Grid>
                                    <Grid mb={2} size={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid container size={12} rowSpacing={1}>
                                        {AudienceFilters?.map((filter) => (<Grid size={12}>
                                            {filter.component(filter)}
                                        </Grid>))}
                                    </Grid>
                                </Grid> */}
                <Grid size={12}>
                  <Grid size={12} textAlign={"center"}>
                    <FilterCategoryHeader>Growth</FilterCategoryHeader>
                  </Grid>
                  <Grid mb={2} size={12}>
                    <Divider />
                  </Grid>
                  <Grid container size={12} rowSpacing={1}>
                    {GrowthFilters?.map((filter) => (
                      <Grid size={12}>{filter.component(filter)}</Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>

              <Grid paddingY={2} size={4}>
                <Grid size={12} textAlign={"center"}>
                  <FilterCategoryHeader>Engagement</FilterCategoryHeader>
                </Grid>
                <Grid mb={2} size={12}>
                  <Divider />
                </Grid>
                <Grid container size={12} rowSpacing={1}>
                  {PerformanceFilters?.map((filter) => (
                    <Grid size={12}>{filter.component(filter)}</Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Footer */}
          <Box paddingY={2}>
            <Divider />
            <Box
              paddingX={3}
              paddingY={1}
              display={"flex"}
              width={"100%"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Button
                  startIcon={<DeleteOutlineOutlined />}
                  onClick={clearAllFilters}
                  disabled={filterStateParent ? false : true}
                >
                  Clear All Filters
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  sx={{
                    color: "white",
                    borderRadius: 20,
                    background: "#3DBE72",
                    "&:hover": { background: "#3DBE72" },
                    textTransform: "none",
                  }}
                  onClick={handleShowResults}
                >
                  Show Results
                </Button>
              </Box>
            </Box>
            <Divider />
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default YTAdvancedFilters;
