import React, { useState } from "react";
import Papa from "papaparse";
import axios from "axios";
import { Button } from "@mui/material";
import { updateCollection2 } from "../../../service/collections api/createCollection.js";

const URL = process.env.REACT_APP_BASE_URL;

function CsvFileUploader({ addInfluencersWithCSV }) {
  const [csvData, setCsvData] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        const results = Papa.parse(csv, {
          header: true,  // Use the first row as header
          dynamicTyping: true,  // Automatically convert numbers from strings
          complete: function (results) {
            // Extract the 'id' column values
            const idArray = results.data.map(row => row.id);
            setCsvData(idArray);
            console.log(idArray);
          }
        });
        setUploadStatus(file.name);
      };
      reader.readAsText(file);
    }
  };

  // const sendDataToApi = async () => {
  //   try {
  //     let newDestnationIDs = [...JSON.parse(InfluencersIds)];

  //     csvData.forEach((row) => {
  //       newDestnationIDs.push(row);
  //     });

  //     console.log("collectionID", collectionID);
  //     console.log("newDestnationIDs", newDestnationIDs);

  //     const uniqueArray = [...new Set(newDestnationIDs)];

  //     const response = updateCollection2({
  //       "id": collectionID,
  //       "influncers_id": `[${uniqueArray.toString()}]`
  //     })
  //     if (response != false) {
  //       // getAllCollection();
  //     } else {
  //       console.log("wasn't able to add influencers from another collection")
  //     }
  //   } catch (error) {
  //     console.error("Error sending data from CSV:", error);
  //   }
  // };

  function handleClickSend() {
    addInfluencersWithCSV(csvData);
  };

  return (
    <div>
      {!csvData.length ? (
        <>
          <input type="file" onChange={handleFileUpload} />
        </>
      ) : (
        <>
          <Button
            variant="contained"
            onClick={handleClickSend}
            disabled={!csvData.length}
          >
            Send
          </Button>
        </>
      )}

      {uploadStatus && (
        <p
          style={{
            marginTop: "1rem",
          }}
        >
          {uploadStatus}
        </p>
      )}
    </div>
  );
}

export default CsvFileUploader;
