import axios from "axios";
const URL = process.env.REACT_APP_BASE_URL;

export const getAllCollection = async () => {
  try {
    const response = await axios.post(`${URL}/collection/getall`);
    const data = response.data;
    console.log("response from data in get all collection", data);
    if (data) {
      return data;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.log("error", error);
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};

export const getAllCollectionCount = async () => {
  try {
    const response = await axios.post(`${URL}/collection/count`);
    return response;
  } catch (error) {
    console.log("error", error);
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};
export const getBrandList = async () => {
  try {
    const response = await axios.post(`${URL}/brand/list`);
    return response;
  } catch (error) {
    console.log("error", error);
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};

export const getAllFilterCollecetion = async (payload) => {
  try {
    const response = await axios.post(`${URL}/getallcollections`, payload);
    const data = response.data;
    console.log("response from data in get all collection", data);
    if (data) {
      return data;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.log("error", error);
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};

export const getSearchedCollection = async (search) => {
  try {
    console.log("search", search);
    const response = await axios.post(
      `${URL}/collection/search?query=${search}`
    );

    console.log("response", response);
    if (response.status === 200) {
      const data = response.data.collections;

      if (data) {
        return data;
      } else {
        console.error("Error: collections is empty");
        return false;
      }
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};

export const getFilteredCollection = async (payload) => {
  try {
    console.log(payload);
    const response = await axios.post(`${URL}/collection/get/filter`, payload);

    const data = response.data;
    console.log("response from data", response);
    if (data) {
      return data;
    } else {
      console.error("Error: collections is empty");
      return false;
    }
  } catch (error) {
    console.error(
      "error occurs while fetch all collections : " + error.message
    );
  }
};
