import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Slider,
  TextField,
  Menu,
  MenuItem,
  ListItemButton,
  IconButton,
  Tooltip,
  styled,
  Autocomplete,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
  marginBottom: "10px",
}));

const KeywordSearch4= ({
  text,
  options,
  timeframes,
  postsTimeframes,
  setKeywordFilterState,
  clear,
  tooltipInfo,
  setAvgLikesState,
  setAvgReelsViewsState,
  setAvgContentLenState,
}) => {
  const [value, setValue] = useState(options[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [value2, setValue2] = useState([0, 1000000]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1000000);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState(timeframes);

  useEffect(() => {
    if (clear) {
      setValue(options[0]);
      setValue2([0, 1000000]);
      setMinValue(0);
      setMaxValue(1000000);
    }
  }, [clear, options]);

  const handleChange2 = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    setValue2(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);

    if (setAvgLikesState) {
      setAvgLikesState({ min: newValue[0], max: newValue[1] });
    } else if (setAvgReelsViewsState) {
      setAvgReelsViewsState({ min: newValue[0], max: newValue[1] });
    } else if (setAvgContentLenState) {
      setAvgContentLenState({ min: newValue[0], max: newValue[1] });
    }
  };

  const handleMinChange = (event) => {
    const newMinValue =
      event.target.value === "" ? "" : parseInt(event.target.value, 10) || 0;
    setMinValue(newMinValue);
    setValue2([newMinValue, maxValue]);
  };

  const handleMaxChange = (event) => {
    const newMaxValue =
      event.target.value === ""
        ? ""
        : parseInt(event.target.value, 10) || 1000000;
    setMaxValue(newMaxValue);
    setValue2([minValue, newMaxValue]);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option) => {
    setValue(option);
    setAnchorEl(null);
    setKeywordFilterState((prev) => [...prev, option]);
    setInputValue("");
    if (option === "Days") {
      setDropdownOptions(timeframes);
    } else if (option === "Posts") {
      setDropdownOptions(postsTimeframes);
    }
  };

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start", paddingTop: 0 }}>
        <Box>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {setAvgLikesState
              ? "Has Shorts in"
              : setAvgReelsViewsState
              ? "Has Shorts in"
              : "Has Shorts in"}
            <span style={{ fontWeight: "normal", color: "#5296dd" }}>
              <ListItemButton onClick={handleClick}>{value}</ListItemButton>
            </span>
          </Typography>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {options.map((option) => (
              <MenuItem key={option} onClick={() => handleSelect(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box sx={{ marginTop: 1 }}>
          <Autocomplete
            sx={{
              marginTop: 2,
              width: "100%",
              "& .MuiInputLabel-root": {
                color: "#3dbe72",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#3dbe72",
                },
                "&:hover fieldset": {
                  borderColor: "#3dbe72",
                },
              },
              "& .MuiInputBase-input": {
                color: "#3dbe72",
              },
            }}
            options={dropdownOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select"
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiInputLabel-root": {
                    color: "#3dbe72",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#3dbe72",
                    },
                    "&:hover fieldset": {
                      borderColor: "#3dbe72",
                    },
                  },
                  "& .MuiInputBase-input": {
                    color: "#3dbe72",
                  },
                }}
              />
            )}
            value={inputValue}
            onChange={(event, newValue) => setInputValue(newValue)}
            fullWidth
          />
        </Box>

        <Box sx={{ marginTop: 1 }}></Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default KeywordSearch4;
