import React, { useState, useEffect } from 'react';
import {
    Autocomplete,
    TextField,
    Chip,
    Box,
    createFilterOptions
} from '@mui/material';

const CustomMultiSelectWithTextfield = ({
    options = [],
    value = [],
    onChange,
    label = 'Select or enter values',
    placeholder = 'Type to search or add...',
    error = false,
    helperText = '',
    disabled = false,
    required = false,
    size = 'medium',
    fullWidth = true,
    maxHeight = 300,
}) => {
    // Filter config that allows adding new options
    const filter = createFilterOptions();

    // Handle changes including new values
    const handleChange = (event, newValue) => {
        // Process new values to ensure they have the correct format
        const processedValues = newValue.map(option => {
            if (typeof option === 'string') {
                // Handle raw string input
                return {
                    label: option,
                    value: option.toLowerCase(),
                    custom: true
                };
            } else if (option && option.inputValue) {
                // Handle new option created by user
                return {
                    label: option.inputValue,
                    value: option.inputValue.toLowerCase(),
                    custom: true
                };
            }
            return option;
        }).filter(
            (option, index, self) =>
                index === self.findIndex((item) => item.label.toLowerCase() === option.label.toLowerCase())
        ); // Filter out duplicates

        // Call parent's onChange with processed values
        onChange(processedValues);
    };

    return (
        <Autocomplete
            multiple
            value={value}
            onChange={handleChange}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;

                // Exclude already selected options
                const selectedValues = value.map((item) => item.label.toLowerCase());
                const filteredOptions = filtered.filter(
                    (option) => !selectedValues.includes(option.label.toLowerCase())
                );

                // Add option to create new value if input doesn't match existing options
                const isExisting = options.some(
                    option => option.label.toLowerCase() === inputValue.toLowerCase()
                );
                if (inputValue !== '' && !isExisting) {
                    filteredOptions.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                        custom: true
                    });
                }

                return filteredOptions;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={options}
            getOptionLabel={(option) => {
                // Handle different types of option values
                if (typeof option === 'string') {
                    return option;
                }
                if (option.inputValue) {
                    return option.inputValue;
                }
                return option.label;
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    {option.label}
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        label={option.label}
                        {...getTagProps({ index })}
                        key={index}
                        size="small"
                        // color={option.custom ? "info" : "primary"}
                        color={"primary"}
                    />
                ))
            }
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={error}
                    helperText={helperText}
                    required={required}
                    size={size}
                />
            )}
            fullWidth={fullWidth}
            ListboxProps={{
                style: {
                    maxHeight: maxHeight,
                }
            }}
            disabled={disabled}
        />
    );
};

export default CustomMultiSelectWithTextfield;