import { GroupsRounded, TurnedInNotOutlined } from "@mui/icons-material";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useState } from "react";

const CategorieBtn = styled(Button)(({ theme }) => ({
  marginTop: 4,
  textTransform: "capitalize",
  background: "#3DBE72",
  boxShadow: "none",
  borderRadius: "20px",
  padding: "3px 25px",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "3px 15px",
  },
}));

const AddCampaignBtn = styled(Button)(({ theme }) => ({
  textTransform: "capitalize",
  background: "#3DBE72",
  borderRadius: 20,
  padding: "3px 10px",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    padding: "5px 10px",
  },
}));

const AddCollectionBtn = styled(Button)(({ theme }) => ({
  color: "#3DBE72",
  borderColor: "#3DBE72",
  borderRadius: 20,
  textTransform: "none",
  padding: "3px 10px",
  boxShadow: "none",
  "&:hover": {
    borderColor: "#3DBE72",
    boxShadow: "none",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 10,
    padding: "5px 10px",
  },
}));

const avt3 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU";

const CardComponent = () => {
  const [selectedRowArr, setSelectedRowArr] = useState([]);
  const [currentCardItems, setCurrentCardItems] = useState([]);

  const handleRowClick = ({ id }) => {};

  const handleCardClick1 = ({ id }) => {};
  return (
    <>
      <Grid container spacing={3} p={4}>
        {currentCardItems.map(
          ({ photo, followers, name, likes, engagement, categories, id }) => (
            <Grid item md={3}>
              <Grid
                container
                p={2}
                sx={{
                  bgcolor: "#FFFFFF",
                  borderRadius: 5,
                  border: "1px solid black",
                  height: "300px",
                }}
                onClick={() => {
                  selectedRowArr.some((selectedRow) => selectedRow.id === id)
                    ? setSelectedRowArr(
                        selectedRowArr.filter(
                          (selectedRow) => selectedRow.id !== id
                        )
                      )
                    : setSelectedRowArr([...selectedRowArr, { id, name }]);

                  console.log(id);
                }}
                style={{
                  cursor: "pointer",
                  backgroundColor: selectedRowArr.some(
                    (selectedRow) => selectedRow.id === id
                  )
                    ? "#BFFFBF"
                    : "white",
                }}
              >
                <Grid item xs={4}>
                  <Box
                    display={"flex"}
                    alignItems={"start"}
                    width={"100%"}
                    height={"100%"}
                    justifyContent={"center"}
                  >
                    <AvatarGroup
                      sx={{ width: "100%", height: "100%", padding: "5px" }}
                    >
                      <Avatar
                        onClick={() => handleRowClick({ id })}
                        alt="Remy Sharp"
                        src={avt3}
                        sx={{
                          borderRadius: "50%",
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </AvatarGroup>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={8}
                  sx={{ padding: 1 }}
                  onClick={() => handleCardClick1({ id })}
                >
                  <Typography sx={{ paddingLeft: 1, fontWeight: "bold" }}>
                    Full Name
                  </Typography>
                  <CategorieBtn variant="contained" size="small">
                    {"Tech Influencer"}
                  </CategorieBtn>
                </Grid>
                <Grid container>
                  <Grid item mt={2} xs={4} align={"center"}>
                    <Typography
                      sx={{
                        fontSize: ".6rem",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      Followers
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: ".8rem",
                        fontWeight: "bold",
                        wordWrap: "break-word", // Break the word to the next line
                        whiteSpace: "normal", // Allow the text to wrap
                        width: "100%",
                      }}
                    >
                      10M
                    </Typography>
                  </Grid>
                  <Grid
                    ml={0.5}
                    item
                    mt={2}
                    xs={4}
                    align={"center"}
                    sx={{
                      borderLeft: "1px solid black",
                      overflow: "hidden",
                    }}
                  >
                    <Typography sx={{ fontSize: ".6rem", fontWeight: "bold" }}>
                      Engag. Rate
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: ".8rem",
                        fontWeight: "bold",
                        padding: "2px",
                        marginBottom: "5px",
                        wordWrap: "break-word", // Break the word to the next line
                        whiteSpace: "normal", // Allow the text to wrap
                        width: "100%",
                      }}
                    >
                      12.5%
                    </Typography>
                  </Grid>
                  <Grid
                    ml={0.5}
                    item
                    mt={2}
                    xs={3}
                    align={"center"}
                    sx={{ borderLeft: "1px solid black" }}
                  >
                    <Typography sx={{ fontSize: ".6rem", fontWeight: "bold" }}>
                      Avg. Likes
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: ".8rem",
                        fontWeight: "bold",
                        padding: "2px",
                        marginBottom: "5px",
                        wordWrap: "break-word", // Break the word to the next line
                        whiteSpace: "normal", // Allow the text to wrap
                        width: "100%",
                      }}
                    >
                      122K
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} mt={3} align={"center"}>
                  <AddCampaignBtn
                    size="small"
                    startIcon={<GroupsRounded />}
                    variant="contained"
                    sx={{ width: "90%" }}
                  >
                    Add to Campaign
                  </AddCampaignBtn>
                </Grid>
                <Grid item xs={12} mt={2} align={"center"}>
                  <AddCollectionBtn
                    size="small"
                    variant="outlined"
                    startIcon={<TurnedInNotOutlined />}
                    sx={{ width: "90%" }}
                  >
                    Add to Collection
                  </AddCollectionBtn>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
};

export default CardComponent;
