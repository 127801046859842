import { useEffect, useState } from "react";
import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const MinMaxFieldGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "1rem",
  alignItems: "center",
}));

const SelectFilterComp2 = ({
  text,
  setFollowersSize,
  setFollowingSize,
  setTotalPostsState,
  setAvgContentLengthSize,
  clear,
  tooltipInfo,
}) => {
  const [value2, setValue2] = useState([0, 1000000]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(1000000);

  useEffect(() => {
    if (setAvgContentLengthSize) {
      setValue2([10, 10000]);
      setMinValue(10);
      setMaxValue(10000);
    }
    if (clear) {
      if (setAvgContentLengthSize) {
        setValue2([10, 10000]);
        setMinValue(10);
        setMaxValue(1000);
      } else {
        setValue2([0, 1000000]);
        setMinValue(0);
        setMaxValue(1000000);
      }
    }
  }, [clear]);

  const handleChange2 = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (setFollowersSize) {
      setFollowersSize({ min: minValue, max: maxValue });
    } else if (setFollowingSize) {
      setFollowingSize({ min: minValue, max: maxValue });
    } else if (setAvgContentLengthSize) {
      setAvgContentLengthSize({ min: minValue, max: maxValue });
    } else setTotalPostsState({ min: minValue, max: maxValue });

    setValue2(newValue);
    setMinValue(newValue[0]);
    setMaxValue(newValue[1]);
  };

  const handleMinChange = (event) => {
    const newMinValue = event.target.value;
    if (newMinValue === "") {
      setMinValue("");
    } else {
      const parsedValue = parseInt(newMinValue, 10);
      if (isNaN(parsedValue)) {
        setMinValue(0);
      } else {
        setMinValue(parsedValue);
      }
    }
  };

  const handleMaxChange = (event) => {
    const newMaxValue = event.target.value;
    if (newMaxValue === "") {
      setMaxValue("");
    } else {
      const parsedValue = parseInt(newMaxValue, 10);
      if (isNaN(parsedValue)) {
        setMaxValue(1000000);
      } else {
        setMaxValue(parsedValue);
      }
    }
  };

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          textAlign: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ textAlign: "start" }}>
        <Slider
          // getAriaLabel={() => "Minimum distance shift"}
          value={[minValue, maxValue]}
          onChange={handleChange2}
          valueLabelDisplay="auto"
          disableSwap
          style={{ color: "#3dbe72", padding: 0, width: "100%" }}
          size="small"
          min={0}
          max={1000000}
        />
        <MinMaxFieldGroup>
          <TextField
            variant="outlined"
            type="number"
            value={minValue}
            onChange={handleMinChange}
            style={{ marginRight: "10px" }}
            size="small"
            label="min"
            sx={{
              width: "50%",
              padding: 0,
              margin: 0,
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
              },
              "&:hover.MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
              },
            }}
          />
          <SwapHorizIcon style={{ marginRight: 6, color: "#3dbe72" }} />
          <TextField
            variant="outlined"
            type="number"
            label="max"
            value={maxValue}
            onChange={handleMaxChange}
            size="small"
            sx={{
              width: "50%",
              "&.MuiOutlinedInput-notchedOutline": {
                borderColor: "#3dbe72",
                color: "#3dbe72",
              },
              "&:hover.MuiOutlinedInput-notchedOutline": {
                color: "#3dbe72",
                borderColor: "#3dbe72",
              },
            }}
          />
        </MinMaxFieldGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default SelectFilterComp2;
