import React, { useEffect } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const RadioButtonFilter = ({
  text,
  buttons,
  setRadioButtonFilterState,
  setVerifiedState,
  setInfluencersGender,
  clear,
  tooltipInfo,
}) => {
  const [selectedRadioBtn, setSelectedRadioBtn] = useState(buttons[0]);

  useEffect(() => {
    if (clear) {
      setSelectedRadioBtn(buttons[0]);
    }
  }, [clear]);

  if (setVerifiedState) setVerifiedState(selectedRadioBtn);
  else setInfluencersGender(selectedRadioBtn);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start", paddingLeft: "20px" }}>
        <FormControl>
          {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={selectedRadioBtn}
            name="radio-buttons-group"
            onChange={(e) => {
              setRadioButtonFilterState(e.target.value);
              setSelectedRadioBtn(e.target.value);
            }}
            value={selectedRadioBtn}
          >
            {buttons.map((btn) => {
              return (
                <FormControlLabel
                  value={btn}
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#3dbe72",
                        },
                      }}
                    />
                  }
                  label={btn}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default RadioButtonFilter;
