import React, { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';

const CustomSingleSelectCheckboxGroup = ({
    options = [],
    label = '',
    selectedValues = '',
    onChange
}) => {

    const handleCheckboxChange = (option) => {
        const isSelected = selectedValues === option.value;

        // Update selected array based on checkbox selection
        let updatedSelected;
        if (isSelected) {
            updatedSelected = '';
        } else {
            updatedSelected = option.value;
        }

        onChange(updatedSelected); // Send updated selected options to the parent
    };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{label}</FormLabel>
            <FormGroup>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        control={
                            <Checkbox
                                checked={selectedValues === option.value}
                                onChange={() => handleCheckboxChange(option)}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default CustomSingleSelectCheckboxGroup;
