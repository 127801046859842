import {  Typography, Button,Divider, styled } from '@mui/material'
import Grid from '@mui/material/Grid2';
import React from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


const FooterBtn = styled(Button)(({theme}) => ({

    textTransform : 'capitalize',
    color : 'black',
    fontWeight : 'bold',
    fontSize : 12

}))

const ShowResultBtn = styled(Button)(({theme}) => ({

    background: '#3DBE72',
    borderRadius : 20,
    '&:hover' : {
        background: '#3DBE72',
    }

}))

const AdvFilterFooter = () => {
  return (
    <Grid container>
        <Divider style={{width : '80%', margin : '10px'}}/>
        <Grid item md={6}>
            <FooterBtn startIcon={<DeleteOutlineOutlinedIcon/>}>Clear All Filters</FooterBtn>
        </Grid>
        <Grid item md={3}>
            <FooterBtn >Total Results : 2091</FooterBtn>
        </Grid>
        <Grid item md={3}>
            <ShowResultBtn  variant="contained">Show Results</ShowResultBtn>
        </Grid>
        <Divider style={{width : '80%',  margin : '10px'}}/>
    </Grid>
  )
}

export default AdvFilterFooter