// import React, { useEffect, useState } from 'react'
// import { Box, Button, TextField, Typography, styled, Badge, Divider, Avatar, IconButton } from '@mui/material';
// import Grid from '@mui/material/Grid2';
// import { AdsClick } from '@mui/icons-material'
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { Settings, ManageAccounts, Verified } from '@mui/icons-material'
// import Logo from '../images/logo_CreatorHunt-white.png'
// import insta from '../images/insta-sm.png'
// import youtube from '../images/youtube-sm.png'
// import DP from '../images/dp.png'
// import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
// import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
// import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
// import MailIcon from '@mui/icons-material/Mail';
// //AXIOS API :
// import { genConfirmInstaOTP } from '../../service/api';
// import { genConfirmYTOTP } from '../../service/genOtpApi';
// import QRCode from "react-qr-code";

// const Container = styled(Box)(({ theme }) => ({

//     display: 'flex',
//     // height : '100%',

//     flexDirection: 'column',
//     alignItems: 'center',
//     marginTop: '2rem',
//     marginBottom: '3rem',
//     // justifyContent : 'center'   ,

//     [theme.breakpoints.down('sm')]: {
//         // paddingLeft: '1rem'
//         marginTop: '0rem',

//     }


// }))

// const Subcontainer = styled(Box)(({ theme }) => ({

//     width: '40%',
//     [theme.breakpoints.down('sm')]: {
//         width: '100%',
//         padding: '1.4rem'
//     }


// }))

// const QRBox = styled(Box)(({ theme }) => ({

//     width: '120px',
//     marginBottom: 5,
//     [theme.breakpoints.down('sm')]: {
//         width: '120px'
//     }


// }))

// const SecondSubContainer = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     background: '#DDE8F8',
//     alignItems: 'center',
//     padding: 20,
//     borderRadius: 5,
//     marginTop: 15,
//     [theme.breakpoints.down('sm')]: {
//         flexDirection: 'column',
//         marginTop: 15
//     }

// }))

// const Influencer = styled(Box)(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: '10px 20px',
//     borderRadius: 5,

//     margin: '2rem 0px',
//     [theme.breakpoints.down('sm')]: {
//         padding: 0
//     }


// }))

// const InfluencerTitle = styled(Box)(({ theme }) => ({
//     marginRight: '5rem',
//     [
//         theme.breakpoints.down('sm')
//     ]: {
//         marginRight: 15
//     }
// }))

// const MyButton = styled(Button)(({ theme }) => ({

//     color: 'Black',
//     width: '100%',
//     textTransform: 'capitalize',
//     fontWeight: 'bold',
//     fontSize: '13px',
//     // background: '#3DBE7233',
//     // border: '1px solid #3DBE72',
//     boxShadow: 'none',
//     marginRight: 10,

//     '&:hover': {
//         // background: '#3DBE7233',
//         border: '1px solid #3DBE72',
//         boxShadow: 'none',
//     },
//     [theme.breakpoints.down('sm')]: {
//         width: 'auto',
//     }

// }))

// const SmallAvatar = styled(Avatar)(({ theme }) => ({
//     width: 22,
//     height: 22,
//     border: `2px solid white`,
//     background: 'white'
// }));



// const InstagramConfirm = () => {

//     // this is for insta 
//     const [isTimeUp, setIsTimeUp] = useState({
//         status: false,
//         message: "The opt is valid for a limited period only"
//     })

//     const [instaOTPcountdown, setInstaOtpCountdown] = useState(120); // 2 minutes in seconds

//     // this is for youtube 

//     const [isYtTimeUp, setIsYtTimeUp] = useState({
//         status: false,
//         message: "The opt is valid for a limited period only"
//     })


//     const [YtOTPcountdown, setYtOtpCountdown] = useState(300); // 2 minutes in seconds

//     // Use the useMediaQuery hook to check for screen size

//     const isSmallScreen = useMediaQuery('(max-width:600px)');


//     const [isICopy, setIsICopy] = useState(false);
//     const [isYCopy, setIsYCopy] = useState(false);


//     const Inumber = null;
//     const Ynumber = null;
//     const [instaOTP, setInstaOTP] = useState(Inumber)
//     const [YoutubeOTP, setYoutubeOTP] = useState(Ynumber);



//     const InstaDigits = Array.from(String(instaOTP, ''), Number);
//     const YoutubeDigits = Array.from(String(YoutubeOTP, ''), Number);



//     const handleInstaCopy = async () => {
//         try {
//             await navigator.clipboard.writeText(instaOTP);
//             setIsICopy(true);
//         } catch (err) {
//             console.error('Failed to copy: ', err);
//         }
//     };

//     const handleYoutubeCopy = async () => {
//         try {
//             await navigator.clipboard.writeText(YoutubeOTP);
//             setIsYCopy(true);
//         } catch (err) {
//             console.error('Failed to copy: ', err);
//         }
//     };




//     // Generate opt for insta comments : 
//     const genInstaOTP = async () => {
//         const user_id = {
//             id: 1
//         };
//         const res = await genConfirmInstaOTP(user_id);
//         console.log(res);
//         const OTP = res.data.user.db_insta_comments;
//         console.log(OTP);
//         setInstaOTP(OTP);
//         setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })
//         setInstaOtpCountdown(120); // Reset the countdown timer

//     }

//     const formatTime = (timeInSeconds) => {
//         const minutes = Math.floor(timeInSeconds / 60);
//         const seconds = timeInSeconds % 60;
//         return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
//     };

//     useEffect(() => {
//         setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })
//         const timer = setInterval(() => {
//             if (instaOTPcountdown > 0) {
//                 setInstaOtpCountdown(instaOTPcountdown - 1);
//             } else {
//                 clearInterval(timer);
//                 // Handle countdown completion if needed
//                 setInstaOTP(null)
//                 setIsTimeUp({ status: true, message: "TIME OUT" })
//             }
//         }, 1000);

//         return () => clearInterval(timer);
//         setInstaOTP(null); // Reset the OTP value in the cleanup function

//     }, [instaOTPcountdown]);



//     // Generate opt for Youtube verification : 

//     const genYtOTP = async () => {
//         const user_id = {
//             id: 1
//         };
//         const res = await genConfirmYTOTP(user_id);
//         console.log(res);
//         const OTP = res.data.user.db_yt_comment;
//         console.log(OTP);
//         setYoutubeOTP(OTP);
//         setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })
//         setYtOtpCountdown(300); // Reset the countdown timer

//     }


//     useEffect(() => {
//         setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })
//         const timer = setInterval(() => {
//             if (YtOTPcountdown > 0) {
//                 setYtOtpCountdown(YtOTPcountdown - 1);
//             } else {
//                 clearInterval(timer);
//                 // Handle countdown completion if needed
//                 setYoutubeOTP(null)
//                 setIsYtTimeUp({ status: true, message: "TIME OUT" })
//             }
//         }, 1000);

//         return () => clearInterval(timer);
//         setYoutubeOTP(null); // Reset the OTP value in the cleanup function

//     }, [YtOTPcountdown]);




//     useEffect(() => {
//         // for instagram 
//         genInstaOTP();
//         setIsTimeUp({ status: false, message: "The opt is valid for a limited period only" })

//         // for youtube 
//         genYtOTP();
//         setIsYtTimeUp({ status: false, message: "The opt is valid for a limited period only" })

//     }, [])


//     return (
//         <Container >
//             <Subcontainer>
//                 <Box style={{ display: 'flex', justifyContent: 'center' }}>
//                     <img src={Logo} alt="" width={170} />
//                 </Box>

//                 <Typography style={{ fontWeight: 'bold', fontSize: '1.2rem', textAlign: 'center' }}>Confirm Your Social Media Account</Typography>


//                 <Grid border={1} container style={{ display: 'flex', alignItems: 'center' }}>
//                     <Grid border={1} md={12} xs={12} sx={{ mt: 3, mb: 3 }}>
//                         <Grid border={1}  container >
//                             <Grid border={1} item md={2} xs={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }} >
//                                 <Badge
//                                     overlap="circular"
//                                     anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//                                     badgeContent={
//                                         <SmallAvatar alt="Remy Sharp" src={insta} />
//                                     }
//                                 >
//                                     <Avatar alt="influencer profile pic" src={DP} sx={{ height: 60, width: 60 }} />
//                                 </Badge>
//                             </Grid>
//                             <Grid item md={6} xs={7} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}>
//                                 <Typography style={{ fontWeight: 'bold', fontSize: '1rem' }}>Abhishek Malviya</Typography>
//                                 <Typography style={{ color: '#00000080', fontSize: '0.8rem' }}>@abhishek.malviya20</Typography>

//                             </Grid>
//                             <Grid item md={4} xs={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                 {
//                                     isSmallScreen
//                                         ?

//                                         <IconButton size='small'>
//                                             <ManageAccounts sx={{ color: '#3DBE72' }} />
//                                         </IconButton>
//                                         :
//                                         <MyButton startIcon={<ManageAccounts />} variant='text' size='small' style={{ textTransform: 'capitalize', color: '#3DBE72' }}>Change account</MyButton>

//                                 }

//                             </Grid>

//                         </Grid>
//                     </Grid>

//                     <Grid item md={12} xs={12} sx={{ mb: 2 }}>
//                         <Divider />
//                     </Grid>

//                 </Grid>

//                 <Grid container>
//                     <Grid item md={12} xs={12}>
//                         <Grid container>
//                             <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: 1, marginBottom: 3 }}>
//                                 <img src={insta} alt="" />
//                                 <Typography sx={{ fontSize: '1.2rem', marginLeft: .5 }}>Confirm Your Instagram</Typography>
//                             </Grid>
//                             <Grid item md={6}>
//                                 <Typography sx={{ fontSize: '.8rem' }}>1. Remember or copy this 4-digit confirmation code to your clipboard</Typography>

//                                 <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>

//                                     {
//                                         instaOTP !== null &&
//                                         <Box mt={1}>
//                                             {
//                                                 InstaDigits?.map((value, index) => (
//                                                     <span key={index} style={{
//                                                         border: '1px solid #ccc',
//                                                         borderRadius: '4px',
//                                                         padding: '6px 12px',
//                                                         marginRight: '5px',
//                                                         width: '40px',
//                                                         textAlign: 'center',
//                                                         background: 'rgba(0, 255, 105, 0.2)',
//                                                     }}>{value}</span>
//                                                 ))
//                                             }


//                                         </Box>


//                                     }


//                                     <Box mt={2}>
//                                         <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: isTimeUp.status ? '#e91e63' : '#3dbf73ff' }}>
//                                             {`${isTimeUp.message} : ${formatTime(instaOTPcountdown)}`}
//                                         </Typography>


//                                         {
//                                             isTimeUp.status &&
//                                             <Button variant='text'
//                                                 sx={{ textTransform: 'none', padding: 0, color: '#3dbf73ff', fontWeight: 'bold' }}

//                                                 endIcon={<AdsClick />}
//                                                 onClick={() => genInstaOTP()}
//                                             >
//                                                 Generate OTP
//                                             </Button>
//                                         }


//                                     </Box>



//                                     <Box mt={2}>
//                                         <Button onClick={() => handleInstaCopy()} size='small' style={{ color: '#3DBE72', textTransform: 'capitalize' }} variant='text' startIcon={isICopy ? <InventoryOutlinedIcon /> : <ContentPasteOutlinedIcon />} > {isICopy ? 'Copied' : 'Copy to ClipBoard'}</Button>
//                                     </Box>
//                                 </Box>
//                             </Grid>
//                             <Grid item md={6} sx={{ marginTop: { md: 0, xs: 4 } }}>
//                                 <Grid container>

//                                     <Grid item md={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#dce7f7ff', padding: 2, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
//                                         <QRBox>
//                                             <QRCode value={'https://www.instagram.com/p/C19HYwxy--Z/'} size={256}
//                                                 style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
//                                         </QRBox>
//                                     </Grid>
//                                     <Grid item md={7} xs={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#dce7f7ff', borderBottomRightRadius: 8, borderTopRightRadius: 8 }}>
//                                         <Typography sx={{ fontSize: '.8rem', marginLeft: { md: 1, xs: 0 }, }}>Open Instagram post by clicking the link below and leave comment with 4-digit confirmation
//                                             code to it from <span style={{ display: 'flex', alignItems: 'center' }}>@zamanastiva <Verified fontSize='.8rem' sx={{ color: '#0093f5ff' }} /></span> </Typography>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                             <Grid item mt={2} md={12} xs={12} align='end'>
//                                 <Button size='small' variant='contained'
//                                     sx={{
//                                         boxShadow: 'none',
//                                         fontWeight: 'bold',
//                                         bgcolor: '#4caf50',
//                                         padding: '5px 15px',
//                                         '&:hover': { bgcolor: '#4caf50', }
//                                     }}
//                                 >Verify</Button>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                     <Grid item md={12} xs={12} mt={3} mb={3}>
//                         <Divider />
//                     </Grid>
//                     {/* <Grid item md={12} xs={12}>
//                         <Grid container>
//                             <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', marginTop: 1, marginBottom: 3 }}>
//                                 <img src={youtube} alt="" width={24} />
//                                 <Typography sx={{ fontSize: '1.2rem', marginLeft: .5 }}>Confirm Your Youtube </Typography>
//                             </Grid>
//                             <Grid item md={6}>
//                                 <Typography sx={{ fontSize: '.9rem', }}>1. Remember or copy this 4-digit confirmation code to your clipboard</Typography>

//                                 <Box style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>

//                                     {
//                                         YoutubeOTP !== null
//                                         &&
//                                         <Box mt={1}>
//                                             {
//                                                 YoutubeDigits?.map((value, index) => (
//                                                     <span key={index} style={{
//                                                         border: '1px solid #ccc',
//                                                         borderRadius: '4px',
//                                                         padding: '6px 12px',
//                                                         marginRight: '5px',
//                                                         width: '40px',
//                                                         textAlign: 'center',
//                                                         background: 'rgba(0, 255, 105, 0.2)',
//                                                     }}>{value}</span>
//                                                 ))
//                                             }


//                                         </Box>
//                                     }



//                                     <Box mt={2}>
//                                         <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold', color: isYtTimeUp.status ? '#e91e63' : '#3dbf73ff' }}>
//                                             {`${isYtTimeUp.message} : ${formatTime(YtOTPcountdown)}`}
//                                         </Typography>


//                                         {
//                                             isYtTimeUp.status &&
//                                             <Button variant='text'
//                                                 sx={{ textTransform: 'none', padding: 0, color: '#3dbf73ff', fontWeight: 'bold' }}

//                                                 endIcon={<AdsClick />}
//                                                 onClick={() => genYtOTP()}
//                                             >
//                                                 Generate OTP
//                                             </Button>
//                                         }


//                                     </Box>
//                                     <Box mt={2}>
//                                         <Button onClick={() => handleYoutubeCopy()} size='small' style={{ color: '#3DBE72', textTransform: 'capitalize' }} variant='text' startIcon={isYCopy ? <InventoryOutlinedIcon /> : <ContentPasteOutlinedIcon />} > {isYCopy ? 'Copied' : 'Copy to ClipBoard'}</Button>
//                                     </Box>
//                                 </Box>
//                             </Grid>
//                             <Grid item md={6} sx={{ marginTop: { md: 0, xs: 4 } }}>
//                                 <Grid container>

//                                     <Grid item md={5} xs={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: '#dce7f7ff', padding: 2, borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
//                                         <QRBox>
//                                             <QRCode value={'https://www.instagram.com/'} size={256}
//                                                 style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
//                                         </QRBox>
//                                     </Grid>
//                                     <Grid item md={7} xs={7} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#dce7f7ff', borderBottomRightRadius: 8, borderTopRightRadius: 8 }}>
//                                         <Typography sx={{ fontSize: '.8rem', marginLeft: { md: 1, xs: 0 }, }}>Open Instagram post by clicking the link below and leave comment with 4-digit confirmation
//                                             code to it from <span>@abhishek._.malviya</span></Typography>
//                                     </Grid>
//                                     <Grid item mt={2} md={12} xs={12} align='end'>
//                                         <Button size='small' variant='contained'
//                                             sx={{
//                                                 boxShadow: 'none',
//                                                 fontWeight: 'bold',
//                                                 bgcolor: '#4caf50',
//                                                 padding: '5px 15px',
//                                                 '&:hover': { bgcolor: '#4caf50', }
//                                             }}
//                                         >Verify</Button>
//                                     </Grid>
//                                 </Grid>
//                             </Grid>
//                         </Grid>
//                     </Grid> */}
//                 </Grid>
//             </Subcontainer>
//         </Container>
//     )
// }

// export default InstagramConfirm


import React, { useEffect } from 'react';

import { Container, Box, Typography, Stack, Button, Avatar, Link, Tooltip, IconButton } from "@mui/material";
import { ContentCopy } from '@mui/icons-material';

import Logo from '../images/logo_CreatorHunt-white.png'
import QRCode from 'react-qr-code';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ytGenerateOTP, ytVerifyOTP } from '../../service/api';
import { useDispatch } from 'react-redux';
import { setRedirectURL, setSocialVerificationStatus } from '../../features/socialverification/SocialVerification';

export default function InstagramConfirm() {

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [platform, setPlatform] = React.useState('Youtube');
    const [copied, setCopied] = React.useState(false);

    const [socialUname, setSocialUname] = React.useState('');
    const [socialHandle, setSocialHandle] = React.useState('');
    const [platformId, setPlatformId] = React.useState('');
    const [profileLink, setProfileLink] = React.useState('');

    const [Otp, setOtp] = React.useState([]);
    const [isOTPsent, setIsOTPsent] = React.useState(false);

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const platform = params.get('platform');
        setPlatform(platform);

        const socialUname = params.get('socialuname');
        setSocialUname(socialUname);

        const socialHandle = params.get('handle');
        setSocialHandle(socialHandle);

        const platformId = params.get('platformid');
        setPlatformId(platformId);

        const profileLink = params.get('profilephoto');
        setProfileLink(profileLink);

    }, [searchParams]);

    const sendOTP = () => {
        if (platformId != '' && platformId != null && isOTPsent === false) {
            console.log('Platform ID: ', platformId);
            // Fetch the platform details using the platform

            ytGenerateOTP({ "otp/genrate": platformId }).then((res) => {
                console.log('OTP Response: ', res);
                if (res.status === 200) {
                    setIsOTPsent(true);
                    const otp = res.data.otp.split('');
                    console.log('OTP: ', otp);
                    setOtp(otp);
                }
            })
        }
    };

    // Copy to clipboard function
    const handleCopy = async () => {
        try {
            if (Otp.length > 0) {
                const otpAsString = Otp.join('');
                const otpAsInteger = parseInt(otpAsString, 10);
                await navigator.clipboard.writeText(otpAsInteger);
                setCopied(true);
            }
        } catch (err) {
            console.error("Failed to copy text: ", err);
        }
    };

    const handleToolTipClose = () => {
        setTimeout(() => {
            setCopied(false);
        }, 150);
    };

    const handleVerifytheAccount = () => {
        if (platform === 'youtube') {
            // Call the API to verify the Youtube account
            const otpAsString = Otp.join('');
            const otpAsInteger = parseInt(otpAsString, 10);
            ytVerifyOTP({ "youtube_id": platformId, "otp": otpAsInteger })
                .then((res) => {
                    console.log('Verify Response: ', res);
                    if (res.status === 200) {
                        // Redirect to the next page
                        console.log('Account Verified Successfully');

                        dispatch(setSocialVerificationStatus(true));
                        dispatch(setRedirectURL(''));
                        navigate('/dashboard/home');
                    }
                });
        }
    }

    return (
        <Container sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            margin: 'auto',
            padding: 'auto',
        }} maxWidth="sm">
            <Stack width={'100%'} gap={3} >
                <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'center', width: "100%", height: "auto", padding: 0 }}>
                    {/* You can replace this with an actual image */}
                    <img src={Logo} alt="" width={160} />
                    {/* <Image src="/icons/logo_CreatorHunt-white.png" alt="Creator Hunt Logo" fill objectFit='contain' /> */}
                </Box>
                <Box>
                    <Typography variant="h3" fontWeight={700} fontSize={'1.4rem'} gutterBottom>
                        {`Confirm Your ${platform} Account`}
                    </Typography>
                </Box>
                <Box sx={(theme) => ({
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid',
                    borderColor: theme.palette.primary.contrastText,
                    // backgroundColor: alpha('primary', 0.25),
                    boxShadow: `grey 0 0 4px 0.05rem`,
                    width: '100%',
                    padding: "10px 16px",
                    gap: 1
                })}>
                    <Avatar sx={{ width: '44px', height: '44px' }} alt="Remy Sharp" src={profileLink} >o</Avatar>
                    <Box display={'flex'} padding={'0 14px'} flexGrow={1}>
                        <Stack direction='column' padding={0} margin={0} spacing={0} gap={0}>
                            <Typography variant='body1' fontSize={'1.2rem'} fontWeight={'bold'} textTransform={'capitalize'}>
                                {socialUname}
                            </Typography>
                            <Typography variant='body2'>
                                {socialHandle}
                            </Typography>
                        </Stack>
                    </Box>
                    <Button size='small'>Change Account</Button>
                </Box>
                <Box>
                    <Typography variant='body1' fontSize={'1.1em'} fontWeight={300} gutterBottom>
                        1. Remember or copy this 4-digit confirmation code to your clipboard
                    </Typography>
                    <Box display={'flex'} justifyContent={'start'} mt={2}>
                        {isOTPsent ?
                            (Otp?.map((value, index) => (
                                <span key={index} style={{
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '6px 12px',
                                    marginRight: '5px',
                                    width: '40px',
                                    textAlign: 'center',
                                    background: 'rgba(0, 255, 105, 0.2)',
                                }}>{value}</span>
                            )))
                            : <Button variant='contained' onClick={sendOTP}>
                                Get OTP
                            </Button>
                        }
                    </Box>

                </Box>
                <Box>
                    <Typography variant='body1' fontSize={'1.1em'} fontWeight={300} gutterBottom>
                        2. Open Instagram post by clicking the link below and leave comment with 4-digit confirmation
                        code to it from <Typography component={'span'} fontWeight={'bold'}>{socialHandle}</Typography>
                    </Typography>
                    <Box
                        mt={2}
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#E0F3FF', // Light blue background
                            padding: '16px',
                            borderRadius: '8px',
                            // boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            maxWidth: '100%',
                        }}
                    >
                        {/* QR Code Section */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: { xs: '8px', md: '16px' },
                                marginRight: { md: '16px' }, // Add margin on larger screens
                                marginBottom: { xs: '16px', md: '0' }, // Add bottom margin on small screens
                            }}
                        >
                            {/* Graps marketing channel */}
                            <QRCode value={'https://www.youtube.com/watch?v=DjZVRWq-d_w'} size={120}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }} />
                        </Box>

                        {/* Text Section */}
                        <Box
                            gap={2}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: { xs: 'center', md: 'flex-start' },
                                textAlign: { xs: 'center', md: 'left' },
                                maxWidth: '320px',
                            }}
                        >
                            {/* Copy to clipboard link */}
                            <Tooltip sx={{ padding: 0 }} onClick={handleCopy} onClose={handleToolTipClose} color="primary" title={copied ? "Copied!" : "Copy to clipboard"}>
                                <IconButton >
                                    <ContentCopy />
                                    <Typography color="primary.dark" ml={1} fontSize={'14px'} component={'span'}>
                                        Copy OTP
                                    </Typography>
                                </IconButton>
                            </Tooltip>

                            {/* Description */}
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#333',
                                    fontSize: '14px',
                                    lineHeight: '1.5',
                                }}
                            >
                                Open Instagram post by clicking the link below and leave a comment with 4-digit confirmation code to it from
                                <Link
                                    href={`https://www.youtube.com/channel/${platformId}`} // Replace with actual Instagram link
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        fontWeight: 'bold',
                                        textDecoration: 'none',
                                        marginLeft: '4px',
                                    }}
                                >
                                    {socialUname}
                                </Link>
                            </Typography>
                        </Box>
                    </Box>
                    {/* <FormTextField
                        // {...field}
                        // margin="normal"
                        required
                        fullWidth
                        id="name"
                        // label="Full Name"
                        placeholder="Enter your Instagram/Youtube username"
                        name="name"
                        autoComplete="name"
                        variant='outlined'
                        // error={!!errors.name}
                        // helperText={errors.name?.message}
                        InputLabelProps={{ shrink: true }}
                    /> */}
                </Box>
                <Box textAlign={'end'}>
                    <Button variant='contained' disabled={!isOTPsent} onClick={handleVerifytheAccount}>
                        Next
                    </Button>
                </Box>
            </Stack>
        </Container >
    )
}
