import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from "../features/user/userSlice.js";
import influencerReducer from "../features/influencers/influencersSlice.js";
import authorizationReducer from "../features/authorization/AuthorizationSlice.js";
import sharedStateReducer from "../features/drawerStatus/SharedStateSlice.js";
import customColumnStateReducer from "../features/customColumn/CustomColumnSlice.js";
import socialVerificationReducer from "../features/socialverification/SocialVerification.js";

import rolesReducer from "../features/rolesAndPermissions/RolesAndPermissions.js";

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  // whitelist: ['roles'], // We are persisting the roles slice only
};

const appReducer = combineReducers({
  roles: rolesReducer,
  user: userReducer,
  influencers: influencerReducer,
  authorization: authorizationReducer,
  sharedState: sharedStateReducer,
  customColumnState: customColumnStateReducer,
  socialVerification: socialVerificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    storage.removeItem('persist:root'); // Clear persist storage
    state = undefined; // Reset the Redux state
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

export { store, persistor };
