import { useDispatch } from 'react-redux';

const useLogout = () => {
    const dispatch = useDispatch();

    const logout = () => {
        dispatch({ type: 'auth/logout' });
    };

    return logout;
};

export default useLogout;