import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    verificationStatus: false,
    redirectURL: '',
};

export const socialVerificationSlice = createSlice({
    name: "socialVerification",
    initialState: initialState,
    reducers: {
        setSocialVerificationStatus: (state, action) => {
            state.verificationStatus = action.payload;
        },
        setRedirectURL: (state, action) => {
            state.redirectURL = action.payload;
        },
    },
});

export const { setSocialVerificationStatus, setRedirectURL } = socialVerificationSlice.actions;

export default socialVerificationSlice.reducer;
