import React, { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
  Item,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import SettingsIcon from "@mui/icons-material/Settings";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "max-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "start",
  justifyContent: "center",
};

function GearDropdown({ can, setCan }) {
  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [priority, setPriority] = useState("");
  const [toggleStates, setToggleStates] = useState({
    srNo:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    profile:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    collectionName:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    numInfluencers:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    brand:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    createdOn:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    status:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    actions:
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
  });

  const [toggleStates2, setToggleStates2] = useState({
    "location":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "avg age":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "category":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "views":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "Audience language":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "Audience brand affinities":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "Profile Audience Likers":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
    "Audience lookalike":
      priority == "low"
        ? true
        : priority == "medium"
          ? true
          : priority == "high"
            ? true
            : false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDropdownChange = (event) => {
    const priority = event.target.value;
    setDropdownValue(priority.toLowerCase());
    setCan(priority);

    switch (priority) {
      case "low":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: false,
          brand: false,
          createdOn: false,
          status: false,
          actions: false,
        });
        setToggleStates2({
          "location": true,
          "avg age": true,
          "category": true,
          "views": true,
          "Audience language": false,
          "Audience brand affinities": false,
          "Profile Audience Likers": false,
          "Audience lookalike": false,
        });
        break;
      case "medium":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: false,
          brand: true,
          createdOn: false,
          status: true,
          actions: false,
        });
        setToggleStates2({
          "location": true,
          "avg age": true,
          "category": true,
          "views": true,
          "Audience language": true,
          "Audience brand affinities": true,
          "Profile Audience Likers": false,
          "Audience lookalike": false,
        });
        break;
      case "high":
        setToggleStates({
          srNo: true,
          profile: true,
          collectionName: true,
          numInfluencers: true,
          brand: true,
          createdOn: true,
          status: true,
          actions: true,
        });
        setToggleStates2({
          "location": true,
          "avg age": true,
          "category": true,
          "views": true,
          "Audience language": true,
          "Audience brand affinities": true,
          "Profile Audience Likers": true,
          "Audience lookalike": true,
        });
        break;
      default:
        break;
    }
  };

  const handleToggleChange = (event) => {
    setToggleStates({
      ...toggleStates,
      [event.target.name]: event.target.checked,
    });
  };

  const handleToggleChange2 = (event) => {
    setToggleStates2({
      ...toggleStates2,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <h2 id="modal-title">Hide Custom Fields</h2>
          <FormControl
            fullWidth
            sx={{
              border: "#3dbf73ff",
              color: "#3dbf73ff",
              "&:focus": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:active": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:outline": { border: "#3dbf73ff", color: "#3dbf73ff" },
            }}
          >
            <InputLabel
              sx={{ border: "#3dbf73ff", color: "#3dbf73ff" }}
              id="dropdown-label"
            >
              Priority
            </InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={dropdownValue}
              label="Priority"
              onChange={handleDropdownChange}
              sx={{
                border: "#3dbf73ff",
                color: "#3dbf73ff",
              }}
            >
              <MenuItem sx={{ fontWeight: "bold" }} value="low">
                Low
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="medium">
                Medium
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="high">
                High
              </MenuItem>
            </Select>
          </FormControl>
          <Box>
            <Typography variant="h5">
              Influencer
            </Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "7px",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Box sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
              }}>
                {Object.keys(toggleStates).map((key, index) => {
                  if (index < 4) return (<FormControlLabel
                    key={key}
                    control={
                      <Switch
                        checked={toggleStates[key]}
                        onChange={handleToggleChange}
                        name={key}
                        color="success"
                        sx={{
                          color: "#3dbf73ff",
                          "&:focus": {
                            color: "#3dbf73ff",
                          },
                          "&:active": {
                            color: "#3dbf73ff",
                          },
                        }}
                      />
                    }
                    label={
                      key.charAt(0).toUpperCase() +
                      key.slice(1).replace(/([A-Z])/g, " $1")
                    }
                  />
                  )
                })}
              </Box>
              <Box sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
              }}>
                {Object.keys(toggleStates).map((key, index) => {
                  if (index >= 4) return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Switch
                          checked={toggleStates[key]}
                          onChange={handleToggleChange}
                          name={key}
                          color="success"
                          sx={{
                            color: "#3dbf73ff",
                            "&:focus": {
                              color: "#3dbf73ff",
                            },
                            "&:active": {
                              color: "#3dbf73ff",
                            },
                          }}
                        />
                      }
                      label={
                        key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/([A-Z])/g, " $1")
                      }
                    />
                  )
                })}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography variant="h5">
              Audience
            </Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "7px",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Box sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
              }}>
                {Object.keys(toggleStates2).map((key, index) => {
                  if (index < 4) return (<FormControlLabel
                    key={key}
                    control={
                      <Switch
                        checked={toggleStates2[key]}
                        onChange={handleToggleChange2}
                        name={key}
                        color="success"
                        sx={{
                          color: "#3dbf73ff",
                          "&:focus": {
                            color: "#3dbf73ff",
                          },
                          "&:active": {
                            color: "#3dbf73ff",
                          },
                        }}
                      />
                    }
                    label={
                      key.charAt(0).toUpperCase() +
                      key.slice(1).replace(/([A-Z])/g, " $1")
                    }
                  />
                  )
                })}
              </Box>
              <Box sx={{
                display: "flex",
                width: "auto",
                flexDirection: "column",
              }}>
                {Object.keys(toggleStates2).map((key, index) => {
                  if (index >= 4) return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Switch
                          checked={toggleStates2[key]}
                          onChange={handleToggleChange2}
                          name={key}
                          color="success"
                          sx={{
                            color: "#3dbf73ff",
                            "&:focus": {
                              color: "#3dbf73ff",
                            },
                            "&:active": {
                              color: "#3dbf73ff",
                            },
                          }}
                        />
                      }
                      label={
                        key.charAt(0).toUpperCase() +
                        key.slice(1).replace(/([A-Z])/g, " $1")
                      }
                    />
                  )
                })}
              </Box>
            </Box>
          </Box>

          {/* <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "7px",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {Object.keys(toggleStates).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    checked={toggleStates[key]}
                    onChange={handleToggleChange}
                    name={key}
                    color="success"
                    sx={{
                      color: "#3dbf73ff",
                      "&:focus": {
                        color: "#3dbf73ff",
                      },
                      "&:active": {
                        color: "#3dbf73ff",
                      },
                    }}
                  />
                }
                label={
                  key.charAt(0).toUpperCase() +
                  key.slice(1).replace(/([A-Z])/g, " $1")
                }
              />
            ))}
          </Box> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3dbf73ff",
              "&:hover": {
                backgroundColor: "#3dbf73ff",
              },
            }}
            onClick={handleClose}
            fullWidth
          >
            Share
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default GearDropdown;
