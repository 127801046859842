import React from 'react';
import { TextField } from '@mui/material';

const NumericTextField = ({
    value,
    onChange,
    placeholder = 'Enter a number',
    disabled = false,
    size = 'small',
    error = false,
    fullWidth = false,
    allowDecimals = false,
    allowNegative = false,
}) => {
    const handleInputChange = (event) => {
        const newValue = event.target.value;
        // Only allow numerical input by matching digits
        if (/^\d*$/.test(newValue)) {
            onChange(newValue);
        }
    };

    const getError = (isMin) => {
        if (error) return true;
        if (value === '') return false;
    };

    return (
        <TextField
            value={value}
            onChange={handleInputChange}
            placeholder={placeholder}
            size={size}
            disabled={disabled}
            error={getError(false)}
            slotProps={{
                htmlInput: {
                    inputMode: 'numeric',
                    pattern: allowDecimals
                        ? (allowNegative ? '-?[0-9]*[.]?[0-9]*' : '[0-9]*[.]?[0-9]*')
                        : (allowNegative ? '-?[0-9]*' : '[0-9]*'),
                }
            }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    borderRadius: '4px',
                },
                width: fullWidth ? '100%' : '120px',
            }}
        />
    );
};

export default NumericTextField;