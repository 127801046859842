import React, { useMemo } from "react";
import { memo, useEffect, useState } from "react";

// MUI Components
import { Box, IconButton, Tooltip, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";

// Icons
import { ExpandMore, HelpOutlineRounded } from "@mui/icons-material";
import CustomCheckboxGroup from "../customInputComponents/checkboxGroup";

// Custom Components

const MulltiCheckbox = memo(({ title, selector, tooltipInfo, label, setDataMethod, data, clearIt, predefinedOptions = [] }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    useMemo(() => {
        if (data) {
            let updatedData = [];
            predefinedOptions.forEach((option, index) => {
                if (data.includes(option.value)) {
                    updatedData.push(option);
                }
            });
            setSelectedOptions(updatedData);
        }
        console.log("incoming data memo running");
    }, [data]);

    useEffect(() => {
        selectedOptions.length > 0 ? setDataMethod(selectedOptions.map((option, index) => {
            return option.value;
        })) : setDataMethod(null);
    }, [selectedOptions]);

    const clearMultiCheckbox = () => {
        setSelectedOptions([]);
    };

    useEffect(() => {
        clearIt(() => clearMultiCheckbox);
    }, [clearIt]);

    return (
        <Accordion sx={{ boxShadow: 'none' }}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "start",
                    }}
                >
                    <IconButton style={{ color: "#3DBE72" }}>
                        <Tooltip title={tooltipInfo}>
                            <HelpOutlineRounded
                                style={{ fontSize: 14, padding: 0, margin: 0 }}
                            />
                        </Tooltip>
                    </IconButton>
                    <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0, textAlign: "start" }}>
                <CustomCheckboxGroup
                    label={label}
                    options={predefinedOptions}
                    selectedValues={selectedOptions}
                    onChange={(newSelectedOptions) => setSelectedOptions(newSelectedOptions)}
                />
            </AccordionDetails>
        </Accordion>
    );
}, (prevProps, nextProps) => {
    return prevProps.setDataMethod === nextProps.setDataMethod && prevProps.clearIt === nextProps.clearIt;
});

export default MulltiCheckbox;