import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL, // Set your base URL here
});

// Add a request interceptor to attach the token
axiosInstance.interceptors.request.use(
    (config) => {
        // Get token from localStorage
        const token = localStorage.getItem('authToken');

        // If token exists, set the Authorization header
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle error
        return Promise.reject(error);
    }
);

// Set up response interceptor to handle errors and token refreshing
axiosInstance.interceptors.response.use(
    (response) => response, // Return response if successful
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to an expired token (401 Unauthorized)
        if (error.response?.status === 401 && !originalRequest._retry) {
            // If the token has expired, log out the user
            // LogoutProcess();
            window.location.href = "/login";
            // originalRequest._retry = true;

            // // Attempt to refresh the token
            // const refreshToken = localStorage.getItem('refreshToken');
            // if (refreshToken) {
            //     try {
            //         const response = await axios.post('https://yourapi.com/api/refresh', {
            //             token: refreshToken,
            //         });

            //         const newAccessToken = response.data.accessToken;

            //         // Update the access token and retry the original request
            //         localStorage.setItem('accessToken', newAccessToken);
            //         axiosInstance.defaults.headers.Authorization = `Bearer ${newAccessToken}`;
            //         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

            //         return axiosInstance(originalRequest); // Retry the original request
            //     } catch (refreshError) {
            //         // If refresh fails, log out the user
            //         console.error('Refresh token expired or invalid, logging out...');
            //         axiosInstance();
            //         return Promise.reject(refreshError);
            //     }
            // } else {
            //     // No refresh token available, log out
            //     axiosInstance();
            // }
        }

        return Promise.reject(error); // Reject error if not a 401 or if retry fails
    }
);


export default axiosInstance;
