import React, { useEffect, useState } from "react";
import {
  FormControl,
  Divider,
  Select,
  MenuItem,
  Slider,
  TextField,
  Box,
  styled,
  IconButton,
  Tooltip,
  Chip,
  Tabs,
  Tab,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";

const SearchDropdownFilter = ({
  text,
  options,
  setDropdownFilterState,
  setInfluencersLocationState,
  setInfluencersLanguageState,
  setInfluencersCategoryState,
  setInstagramCategoryState,
  setInfluencersInterestState,
  setPrevBrandSponsorsState,
  clear,
  tooltipInfo,
}) => {
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (clear) {
      setValue(options[0]);
      setSelectedValues([]);
    }
  }, [clear]);

  const handleTagDelete = (value) => {
    setSelectedValues((prevValues) => prevValues.filter((v) => v !== value));
  };

  const handleSelect = (event, newValue) => {
    if (newValue && !selectedValues.includes(newValue)) {
      setSelectedValues((prevValues) => [...prevValues, newValue]);
      setDropdownFilterState(newValue);
    }
  };

  if (setInfluencersLocationState) setInfluencersLocationState(selectedValues);
  else if (setInfluencersLanguageState)
    setInfluencersLanguageState(selectedValues);
  else if (setInfluencersCategoryState)
    setInfluencersCategoryState(selectedValues);
  else if (setInstagramCategoryState) setInstagramCategoryState(selectedValues);
  else if (setInfluencersInterestState)
    setInfluencersInterestState(selectedValues);
  else if (setPrevBrandSponsorsState) setPrevBrandSponsorsState(selectedValues);

  return (
    <Accordion sx={{ width: { md: "80%", xs: "90%" }, boxShadow: "none" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ textAlign: "start" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            gap: 1,
          }}
        >
          <IconButton style={{ color: "#3DBE72" }}>
            <Tooltip title={tooltipInfo}>
              <HelpOutlineRoundedIcon
                style={{ fontSize: 12, padding: 0, margin: 0 }}
              />
            </Tooltip>
          </IconButton>
          <Typography sx={{ fontWeight: "bold" }}>{text}</Typography>
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ textAlign: "start" }}>
        {/* {selectedValues.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => handleTagDelete(value)}
            sx={{ mt: 1, ml: 1, bgcolor: "#3DBE72", color: "#fff" }}
          />
        ))} */}
        <Autocomplete
          value={value}
          // onChange={(event, newValue) => {
          //   setValue(newValue);
          //   setDropdownFilterState(newValue);
          //   setSelectedValues(newValue);
          // }}
          onChange={handleSelect}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={options}
          sx={{
            marginTop: 2,
            width: "100%",
            "& .MuiInputLabel-root": {
              color: "#3dbe72",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3dbe72",
              },
              "&:hover fieldset": {
                borderColor: "#3dbe72",
              },
            },
            "& .MuiInputBase-input": {
              color: "#3dbe72",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiInputLabel-root": {
                  color: "#3dbe72",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#3dbe72",
                  },
                  "&:hover fieldset": {
                    borderColor: "#3dbe72",
                  },
                },
                "& .MuiInputBase-input": {
                  color: "#3dbe72",
                },
              }}
              label={text}
            />
          )}
        />
        {selectedValues.length > 0 && (
          <Box>
            <Box
              sx={{
                padding: "7px 15px",
                borderRadius: "10px",
                marginTop: "10px",
                bgcolor: "#f0f3f6",
              }}
            >
              {selectedValues.map((item) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ padding: "5px", fontWeight: "bold" }}>
                      {item}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => handleTagDelete(item)}
                      sx={{ padding: 0, margin: 0 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchDropdownFilter;
