import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Typography,
  Divider,
  Slider,
  styled,
  Box,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { IconButton } from "@mui/material";
import AdvFilterFooter from "./AdvFilterFooter";
import FormControlSelect from "./FormControlSelect";
import SelectFilterComponent from "./SelectFilterComponent";
import SelectFilterComp2 from "./SelectFilterComp2";
import AdvIcon from "../../../images/cog.png";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ButtonGroupFilter from "./ButtonGroupFilter";
import RadioButtonFilter from "./RadioButtonFilter";
import CheckboxButtonFilter from "./CheckboxButtonFilter";
import SearchDropdownFilter from "./SearchDropdownFilter";
import AgeFilter from "./AudienceAgeFilter";
import AudienceAgeFilter from "./AudienceAgeFilter";
import AudienceSearchDropdownFilter from "./AudienceSearchDropdownFilter";
import AudienceRadioButtonFilter from "./AudienceRadioButtonFilter";
import PerformanceSlider from "./PerformanceSlider";
import PerformanceSlider2 from "./PerformanceSlider2";
import PerformanceSearch from "./PerformanceSearch";
import SearchNTag from "./SearchNTag";
import SearchDropdownFilter2 from "./SearchDropdownFilter2";
import CustomSearchMinMax from "./CustomSearchMinMax";
import PerformanceSearch2 from "./PerformanceSearch2";
import TabsGroup from "./TabsGroup";
import KeywordSearch from "./KeywordSearch";
import KeywordSearch1 from "./KeywordSearch1";
import TabsGroup1 from "./TabsGroup1";
import FilterAccordian from "./FilterAccordian";
import LikesViewsComp from "./LikesViewsComp";
import KeywordSearch4 from "./KeywordSearch4";
import PerformanceSearch3 from "./PerformanceSearch3";
import VideoGapUploadFilter from "./VideoGapUploadFil";
// import DateSearchBox from "./DateSearchBox";

const Header = styled(Grid)(({ theme }) => ({
  //    display : 'flex',
  //    width : '100%',
  //    alignItems :'center',
  //    justifyContent : 'center'
  //
}));

const ColumnHead = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  fontWeight: "bold",
  textTransform: "uppercase",
}));

const AdvFilterDrawerY = ({ open, set, selectedSocial }) => {
  const handleClose = () => {
    set(false);
  };

  const locations = [
    "select",
    "India",
    "USA",
    "California",
    "New York",
    "Maharashtra",
    "Mumbai",
    "China",
    "Shanghai",
    "Beijing",
    "Australia",
    "New South Wales",
    "Sydney",
    "Canada",
    "Ontario",
    "Toronto",
    "Germany",
    "Bavaria",
    "Munich",
    "France",
    "Île-de-France",
    "Paris",
    "Japan",
    "Tokyo",
    "Osaka",
    "Brazil",
    "São Paulo",
    "Rio de Janeiro",
    "Italy",
    "Lombardy",
    "Rome",
    "South Korea",
    "Seoul",
    "Busan",
  ];

  const languages = [
    "select",
    "English",
    "Spanish",
    "Mandarin",
    "Arabic",
    "Hindi",
    "Bengali",
    "Portuguese",
    "Russian",
    "Japanese",
    "Punjabi",
    "German",
    "Javanese",
    "Wu",
    "French",
    "Telugu",
    "Vietnamese",
    "Marathi",
    "Korean",
    "Tamil",
    "Turkish",
    "Polish",
    "Thai",
    "Urdu",
    "Greek",
    "Italian",
    "Cantonese",
    "Swedish",
    "Romanian",
    "Dutch",
    "Hungarian",
    "Finnish",
    "Indonesian",
    "Norwegian",
    "Danish",
    "Czech",
    "Slovak",
    "Bulgarian",
    "Croatian",
    "Slovenian",
    "Lithuanian",
    "Latvian",
    "Estonian",
    "Hebrew",
    "Persian",
    "Afrikaans",
    "Albanian",
    "Amharic",
    "Armenian",
    "Azerbaijani",
    "Basque",
    "Belarusian",
    "Bosnian",
    "Catalan",
    "Cebuano",
    "Chichewa",
    "Corsican",
    "Welsh",
    "Yiddish",
    "Zulu",
    "Hmong",
    "Igbo",
    "Khmer",
    "Lao",
    "Malagasy",
    "Malay",
    "Mongolian",
    "Nepali",
    "Pashto",
    "Sinhala",
    "Somali",
    "Sundanese",
    "Tagalog",
    "Tajik",
    "Ukrainian",
    "Uzbek",
    "Yoruba",
  ];

  const influencersCategories = [
    "select",
    "Beauty",
    "Fashion",
    "Lifestyle",
    "Travel",
    "Food",
    "Gaming",
    "Sports",
    "Technology",
    "Business",
    "Entertainment",
  ];

  const influencersInterests = [
    "select",
    "Makeup tutorials",
    "Fashion trends",
    "Wellness",
    "Adventure travel",
    "Vegan recipes",
    "Esports",
    "Fitness motivation",
    "Gadget reviews",
    "Entrepreneurship",
    "Pop culture",
    "Sustainable living",
  ];

  const audienceInterests = [
    "select",
    "Makeup tutorials",
    "Fashion trends",
    "Wellness",
    "Adventure travel",
    "Vegan recipes",
    "Esports",
    "Fitness motivation",
    "Gadget reviews",
    "Entrepreneurship",
    "Pop culture",
    "Sustainable living",
  ];

  const contentType = [
    "select",
    "Videos",
    "Reels",
    "Stories",
    "Photos",
    "Live Streams",
    "IGTV",
    "TikTok Videos",
    "YouTube Videos",
    "Blogs",
    "Podcasts",
  ];

  const keywords = ["Anywhere", "In bio", "In content", "Exclude anywhere"];

  const timeframes = ["1 Week", "30 Days", "2 Months", "6 Months", "1 Year"];

  const postsTimeframes = ["Prev 15 posts", "Prev 30 posts", "Prev 60 posts"];

  const dropdownOptions = ["Days", "Posts"];

  const usernames = [
    { avatar: "https://example.com/avatar1.jpg", username: "JohnDoe" },
    { avatar: "https://example.com/avatar2.jpg", username: "JaneSmith" },
    { avatar: "https://example.com/avatar3.jpg", username: "BobJohnson" },
    { avatar: "https://example.com/avatar4.jpg", username: "AliceWilliams" },
    { avatar: "https://example.com/avatar5.jpg", username: "MikeBrown" },
    { avatar: "https://example.com/avatar6.jpg", username: "EmilyDavis" },
    { avatar: "https://example.com/avatar7.jpg", username: "SarahTaylor" },
    { avatar: "https://example.com/avatar8.jpg", username: "KevinWhite" },
    { avatar: "https://example.com/avatar9.jpg", username: "LisaHall" },
    { avatar: "https://example.com/avatar10.jpg", username: "DavidLee" },
  ];

  const PrevBrandSponsors = [
    "select..",
    "Amazon",
    "Apple",
    "Burger King",
    "Coca Cola",
    "Dior",
  ];

  // clear all filters
  // influencers states
  const [buttonGroupFilterState, setButtonGroupFilterState] = useState("");
  const [FollowersSize, setFollowersSize] = useState({ min: "", max: "" });
  const [FollowingSize, setFollowingSize] = useState({ min: "", max: "" });
  const [TotalPostsState, setTotalPostsState] = useState({ min: "", max: "" });
  const [DropdownFilterState, setDropdownFilterState] = useState("");
  const [InfluencersLocationState, setInfluencersLocationState] = useState("");
  const [InfluencersGender, setInfluencersGender] = useState();
  const [InfluencersLanguageState, setInfluencersLanguageState] = useState();
  const [InfluencersCategoryState, setInfluencersCategoryState] = useState();
  const [InstagramCategoryState, setInstagramCategoryState] = useState();
  const [InfluencersInterestState, setInfluencersInterestState] = useState();
  const [InfluencersLookalikeState, setInfluencersLookalikeState] = useState();
  const [VerifiedState, setVerifiedState] = useState();
  const [PreviousBrandSponsorsState, setPreviousBrandSponsorsState] =
    useState();
  const [CommentRateState, setCommentRateState] = useState();
  const [MostRecentPostState, setMostRecentPostState] = useState();
  const [AveragePostedContentState, setAveragePostedContentState] = useState();
  const [AvgContentLengthSize, setAvgContentLengthSize] = useState();
  const [ImpressionOnReelsState, setImpressionOnReelsState] = useState();
  const [ImpressionOnPostsState, setImpressionOnPostsState] = useState();
  const [ImpressionOnVideosState, setImpressionOnVideosState] = useState();

  const [AvgContentLenState, setAvgContentLenState] = useState();

  const [MentionsState, setMentionsState] = useState();
  const [HashtagsState, setHashtagsState] = useState();

  const [AudienceLocationState, setAudienceLocationState] = useState({
    location: "",
    percentage: "",
  });

  const [AudienceInterestState, setAudienceInterestState] = useState({
    interest: "",
    percentage: "",
  });

  const [AudienceMostPostedState, setAudienceMostPostedState] = useState({
    mostPosted: "",
    percentage: "",
  });

  const [AudienceLanguageState, setAudienceLanguageState] = useState({
    language: "",
    percentage: "",
  });

  const [AudienceLookalikeState, setAudienceLookalikeState] = useState();

  const [RadioButtonFilterState, setRadioButtonFilterState] = useState("");
  const [CheckboxButtonFilterState, setCheckboxButtonFilterState] = useState(
    {}
  );
  const [PerformanceFilterState, setPerformanceFilterState] = useState("");
  const [DropdownFilter2State, setDropdownFilter2State] = useState();
  const [SearchNTagFilterState, setSearchNTagFilterState] = useState();
  const [SearchChannelState, setChannelFilterState] = useState();
  const [ChannelNameState, setChannelNameState] = useState();
  const [KeywordFilterState, setKeywordFilterState] = useState([]);
  const [AudienceFilterState, setAudienceFilterState] = useState({
    selectedValue: "",
    percentage: "",
  });
  const [AudienceAgeFilterState, setAudienceAgeFilterState] = useState({
    percentage: "",
    min: "",
    max: "",
  });

  const [AudienceGenderFilterState, setAudienceGenderFilterState] = useState({
    percentage: "",
    gender: "",
  });
  const [PerformanceSliderFilterState, setPerformanceSliderFilterState] =
    useState();

  const [TabsGroupFilterState, setTabsGroupFilterState] = useState({
    selectedTab: "",
    value: "",
  });

  const [ERState, setERState] = useState();

  const [AvgLikesState, setAvgLikesState] = useState();

  const [SponsoredVideoPerformanceState, setSponsoredVideoPerformanceState] =
    useState();

  const [FollowersGrowthState, setFollowersGrowthState] = useState({
    selectedValue: "",
    value: "",
  });
  const [AvgReelsViewsState, setAvgReelsViewsState] = useState({
    selectedValue: "",
    value: "",
  });
  const [ViewsTimeframeState, setViewsTimeframeState] = useState({
    selectedValue: "",
    value: "",
    min: "",
    max: "",
  });

  const [ContactInfoState, setContactInfoState] = useState();
  const [madeforInfoState, setmadeforInfoState] = useState();
  const [VerifiedContactInfoState, setVerifiedContactInfoState] = useState();
  const [SocialLinksState, setSocialLinksState] = useState();
  const [PrevBrandSponsorsState, setPrevBrandSponsorsState] = useState([]);
  const [HasSponsoredPostsState, setHasSponsoredPostsState] = useState();

  const [AvgCommentsPerVideoState, setAvgCommentsPerVideoState] = useState();
  const [AvgViewsPerVideoState, setAvgViewsPerVideoState] = useState();

  // clear filter states end

  const [clear, setClear] = useState(false);

  const clearAllFilters = () => {
    setClear(true);

    setTimeout(() => {
      setClear(false);
    }, 500);
  };

  const showResult = () => {
    console.log("Account type: ", buttonGroupFilterState);
    console.log("Followers size: ", FollowersSize);
    console.log("Following size: ", FollowingSize);
    console.log("Total Posts: ", TotalPostsState);
    console.log("Influencer location: ", InfluencersLocationState);
    console.log("Influencer language: ", InfluencersLanguageState);
    console.log("Influencer gender: ", InfluencersGender);
    console.log("Influencer category: ", InfluencersCategoryState);
    console.log("Instagram category: ", InfluencersCategoryState);
    console.log("Instagram category: ", InstagramCategoryState);
    console.log("Influencer interest: ", InfluencersInterestState);
    console.log("Influencer lookalike: ", InfluencersLookalikeState);
    console.log("Verified : ", VerifiedState);
    console.log("Previous brand sponsors : ", PreviousBrandSponsorsState);
    console.log("CommentRateState:", CommentRateState);
    console.log("MostRecentPostState:", MostRecentPostState);
    console.log("AveragePostedContentState:", AveragePostedContentState);
    console.log("AvgContentLengthSize:", AvgContentLengthSize);
    console.log("ImpressionOnReelsState:", ImpressionOnReelsState);
    console.log("ImpressionOnPostsState:", ImpressionOnPostsState);
    console.log("Mentions State: ", MentionsState);
    console.log("Hashtags State: ", HashtagsState);

    console.log("Audience location: ", AudienceLocationState);

    console.log("Audience interest: ", AudienceInterestState);
    console.log("Audience language: ", AudienceLanguageState);

    console.log("Audience lookalike: ", AudienceLookalikeState);
    console.log("Radio button filter: ", RadioButtonFilterState);
    console.log("Checkbox button filter: ", CheckboxButtonFilterState);
    console.log("Search filter: ", PerformanceFilterState);
    console.log("Dropdown 2 filter : ", DropdownFilter2State);
    console.log("Search and tag : ", SearchNTagFilterState);
    console.log("Keywords : ", KeywordFilterState);
    console.log("Audience search drop down filter : ", AudienceFilterState);
    console.log("Audience age filter : ", AudienceAgeFilterState);
    console.log("Audience gender filter : ", AudienceGenderFilterState);
    console.log("Performance slider filter : ", PerformanceSliderFilterState);
    console.log("Tabs group filter : ", TabsGroupFilterState);
    console.log("ER%", ERState);
    console.log("AvgLikesState", AvgLikesState);
    console.log(
      "SponsoredVideoPerformanceState",
      SponsoredVideoPerformanceState
    );
    console.log("Followers Growth: ", FollowersGrowthState);
    console.log("Avg Reels Views: ", AvgReelsViewsState);
    console.log("Views Timeframe: ", ViewsTimeframeState);
    console.log("Contact information: ", ContactInfoState);
    console.log("Verified Contact information: ", VerifiedContactInfoState);
    console.log("Social Links: ", SocialLinksState);
    console.log("Has previous brand sponsors: ", PrevBrandSponsorsState);
    console.log("Has sponsored posts: ", HasSponsoredPostsState);
    console.log("ImpressionOnVideosState: ", ImpressionOnVideosState);
    console.log("AvgCommentsPerVideoState", AvgCommentsPerVideoState);
    console.log("AvgViewsPerVideoState", AvgViewsPerVideoState);
    console.log("Avg Content Length State", AvgContentLenState);
  };

  const matches = useMediaQuery("(max-width:600px)");

  const tabs = [
    "select..",
    "15 Days",
    "30 Days",
    "2 Months",
    "4 Months",
    "6 Months",
    "1 Year",
  ];

  const Filters = {
    "Influencer": [
      {
        "title": "In Channel Name",
        "Tooltip": "Set the Channel Names",
        "component": <LikesViewsComp
          clear={clear}
          setMentionsState={setChannelNameState}
          text="Channel Name"
          setSearchNTagFilterState={setChannelFilterState}
          tooltipInfo={"set the Sub Size"} />
      }
    ],
    "Audience": [
    ],
    "Performance": [
    ],
  }

  return (
    <>
      <Drawer
        PaperProps={{ sx: { width: "50%", maxHeight: "100vh" } }}
        anchor="right"
        open={open}
        onClose={handleClose}
      >
        <Box display={'flex'} height={"100%"} flexDirection={'column'}>

          <Box position={'relative'} display={'flex'} flexDirection={'row'} width={"100%"} height={'auto'} paddingY={2} marginBottom={2} justifyContent={'center'} alignItems={'center'}>

            <Box position={'absolute'} right={0} padding={3} size={'auto'}>
              <Tooltip title="close" placement="right" arrow>
                <IconButton onClick={handleClose}>
                  <CloseRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Box
              display={"flex"}
              flexGrow={1}
              justifyContent={'center'}
            >
              <img
                src={AdvIcon}
                alt=""
                width={40}
                style={{ display: matches ? "none" : "block" }}
              />
              <Typography
                fontWeight={"bold"}
                fontSize={matches ? "1.2rem" : "1.7rem"}
              >
                Advance Filters{" "}
                {selectedSocial &&
                  selectedSocial.charAt(0).toUpperCase() +
                  selectedSocial.slice(1)}
              </Typography>
            </Box>
          </Box>

          <Box display={'flex'} overflow={'scroll'} flexDirection={'column'} width={'100%'} flexGrow={1}>
            <Grid container columns={3}>
              <Grid item size={1} align="center">
                <Grid
                  item md={12}
                  sx={{
                    maxWidth: "fit-content",
                    width: { md: "70%", xs: "80%" },
                    marginTop: { xs: 4, md: 0 },
                  }}
                >
                  <ColumnHead>Influencer</ColumnHead>
                </Grid>
                <Grid item md={12} style={{ padding: "10px 0px" }}>
                  <Divider />
                </Grid>
                <Grid item md={12}>
                  <Grid item md={12}>
                    <SelectFilterComp2
                      tooltipInfo={"Set the Subscribers size"}
                      clear={clear}
                      setFollowersSize={setFollowersSize}
                      text="Subscribers Size"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Influencers location"}
                      clear={clear}
                      setDropdownFilterState={setDropdownFilterState}
                      setInfluencersLocationState={setInfluencersLocationState}
                      options={locations}
                      text="Influencers Location"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <RadioButtonFilter
                      tooltipInfo={"Set the Influencers gender"}
                      clear={clear}
                      setInfluencersGender={setInfluencersGender}
                      setRadioButtonFilterState={setRadioButtonFilterState}
                      buttons={["Male", "Female", "Other", "Prefer not to say"]}
                      text="Influencers Gender"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Influencers language"}
                      clear={clear}
                      setDropdownFilterState={setDropdownFilterState}
                      setInfluencersLanguageState={setInfluencersLanguageState}
                      options={languages}
                      text="Influencers Language"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Influencers category"}
                      clear={clear}
                      setDropdownFilterState={setDropdownFilterState}
                      options={influencersCategories}
                      setInfluencersCategoryState={setInfluencersCategoryState}
                      text="Influencers Category"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Instagram category"}
                      clear={clear}
                      setDropdownFilterState={setDropdownFilterState}
                      setInstagramCategoryState={setInstagramCategoryState}
                      options={influencersCategories}
                      text="Instagram Category"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <RadioButtonFilter
                      tooltipInfo={"Set the verified status"}
                      clear={clear}
                      setVerifiedState={setVerifiedState}
                      setRadioButtonFilterState={setRadioButtonFilterState}
                      buttons={["All", "Verified", "Not Verified", "CH Verified"]}
                      text="Verified"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the has contact information status"}
                      clear={clear}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      setContactInfoState={setContactInfoState}
                      buttons={[
                        "Has Personal Email",
                        "Has Business Email",
                        "Has Contact",
                        "Has WhatsApp",
                      ]}
                      text="Contact Info"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the Verified contact information status"}
                      clear={clear}
                      setVerifiedContactInfoState={setVerifiedContactInfoState}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      buttons={[
                        "Has Verified Email",
                        "Has Verified Contact",
                        "Has Verified WhatsApp",
                      ]}
                      text="Verified Contact Info"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the has Social links status"}
                      clear={clear}
                      setSocialLinksState={setSocialLinksState}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      buttons={[
                        "Has Facebook",
                        "Has YouTube",
                        "Has Twitter",
                        "Has LinkedIn",
                        "Has Twitch",
                        "Has Linktree",
                        "Has TikTok",
                      ]}
                      text="Social Links"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Influencers interest"}
                      clear={clear}
                      setInfluencersInterestState={setInfluencersInterestState}
                      setDropdownFilterState={setDropdownFilterState}
                      options={influencersInterests}
                      text="Influencers Interest"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter
                      tooltipInfo={"Set the Previous brand sponsors status"}
                      clear={clear}
                      setPrevBrandSponsorsState={setPrevBrandSponsorsState}
                      setDropdownFilterState={setDropdownFilterState}
                      options={PrevBrandSponsors}
                      text="Previous Brand Sponsors"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchNTag
                      tooltipInfo={"Set the Mentions tag"}
                      clear={clear}
                      setMentionsState={setMentionsState}
                      setSearchNTagFilterState={setSearchNTagFilterState}
                      text="Mentions"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchNTag
                      tooltipInfo={"Set the Hashtags tag"}
                      clear={clear}
                      setHashtagsState={setHashtagsState}
                      setSearchNTagFilterState={setSearchNTagFilterState}
                      text="Hashtags"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SearchDropdownFilter2
                      tooltipInfo={"Set the Influencers lookalike"}
                      clear={clear}
                      setInfluencersLookalikeState={setInfluencersLookalikeState}
                      setDropdownFilter2State={setDropdownFilter2State}
                      options={usernames}
                      text="Influencer Lookalike"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch
                      tooltipInfo={"Set the keywords"}
                      clear={clear}
                      setKeywordFilterState={setKeywordFilterState}
                      options={keywords}
                      text="Keywords"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the Made for Kids"}
                      clear={clear}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      setContactInfoState={setmadeforInfoState}
                      buttons={[
                        "Made for Kids",
                      ]}
                      text="Made For kids"
                    />
                  </Grid>
                  {Filters.Influencer.map((fil) => {
                    return (<Grid item md={12}>
                      <FilterAccordian component={fil.component} tooltipInfo={fil.Tooltip} text={fil.title} />
                    </Grid>)
                  })}
                </Grid>
              </Grid>

              <Grid item size={1} align="center">
                <Grid container spacing={4} columns={1}>
                  <Grid item size={1}>
                    <Grid
                      item
                      md={12}
                      sx={{
                        width: { md: "70%", xs: "80%" },
                        marginTop: { xs: 4, md: 0 },
                      }}
                    >
                      <ColumnHead>Audience</ColumnHead>
                    </Grid>
                    <Grid item md={12} style={{ padding: "10px 0px" }}>
                      <Divider />
                    </Grid>
                    <Grid item style={{
                      maxHeight: "72vh",
                      overflowY: "auto",
                    }} md={12}>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience location"}
                          clear={clear}
                          setAudienceFilterState={setAudienceFilterState}
                          setAudienceLocationState={setAudienceLocationState}
                          options={locations}
                          text="Audience Location"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceAgeFilter
                          tooltipInfo={"Set the Audience age"}
                          clear={clear}
                          text="Audience Age"
                          setAudienceAgeFilterState={setAudienceAgeFilterState}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceRadioButtonFilter
                          tooltipInfo={"Set the Audience gender"}
                          clear={clear}
                          setAudienceGenderFilterState={setAudienceGenderFilterState}
                          buttons={["Male", "Female", "Other", "Prefer not to say"]}
                          text="Audience Gender"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience interests"}
                          clear={clear}
                          setAudienceInterestState={setAudienceInterestState}
                          setAudienceFilterState={setAudienceFilterState}
                          options={audienceInterests}
                          text="Audience Interests"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience languages"}
                          clear={clear}
                          setAudienceLanguageState={setAudienceLanguageState}
                          setAudienceFilterState={setAudienceFilterState}
                          options={languages}
                          text="Audience Languages"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <SearchDropdownFilter2
                          tooltipInfo={"Set the Audience lookalike"}
                          clear={clear}
                          setAudienceLookalikeState={setAudienceLookalikeState}
                          setDropdownFilter2State={setDropdownFilter2State}
                          options={usernames}
                          text="Audience Lookalike"
                        />
                      </Grid>
                      {Filters.Audience.map((fil) => {
                        return (<Grid item md={12}>
                          <FilterAccordian tooltipInfo={fil.Tooltip} text={fil.title} />
                        </Grid>)
                      })}
                    </Grid>
                  </Grid>
                  <Grid item size={1}>
                    <Grid
                      item
                      md={12}
                      sx={{
                        width: { md: "70%", xs: "80%" },
                        marginTop: { xs: 4, md: 0 },
                      }}
                    >
                      <ColumnHead>Growth</ColumnHead>
                    </Grid>
                    <Grid item md={12} style={{ padding: "10px 0px" }}>
                      <Divider />
                    </Grid>
                    <Grid item style={{
                      maxHeight: "72vh",
                      overflowY: "auto",
                    }} md={12}>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience location"}
                          clear={clear}
                          setAudienceFilterState={setAudienceFilterState}
                          setAudienceLocationState={setAudienceLocationState}
                          options={locations}
                          text="Audience Location"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceAgeFilter
                          tooltipInfo={"Set the Audience age"}
                          clear={clear}
                          text="Audience Age"
                          setAudienceAgeFilterState={setAudienceAgeFilterState}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceRadioButtonFilter
                          tooltipInfo={"Set the Audience gender"}
                          clear={clear}
                          setAudienceGenderFilterState={setAudienceGenderFilterState}
                          buttons={["Male", "Female", "Other", "Prefer not to say"]}
                          text="Audience Gender"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience interests"}
                          clear={clear}
                          setAudienceInterestState={setAudienceInterestState}
                          setAudienceFilterState={setAudienceFilterState}
                          options={audienceInterests}
                          text="Audience Interests"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <AudienceSearchDropdownFilter
                          tooltipInfo={"Set the Audience languages"}
                          clear={clear}
                          setAudienceLanguageState={setAudienceLanguageState}
                          setAudienceFilterState={setAudienceFilterState}
                          options={languages}
                          text="Audience Languages"
                        />
                      </Grid>
                      <Grid item md={12}>
                        <SearchDropdownFilter2
                          tooltipInfo={"Set the Audience lookalike"}
                          clear={clear}
                          setAudienceLookalikeState={setAudienceLookalikeState}
                          setDropdownFilter2State={setDropdownFilter2State}
                          options={usernames}
                          text="Audience Lookalike"
                        />
                      </Grid>
                      {Filters.Audience.map((fil) => {
                        return (<Grid item md={12}>
                          <FilterAccordian tooltipInfo={fil.Tooltip} text={fil.title} />
                        </Grid>)
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item size={1} align="center">
                <Grid
                  item
                  md={12}
                  sx={{
                    width: { md: "70%", xs: "80%" },
                    marginTop: { xs: 4, md: 0 },
                  }}
                >
                  <ColumnHead>Performance</ColumnHead>
                </Grid>
                <Grid item md={12} style={{ padding: "10px 0px" }}>
                  <Divider />
                </Grid>
                <Grid item style={{
                  // maxHeight: "72vh",
                  overflowY: "auto",
                }} md={12}>

                  {/* <Grid item md={12}>
              <SelectFilterComponent text="Brand Safety" />
            </Grid> */}
                  <Grid item md={12}>
                    <PerformanceSlider
                      tooltipInfo={"Set the Engagement Rate percentage"}
                      clear={clear}
                      setERState={setERState}
                      setPerformanceSliderFilterState={
                        setPerformanceSliderFilterState
                      }
                      text="ER%"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TabsGroup
                      tooltipInfo={
                        "Set the subscribers growth status within given time frame"
                      }
                      clear={clear}
                      setFollowersGrowthState={setFollowersGrowthState}
                      setTabsGroupFilterState={setTabsGroupFilterState}
                      tabs={tabs}
                      text="Subscribers Growth"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch1
                      tooltipInfo={"Set the Average likes status"}
                      clear={clear}
                      setAvgLikesState={setAvgLikesState}
                      setKeywordFilterState={setKeywordFilterState}
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                      text="Average Likes"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch1
                      setAvgCommentsPerVideoState={setAvgCommentsPerVideoState}
                      setKeywordFilterState={setKeywordFilterState}
                      tooltipInfo={"Set the Average comments per video"}
                      clear={clear}
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                      text="Average comments per video"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Comment rate percentage"}
                      clear={clear}
                      setCommentRateState={setCommentRateState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Comment Rate %"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch1
                      setAvgViewsPerVideoState={setAvgViewsPerVideoState}
                      tooltipInfo={"Set the Average views per video"}
                      clear={clear}
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                      setKeywordFilterState={setKeywordFilterState}
                      text="Average views per video"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Most recent video status"}
                      clear={clear}
                      setMostRecentPostState={setMostRecentPostState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Most Recent video"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <SelectFilterComp2
                      tooltipInfo={
                        "Set the total number of video within given time frame"
                      }
                      clear={clear}
                      setTotalPostsState={setTotalPostsState}
                      text="Total number of video"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch1
                      tooltipInfo={
                        "Set the Average shorts views within given time frame"
                      }
                      clear={clear}
                      setAvgReelsViewsState={setAvgReelsViewsState}
                      setKeywordFilterState={setKeywordFilterState}
                      text="Average Shorts Views"
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the has sponsored videos status"}
                      clear={clear}
                      setHasSponsoredPostsState={setHasSponsoredPostsState}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      buttons={["Has Sponsored videos", "Has videos"]}
                      text="Has Sponsored videos"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSlider
                      tooltipInfo={"Set the sponsored videos performance status"}
                      clear={clear}
                      setPerformanceSliderFilterState={
                        setPerformanceSliderFilterState
                      }
                      setSponsoredVideoPerformanceState={
                        setSponsoredVideoPerformanceState
                      }
                      text="Sponsored videos Performance"
                    />
                  </Grid>
                  {/* <Grid item md={12}>
              <CheckboxButtonFilter
                tooltipInfo={"Set the Previous brand sponsors status"}
                clear={clear}
                setHasPrevBrandSponsorsState={setHasPrevBrandSponsorsState}
                setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                buttons={[
                  "Has Previous Brand Sponsors",
                  "Has Previous Sponsors",
                ]}
                text="Previous Brand Sponsors"
              />
            </Grid> */}
                  <Grid item md={12}>
                    <TabsGroup1
                      tooltipInfo={"Set the Average posted content status"}
                      clear={clear}
                      text="Average Posted Content"
                      setAveragePostedContentState={setAveragePostedContentState}
                      setTabsGroupFilterState={setTabsGroupFilterState}
                      tabs={tabs}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch1
                      tooltipInfo={
                        "Set the minimum and maximum average content length"
                      }
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                      clear={clear}
                      setKeywordFilterState={setKeywordFilterState}
                      setAvgContentLenState={setAvgContentLenState}
                      text="Average content length"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TabsGroup1
                      tooltipInfo={"Set the Views in the given time frame"}
                      clear={clear}
                      setViewsTimeframeState={setViewsTimeframeState}
                      setTabsGroupFilterState={setTabsGroupFilterState}
                      text="Views in a selected timeframe"
                      tabs={tabs}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Impressions on videos percentage"}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Impressions on videos %"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Views/Subs in Percentage "}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Video/Subs (%)"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Cmt. / Views in Percentage "}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Cmt. / Views (%)"
                    />
                  </Grid>

                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Total Views "}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Total Views "
                    />
                  </Grid>

                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Channel Creation Date "}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Channel Creation Date "
                    />
                  </Grid>
                  <Grid item md={12}>
                    <KeywordSearch4
                      tooltipInfo={
                        "Has Shorts"
                      }
                      timeframes={timeframes}
                      options={dropdownOptions}
                      postsTimeframes={postsTimeframes}
                      clear={clear}
                      setKeywordFilterState={setKeywordFilterState}
                      setAvgContentLenState={setAvgContentLenState}
                      text="Has Shorts"
                    />
                  </Grid>

                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the minimum number of total views"}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Minimum number of total views"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <AudienceSearchDropdownFilter
                      tooltipInfo={"Set the Mostly posted content type"}
                      clear={clear}
                      setAudienceMostPostedState={setAudienceMostPostedState}
                      setAudienceFilterState={setAudienceFilterState}
                      options={contentType}
                      text="Mostly posted content type"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <VideoGapUploadFilter
                      tooltipInfo={"Set the Mostly posted content type"}
                      clear={clear}
                      setAudienceAgeFilterState={setAudienceFilterState}
                      options={contentType}
                      text="Video Gap Upload"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <CheckboxButtonFilter
                      tooltipInfo={"Set the has contact information status"}
                      clear={clear}
                      setCheckboxButtonFilterState={setCheckboxButtonFilterState}
                      setContactInfoState={setContactInfoState}
                      buttons={[
                        "Long Videos",
                        "Short Videos",
                      ]}
                      text="Only Shorts/Long Videos"
                    />
                  </Grid>
                  <Grid item md={12}>
                    <PerformanceSearch
                      tooltipInfo={"Set the Cmt. / Views in Percentage "}
                      clear={clear}
                      setImpressionOnVideosState={setImpressionOnVideosState}
                      setPerformanceFilterState={setPerformanceFilterState}
                      text="Video/Shorts Ratio"
                    />
                  </Grid>
                  {Filters.Performance.map((fil) => {
                    return (<Grid item md={12}>
                      <FilterAccordian component={fil.component} tooltipInfo={fil.Tooltip} text={fil.title} />
                    </Grid>)
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Footer */}
          <Box paddingY={2}>
            <Divider />
            <Box paddingX={3} paddingY={1} display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>

              <Box>
                <Button
                  startIcon={<DeleteOutlineOutlinedIcon />}
                  style={{ color: "black", textTransform: "none" }}
                  onClick={clearAllFilters}
                >
                  Clear All Filters
                </Button>
              </Box>

              <Box>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    borderRadius: 20,
                    background: "#3DBE72",
                    "&:hover": { background: "#3DBE72" },
                    textTransform: "none",
                  }}
                  onClick={showResult}
                >
                  Show Results
                </Button>
              </Box>

            </Box>
            <Divider />
          </Box>
        </Box>
      </Drawer >
    </>
  );
};

export default AdvFilterDrawerY;
