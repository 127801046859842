import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FavoriteBorderOutlined, ChatBubbleOutline } from "@mui/icons-material";
import Card3 from "./Card3";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    width: "80%",
    maxWidth: 800,
    maxHeight: 400,
    margin: "40px auto",
  },
  carouselItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    height: 400,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
  },
  iconGroup: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  iconText: {
    marginLeft: theme.spacing(1),
  },
  bottomText: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

export default function CustomCarousel() {
  const classes = useStyles();
  return (
    <div className={classes.carouselContainer}>
      <Carousel animation="fade" indicators={false} navButtonsAlwaysVisible>
        <Card3 likes="2" comments="10.2" date="19 Jun 20" />
        <Card3 likes="4" comments="3.2" date="28 Feb 24" />
        <Card3 likes="5" comments="12" date="13 Sept 24" />
        <Card3 likes="1" comments="9" date="1 Nov 24" />
        <Card3 likes="0.5" comments="7.3" date="1 Jan 24" />
      </Carousel>
    </div>
  );
}
