import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Icon,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Stack,
  styled,
  Tabs,
  Tab,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import AccordionActions from "@mui/material/AccordionActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Instagram, YouTube } from "@mui/icons-material";
import makeStyles from '@mui/styles/makeStyles';
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { useSelector } from "react-redux";
import AccountAnalytics from "./AccountAnalytics";
import OverViewChart from "./OverViewChart";
import ContentChart from "./ContentChart";
import AudienceChart from "./AudienceChart";
import LookalikeChart from "./LookalikeChart";
import CustomCarousel1 from "./CustomCarousel1";
import InfluencersDataTable from "./InfluencersDataTable";
import InfluencersDataTable1 from "./InfluencersDataTable1";
import BrandMentions from "./BrandMentions";
import { getAllInfluencers } from "../../../../service/getAllApi";

const useStyles = makeStyles({
  accordionContainer: {
    margin: "10px 0",
    padding: 0,
    "&::before": {
      content: "none",
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      {...other}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box paddingTop={4}>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const AccordionOptionsInsta = [
  {
    name: "Overview",
    titles: [
      {
        name: "Engagement",
        options: [
          { name: "Engagement Rate", elementType: "text" },
          { name: "Average Number of Likes on Posts", elementType: "text" },
          { name: "Average Number of Views on Posts", elementType: "text" },
          { name: "Average Number of Comments", elementType: "text" },
          { name: "Average Posts Per Week", elementType: "text" },
          { name: "Average Posts Per Month", elementType: "text" },
        ],
      },
      {
        name: "Credibility",
        options: [
          {
            name: "Percentage of Channel Subscribers Credibility",
            elementType: "text",
          },
          { name: "Age Group of the Channel", elementType: "text" },
          { name: "Language of the Channel", elementType: "text" },
          { name: "Credibility of Channel Viewers", elementType: "text" },
          { name: "Channel Content Count", elementType: "text" },
          {
            name: "Performance of Sponsored Videos vs Organic Videos",
            elementType: "text",
          },
        ],
      },
      {
        name: "Location",
        options: [
          { name: "Profile Location Country", elementType: "text" },
          { name: "Profile Location State", elementType: "text" },
          { name: "Profile Location City", elementType: "text" },
        ],
      },
      {
        name: "Growth",
        options: [
          { name: "Followers Growth Rate", elementType: "fullgraph" },
          {
            name: "Brand Mentions (In the past 3 months)",
            elementType: "mention",
          },
        ],
      },
    ],
  },
  {
    name: "Content",
    titles: [
      {
        name: "Top",
        options: [
          {
            name: "List of top hashtags used by the creator",
            elementType: "text",
          },
          {
            name: "List of top mentions used by the creator",
            elementType: "text",
          },
          { name: "List of top interests of the creator", elementType: "text" },
          { name: "Name of the creator brand affinity", elementType: "text" },
          { name: "", elementType: "carousel" },
        ],
      },
      {
        name: "Recent",
        options: [{ name: "", elementType: "carousel" }],
      },
      {
        name: "Sponsored",
        options: [{ name: "", elementType: "carousel" }],
      },
    ],
  },
  {
    name: "Audience",
    titles: [
      {
        name: "Followers",
        options: [
          { name: "Profile Audience Languages", elementType: "graph" },
          {
            name: "Profile Audience Languages Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Interests Name", elementType: "graph" },
          {
            name: "Profile Audience Interests Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Follower Types Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Follower Types Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Countries",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Countries Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Cities Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Profile Audience Followers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Lookalikes", elementType: "table" },
          { name: "Profile Significant Followers", elementType: "table" },
          { name: "Profile Audience Credibility Score", elementType: "graph" },
          {
            name: "Profile Significant Followers Percentage",
            elementType: "graph",
          },
        ],
      },
      {
        name: "Likers",
        options: [
          { name: "Profile Audience Likers", elementType: "graph" },
          { name: "Profile Audience Likers Countries", elementType: "graph" },
          {
            name: "Profile Audience Likers Countries Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Cities Name", elementType: "graph" },
          {
            name: "Profile Audience Likers Cities Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution - Gender",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution - Age Range",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Gender Age Distribution Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Languages", elementType: "graph" },
          {
            name: "Profile Audience Likers Languages Percentage",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Interests Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Interests Value",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Followers Types Name",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Followers Types Percentage",
            elementType: "graph",
          },
          { name: "Profile Audience Likers Lookalikes", elementType: "table" },
          {
            name: "Profile Audience Likers Significant Followers",
            elementType: "table",
          },
          {
            name: "Profile Audience Likers Credibility Score",
            elementType: "graph",
          },
          {
            name: "Profile Audience Likers Significant Followers Percentage",
            elementType: "graph",
          },
        ],
      },
    ],
  },
  {
    name: "Lookalike",
    titles: [
      {
        name: "Profile Lookalikes",
        options: [{ name: "", elementType: "table" }],
      },
    ],
  },
];


function convertDurationToSeconds(duration) {
  const parts = duration.split(':');
  const hours = parseInt(parts[0], 10) || 0;
  const minutes = parseInt(parts[1], 10) || 0;
  const seconds = parseInt(parts[2], 10) || 0;

  // Convert duration to total seconds
  return hours * 3600 + minutes * 60 + seconds;
}


function calculateVideoEr(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media']

  let totalLikes = 0;
  let totalComments = 0;
  let totalViews = 0;

  mediaArray.forEach(media => {
    const durationInSeconds = convertDurationToSeconds(media.duration);

    // Only add if media duration is greater than 60 seconds (1 minute)
    if (durationInSeconds > 60) {
      totalLikes += parseInt(media.likecount) || 0;
      totalComments += parseInt(media.commentcount) || 0;
      totalViews += parseInt(media.viewcount) || 0;
    }
  });

  totalLikes = totalLikes / (mediaArray.length);
  totalComments = totalComments / (mediaArray.length);
  totalViews = totalViews / (mediaArray.length);

  if (totalViews > 0) {
    const engagementRate = ((totalLikes + totalComments) / totalViews) * 100;
    return engagementRate.toFixed(2) + "%"// Return the final engagement rate rounded to 2 decimal places
  } else {
    return 0; // Return 0 if there are no views
  }

}

function calculateShortsEr(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media']

  let totalLikes = 0;
  let totalComments = 0;
  let totalViews = 0;

  mediaArray.forEach(media => {
    const durationInSeconds = convertDurationToSeconds(media.duration);

    console.log(durationInSeconds);

    // Only add if media duration is greater than 60 seconds (1 minute)
    if (durationInSeconds < 60) {
      totalLikes += parseInt(media.likecount) || 0;
      totalComments += parseInt(media.commentcount) || 0;
      totalViews += parseInt(media.viewcount) || 0;
    }
  });

  totalLikes = totalLikes / (mediaArray.length);
  totalComments = totalComments / (mediaArray.length);
  totalViews = totalViews / (mediaArray.length);

  if (totalViews > 0) {
    const engagementRate = ((totalLikes + totalComments) / totalViews) * 100;
    return engagementRate.toFixed(2) + "%"// Return the final engagement rate rounded to 2 decimal places
  } else {
    return 0; // Return 0 if there are no views
  }

}

function calculateAvgViewsVideo(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media']

  let totalViews = 0;

  mediaArray.forEach(media => {
    const durationInSeconds = convertDurationToSeconds(media.duration);

    // Only add if media duration is greater than 60 seconds (1 minute)
    if (durationInSeconds > 60) {
      totalViews += parseInt(media.viewcount) || 0;
    }
  });

  totalViews = totalViews / (mediaArray.length);

  return parseInt(totalViews);
}

function calculateAvgViewsShorts(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media']

  let totalViews = 0;

  mediaArray.forEach(media => {
    const durationInSeconds = convertDurationToSeconds(media.duration);

    // Only add if media duration is greater than 60 seconds (1 minute)
    if (durationInSeconds < 60) {
      totalViews += parseInt(media.viewcount) || 0;
    }
  });

  totalViews = totalViews / (mediaArray.length);

  return parseInt(totalViews);

}

function calculateAvgPostperWeek(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";

  }

  let mediaArray = userdata['media']

  // Parse the published_at dates and sort the media by published_at
  const dates = mediaArray.map(media => new Date(media.published_at)).sort((a, b) => a - b);

  // Find the earliest and latest post dates
  const earliestDate = dates[0];
  const latestDate = dates[dates.length - 1];

  // Calculate the total number of weeks between the earliest and latest post dates
  const msInOneWeek = 1000 * 60 * 60 * 24 * 7;
  const totalWeeks = (latestDate - earliestDate) / msInOneWeek;

  // Calculate the average posts per week
  const avgPostsPerWeek = mediaArray.length / totalWeeks;

  return parseInt(avgPostsPerWeek); // Return average posts per week rounded to 2 decimal places

}

function calculateAvgPostperMonth(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";

  }

  let mediaArray = userdata['media']

  // Parse the published_at dates and sort the media by published_at
  const dates = mediaArray.map(media => new Date(media.published_at)).sort((a, b) => a - b);

  // Find the earliest and latest post dates
  const earliestDate = dates[0];
  const latestDate = dates[dates.length - 1];

  // Calculate total months between the earliest and latest post dates
  const totalMonths =
    (latestDate.getFullYear() - earliestDate.getFullYear()) * 12 +
    (latestDate.getMonth() - earliestDate.getMonth());

  // If the difference in months is 0, avoid division by 0 and return total posts
  const avgPostsPerMonth = totalMonths > 0 ? mediaArray.length / totalMonths : mediaArray.length;

  return parseInt(avgPostsPerMonth);

}
function calculateAvgComments(userdata) {

  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media']

  let totalcomment = 0;

  mediaArray.forEach(media => {
    totalcomment += parseInt(media.commentcount) || 0;
  });

  totalcomment = totalcomment / (mediaArray.length);

  return parseInt(totalcomment);
}

function calculateLanguage(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];

  let lang = [];

  mediaArray.forEach(media => {
    // Only add the language if it's not already in the lang array
    if (!lang.includes(media.audio_language)) {
      lang.push(media.audio_language);
    }
  });

  return lang;
}

function calculatetophasttags(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];

  // Use a Set to collect unique hashtags
  let hashtagsSet = new Set();

  mediaArray.forEach(media => {
    // Check if media has keywords
    if (media.hashtags) {
      // Split the keywords string by comma and trim whitespace
      let keywordsArray = media.hashtags.split(',').map(keyword => keyword.trim());

      keywordsArray.forEach(keyword => {
        hashtagsSet.add(keyword); // Add each keyword to the Set
      });
    }
  });
  // Convert the Set to an array and limit to 5
  return Array.from(hashtagsSet).slice(0, 5).join(", ");
}

function calculatementions(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];

  // Use a Set to collect unique hashtags
  let hashtagsSet = new Set();

  mediaArray.forEach(media => {
    // Check if media has keywords
    if (media.tags) {
      // Split the keywords string by comma and trim whitespace
      let keywordsArray = media.tags?.split(',').map(keyword => keyword.trim());

      keywordsArray.forEach(keyword => {
        hashtagsSet.add(keyword); // Add each keyword to the Set
      });
    }
  });
  // Convert the Set to an array and limit to 5
  return Array.from(hashtagsSet).slice(0, 5);
}

function calculateinterests(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];

  // Use a Set to collect unique hashtags
  let hashtagsSet = new Set();

  mediaArray.forEach(media => {
    // Check if media has keywords
    if (media.category) {
      hashtagsSet.add(media.category);
    }
  });
  // Convert the Set to an array and limit to 5
  return Array.from(hashtagsSet).slice(0, 5);
}

function calculateTopContentType(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];
  let reelsCount = 0;
  let videosCount = 0;

  mediaArray.forEach(media => {
    if (media.duration) {
      let durationInSeconds = convertDurationToSeconds(media?.duration);
      // Count as reel if under 60 seconds, otherwise count as video
      if (durationInSeconds < 60) {
        reelsCount++;
      } else {
        videosCount++;
      }
    }
  });

  // Determine content type based on which has a higher count
  if (reelsCount > videosCount) {
    return "Reels";
  } else if (videosCount > reelsCount) {
    return "Long Videos";
  } else {
    return "Equal Reels and Videos";
  }
}


function calculateTopMedia(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata['media'] || userdata['media'].length <= 0) {
    return "No Media History";
  }

  let mediaArray = userdata['media'];
  let topMedia = null;

  mediaArray.forEach(media => {
    // Initialize or update topMedia based on view count
    if (!topMedia || (media.viewcount && media.viewcount > topMedia.viewcount)) {
      topMedia = media;
    }
  });


  // Return URL of the media with the highest views, or a message if none found
  return topMedia;
}

function calculateSponsoredPost(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata.media || userdata.media.length === 0) {
    return "No Media History";
  }

  // Define the keywords to search for
  const keywords = ["ad", "sponsored", "paidpartnership", "collab", "paid_collab"];

  // Filter and map media items to return only sponsored posts with specific fields
  const sponsoredMedia = userdata.media
    .filter(media =>
      // Check if description contains any of the keywords (case insensitive)
      keywords.some(keyword =>
        media.description && media.description.toLowerCase().includes(keyword.toLowerCase())
      )
    )
    .map(media => ({
      likes: media.likecount,
      comments: media.commentcount,
      date: new Date(media.published_at).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }),
      link: media.default_thumnail_link
    }));

  return sponsoredMedia.length > 0 ? sponsoredMedia : [];
}

function calculateRecentPost(userdata) {
  // Check if the user has media and if the media array is not empty
  if (!userdata.media || userdata.media.length === 0) {
    return "No Media History";
  }

  const recentPosts = userdata.media
    .sort((a, b) => new Date(b.published_at) - new Date(a.published_at)) // Sort by published_at, most recent first
    .map(media => ({
      likes: media.likecount,
      comments: media.commentcount,
      link: media.default_thumnail_link,
      date: new Date(media.published_at).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
    }));

  return recentPosts;
}

const BasicTabs = ({ userdata }) => {
  const [value, setValue] = React.useState(0);
  const [social, setSocial] = React.useState("Youtube");
  const [currentTab, setCurrentTab] = useState(0);
  const [influencers, setInfluencers] = useState([]);
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);

  const classes = useStyles();

  const AccordionOptionsYoutube = [
    {
      name: "Overview",
      titles: [
        {
          name: "Engagement",
          options: [
            { name: "Videos Engagement Rate", elementType: "text", key: "Engagement Rate", value: calculateVideoEr(userdata) },
            { name: "Shorts Engagement Rate", elementType: "text", key: "Shorts Engagement Rate", value: calculateShortsEr(userdata) },
            { name: "Average Number of Views on Videos", elementType: "text", key: "Average Number of Views on Videos", value: calculateAvgViewsVideo(userdata) },
            { name: "Average Number of Views on Shorts", elementType: "text", key: "Average Number of Views on Shorts", value: calculateAvgViewsShorts(userdata) },
            { name: "Average Number of Comments", elementType: "text", key: "Average Number of Comments", value: calculateAvgComments(userdata) },
            { name: "Average Posts Per Week", elementType: "text", key: "Average Posts Per Week", value: calculateAvgPostperWeek(userdata) },
            { name: "Average Posts Per Month", elementType: "text", key: "Average Posts Per Month", value: calculateAvgPostperMonth(userdata) },
          ],
        },
        {
          name: "Credibility",
          options: [
            // {
            //   name: "Percentage of Channel Subscribers Credibility",
            //   elementType: "text", key: "Percentage of Channel Subscribers Credibility",
            //   value: "Not found"
            // },
            { name: "Age Group of the Channel", elementType: "text", key: "Age Group of the Channel", value: "Not Found" },
            { name: "Language of the Channel", elementType: "text", key: "Language of the Channel", value: calculateLanguage(userdata) },
            // { name: "Credibility of Channel Viewers", elementType: "text", key: "Channel Audience Viewers Cedibility Score", value: "Not Found" },
            { name: "Channel Content Count", elementType: "text", key: "Channel content count", value: userdata?.video_count },
            // {
            //   name: "Performance of Sponsored Videos vs Organic Videos",
            //   elementType: "text", key: "Performance of sponsored videos vs organic videos"
            //   , value: "Not Found"
            // },
          ],
        },
        {
          name: "Location",
          options: [
            { name: "Channel Location Country", elementType: "text", key: "Channel Location Country", value: userdata?.country },
            { name: "Channel Location State", elementType: "text", key: "Channel Location State", value: userdata?.location },
            { name: "Channel Location City", elementType: "text", key: "Channel Location City", value: userdata?.city },
          ],
        },
        // {
        //   name: "Growth",
        //   options: [
        //     { name: "Subscribers Growth Rate", elementType: "fullgraph" },
        //     { name: "Channel Views Growth Rate", elementType: "fullgraph" },
        //     {
        //       name: "Brand Mentions (In the Past 3 Months)",
        //       elementType: "mention",
        //     },
        //   ],
        // },
      ],
    },
    {
      name: "Content",
      titles: [
        {
          name: "Top",
          options: [
            {
              name: "List of top hashtags used by the creator",
              elementType: "text", key: "List of top hashtags used by the creator",
              value: calculatetophasttags(userdata)
            },
            {
              name: "List of top mentions used by the creator",
              elementType: "text", key: "List of top mentions used by the creator",
              value: calculatementions(userdata)
            },
            { name: "List of top interests of the creator", elementType: "text", key: "List of top interests of the creator", value: calculateinterests(userdata) },
            { name: "Name of the creator brand affinity", elementType: "text", key: "Name of the creator brand affinity" },
            { name: "Channel Top Contents Type", elementType: "text", key: "", value: calculateTopContentType(userdata) },
            { name: "Channel Top Contents URL", elementType: "text", key: "", value: "https://youtube.com/video/" + calculateTopMedia(userdata)?.media_id },
            { name: "Channel Top Contents Description", elementType: "text", key: "", value: calculateTopMedia(userdata)?.description },
            { name: "Channel Top Contents Thumbnail URL", elementType: "text", key: "", value: calculateTopMedia(userdata)?.high_thumnail_link },
            {
              name: "Channel Top Contents Engagement Views Count",
              elementType: "text", key: "", value: calculateTopMedia(userdata)?.viewcount
            },
            {
              name: "Channel Top Contents Engagement Like Count",
              elementType: "text", key: "", value: calculateTopMedia(userdata)?.likecount
            },
            {
              name: "Channel Top Contents Engagement Comment Count",
              elementType: "text", key: "", value: calculateTopMedia(userdata)?.commentcount
            },
            // { name: "", elementType: "carousel" },
          ],
        },
        {
          name: "Recent",
          options: [{ name: "", elementType: "carousel", value: calculateRecentPost(userdata) }],
        },
        // {
        //   name: "Sponsored",
        //   options: [{ name: "", elementType: "carousel", value: calculateSponsoredPost(userdata) }],
        // },
      ],
    },
    // {
    //   name: "Audience",
    //   titles: [
    //     {
    //       name: "Followers",
    //       options: [
    //         { name: "Channel Audience Languages", elementType: "graph", key: "Channel Audience Languages" },
    //         {
    //           name: "Channel Audience Languages Percentage",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Interests Name", elementType: "graph" },
    //         {
    //           name: "Channel Audience Interests Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Types Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Types Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Countries",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Countries Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Cities Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Cities Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Gender Age Distribution - Gender",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Gender Age Distribution - Age Range",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Subscribers Gender Age Distribution Percentage",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Credibility Score", elementType: "text" },
    //         { name: "Channel Audience Lookalikes", elementType: "table" },
    //         { name: "Channel Significant Followers", elementType: "table" },
    //       ],
    //     },
    //     {
    //       name: "Likers",
    //       options: [
    //         { name: "Channel Audience Likers", elementType: "graph" },
    //         { name: "Channel Audience Likers Countries", elementType: "graph" },
    //         {
    //           name: "Channel Audience Likers Countries Percentage",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Likers Cities Name", elementType: "graph" },
    //         {
    //           name: "Channel Audience Likers Cities Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Gender Age Distribution - Gender",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Gender Age Distribution - Age Range",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Gender Age Distribution Percentage",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Likers Languages", elementType: "graph" },
    //         {
    //           name: "Channel Audience Likers Languages Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Interests Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Interests Value",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Followers Types Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Followers Types Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Likers Credibility Score",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Likers Lookalikes", elementType: "table" },
    //         {
    //           name: "Channel Audience Likers Significant Followers",
    //           elementType: "table",
    //         },
    //       ],
    //     },
    //     {
    //       name: "Viewers",
    //       options: [
    //         { name: "Channel Audience Viewers", elementType: "graph" },
    //         { name: "Channel Audience Viewers Countries", elementType: "graph" },
    //         {
    //           name: "Channel Audience Viewers Countries Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Cities Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Cities Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Gender Age Distribution - Gender",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Gender Age Distribution - Age Range",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Gender Age Distribution Percentage",
    //           elementType: "graph",
    //         },
    //         { name: "Channel Audience Viewers Languages", elementType: "graph" },
    //         {
    //           name: "Channel Audience Viewers Languages Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Interests Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Interests Value",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Followers Types Name",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Followers Types Percentage",
    //           elementType: "graph",
    //         },
    //         {
    //           name: "Channel Audience Viewers Lookalikes",
    //           elementType: "table",
    //         },
    //         {
    //           name: "Channel Audience Viewers Cedibility Score",
    //           elementType: "graph",
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Lookalike",
    //   titles: [
    //     {
    //       name: "Channel Lookalikes",
    //       options: [{ name: "", elementType: "table" }],
    //     },
    //   ],
    // },
  ];

  const handleSocialChange = (event, newValue) => {
    setSocial(newValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCurrentTab(newValue);
  };

  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      const response = await getAllInfluencers();
      console.log("influencersrr ", response.users);
      if (response.users.length > 0) {
        setInfluencers(response.users);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    fetchMoreInfluencers();
  }, []);


  const data2 = [
    { label: "Group A", value: 2400 },
    { label: "Group B", value: 4567 },
    { label: "Group C", value: 1398 },
    { label: "Group D", value: 9800 },
    { label: "Group E", value: 3908 },
    { label: "Group F", value: 4800 },
  ];

  const cardss = [
    { likes: 9, comments: 12.1, date: "19 Jun 24", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 5.6, comments: 11, date: "9 Jul 21", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 10.4, comments: 31, date: "4 Sept 22", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 7.2, comments: 21, date: "19 Feb 20", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 10.4, comments: 31, date: "4 Sept 22", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 7.2, comments: 21, date: "19 Feb 20", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 10.4, comments: 31, date: "4 Sept 22", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 5.6, comments: 11, date: "9 Jul 21", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
    { likes: 10.4, comments: 31, date: "4 Sept 22", link: "https://i.ytimg.com/vi/NGSbtV6Ug8w/default.jpg" },
  ];

  let values = []

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#e36e46" : "#308fe8",
    },
  }));

  function CustomizedProgressBars({ data }) {
    return (
      <Stack spacing={1} sx={{ flexGrow: 1, padding: "10px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{data.label}</Typography>
          <Typography>{data.value / 100} %</Typography>
        </Box>
        <BorderLinearProgress variant="determinate" value={data.value / 100} />
      </Stack>
    );
  }

  const IsOpen = useSelector((state) => state.sharedState.isOpen);

  const renderComponentByType = (
    elementType,
    option,
    data2,
    values,
    influencers,
    currentTab,
    fetchingInfluencer,
    fetchMoreInfluencers,
    fetchingInfluencers
  ) => {
    switch (elementType) {
      case "text":
        return (
          <Box
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              height: "100%",
            }}
          >
            <Typography>{option.name}</Typography>
          </Box>
        );

      case "graph":
        return (
          <Box
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {currentTab === 1 && <ContentChart options={option.name} />}
            {/* {currentTab === 2 && <AudienceChart options={option.name} />}
            {currentTab === 3 && <LookalikeChart />} */}
          </Box>
        );

      case "table":
        return (
          <Box
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                <IconButton sx={{ color: "#3DBE72" }}>
                  <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </Box>
              <InfluencersDataTable1
                influencers={influencers}
                fetching={fetchingInfluencer}
                fetchMoreInfluencers={fetchMoreInfluencers}
                onScrollFetching={fetchingInfluencers}
              />
            </Box>
          </Box>
        );

      case "fullgraph":
        return (
          <Box
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
          </Box>
        );

      case "carousel":
        return (
          <Box
            sx={{
              maxWidth: IsOpen ? "85vw" : "71vw",
            }}
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {!IsOpen ? (
              <CustomCarousel1 value={5} cards={cardss} />
            ) : (
              <CustomCarousel1 value={6} cards={cardss} />
            )}
            {!IsOpen ? (
              <CustomCarousel1 value={5} cards={cardss} />
            ) : (
              <CustomCarousel1 value={6} cards={cardss} />
            )}
          </Box>
        );

      case "mention":
        return (
          <Box
            sx={{
              maxWidth: IsOpen ? "85vw" : "71vw",
            }}
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <BrandMentions />
          </Box>
        );

      default:
        return null;
    }
  };

  const renderYoutubeComponentByType = (
    elementType,
    option,
    data2,
    values,
    influencers,
    currentTab,
    fetchingInfluencer,
    fetchMoreInfluencers,
    fetchingInfluencers
  ) => {
    const commonStyle = {
      padding: 2,
      height: "100%"
    };

    switch (elementType) {
      case "text":
        return (
          <Box sx={{ ...commonStyle }}>
            <Typography variant="body2" sx={{ color: "gray" }} >{option.name}</Typography>
            <Box display={'flex'} >
              <Typography flexWrap={'wrap'} variant="h5">{option.value}</Typography>
            </Box>
          </Box>
        );

      case "graph":
        return (
          <Box sx={commonStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {currentTab === 1 && <ContentChart options={option.name} />}
            {currentTab === 2 && <AudienceChart options={option.name} />}
            {currentTab === 3 && <LookalikeChart />}
          </Box>
        );

      case "fullgraph":
        return (
          <Box sx={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            {currentTab === 0 && <OverViewChart />}
            {/* Adjust for other fullgraph types if needed */}
          </Box>
        );
      case "mention":
        return (
          <Box sx={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <BrandMentions userdata={userdata} option={option} />
          </Box>
        );

      case "carousel":
        return (
          <Box sx={{ ...commonStyle, width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option.name}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <CustomCarousel1 value={5} cards={option.value} />
          </Box>
        );

      case "table":
        return (
          <Box
            sx={{
              background: "#FFFFFF",
              padding: 15,
              borderRadius: 10,
              marginTop: 20,
              height: "100%",
            }}
          >
            <Box sx={{ width: "100%", marginTop: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                <IconButton sx={{ color: "#3DBE72" }}>
                  <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                </IconButton>
              </Box>
            </Box>
            <InfluencersDataTable
              influencers={influencers}
              fetching={fetchingInfluencer}
              fetchMoreInfluencers={fetchMoreInfluencers}
              onScrollFetching={fetchingInfluencers}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box>
      <TabContext value={social}>
        <TabList
          onChange={handleSocialChange}
          aria-label="influencer_profile_tabs"
        >
          {/* <Tab
              icon={
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png"
                  alt="Instagram"
                  width={20}
                />
              }
              iconPosition="start"
              sx={{ color: "black" }}
              label="Instagram"
              value="Instagram"
            /> */}
          <Tab
            icon={
              <img
                src="https://cdn-icons-png.flaticon.com/512/1384/1384060.png"
                alt="Youtube"
                width={20}
              />
            }
            iconPosition="start"
            sx={{ color: "black" }}
            label="Youtube"
            value="Youtube"
          />
        </TabList>
        <TabPanel value="Instagram">
          <Box
            sx={{
              maxWidth: "100%",
              padding: 0,
            }}
          >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                padding: 0,
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                {/* <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                /> */}
              </Tabs>
            </Box>

            {AccordionOptionsInsta.map((section, sectionIndex) => (
              <CustomTabPanel
                value={value}
                index={sectionIndex}
                key={sectionIndex}
              >
                {section.titles.map((title, titleIndex) => (
                  <Box key={titleIndex} className={classes.accordionContainer}>
                    <Accordion
                      defaultExpanded
                      sx={{
                        background: "#F2F7FF",
                        boxShadow: "0",
                        borderRadius: 5,
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls={`panel-${titleIndex}-content`}
                        id={`panel-${titleIndex}-header`}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: { md: "center", xs: "flex-start" },
                            flexDirection: { xs: "column", md: "row" },
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: 15 }}
                          >
                            {title.name}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {/* Grid components */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(500px, 1fr))",
                            gap: 20,
                            marginBottom: 40,
                          }}
                        >
                          {title.options
                            .filter(
                              (option) =>
                                option.elementType === "graph" ||
                                option.elementType === "text"
                            )
                            .map((option, optionIndex) => (
                              <Box key={optionIndex}>
                                {renderComponentByType(
                                  option.elementType,
                                  option,
                                  data2,
                                  values,
                                  influencers,
                                  currentTab,
                                  fetchingInfluencer,
                                  fetchMoreInfluencers,
                                  fetchingInfluencers
                                )}
                              </Box>
                            ))}
                        </Box>

                        {/* Full-width components */}
                        {title.options
                          .filter(
                            (option) =>
                              option.elementType === "table" ||
                              option.elementType === "fullgraph" ||
                              option.elementType === "carousel" ||
                              option.elementType === "mention"
                          )
                          .map((option, optionIndex) => (
                            <Box key={optionIndex} sx={{ marginTop: 20 }}>
                              {renderComponentByType(
                                option.elementType,
                                option,
                                data2,
                                values,
                                influencers,
                                currentTab,
                                fetchingInfluencer,
                                fetchMoreInfluencers,
                                fetchingInfluencers
                              )}
                            </Box>
                          ))}

                        {title.name === "Content" && (
                          <Box sx={{ marginTop: 2, width: "100%" }}>
                            {/* <CustomCarousel1 /> */}
                          </Box>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value="Youtube">
          <Box >
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Overview"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    fontSize: "14px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Content"
                  {...a11yProps(0)}
                />
                {/* <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Audience"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{
                    fontSize: "12px",
                    color: "black",
                    fontWeight: "bold",
                  }}
                  iconPosition="start"
                  label="Lookalike"
                  {...a11yProps(0)}
                /> */}
              </Tabs>
            </Box>

            {AccordionOptionsYoutube.map((section, sectionIndex) => (
              <CustomTabPanel
                padding={0}
                value={value}
                index={sectionIndex}
                key={sectionIndex}
              >
                {section.titles.map((title, titleIndex) => (
                  <Accordion
                    defaultExpanded
                    sx={{
                      paddingX: 2,
                      paddingY: 1,
                      background: "#F2F7FF",
                      borderRadius: 8,
                      marginTop: 1,
                      boxShadow: "0",
                      border: "none",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls={`panel-${titleIndex}-content`}
                      id={`panel-${titleIndex}-header`}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: { md: "center", xs: "flex-start" },
                          flexDirection: { xs: "column", md: "row" },
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "bold", fontSize: "1.2rem", }}
                        >
                          {title.name}
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        {/* Grid layout for other components */}
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns:
                              "repeat(auto-fit, minmax(360px, 1fr))",
                            gap: 2,
                          }}
                        >
                          {title.options
                            .filter(
                              (option) =>
                                !["fullgraph", "carousel", "table"].includes(
                                  option.elementType
                                )
                            )
                            .map((option, optionIndex) => (
                              <Box
                                key={optionIndex}
                                sx={{
                                  background: "#FFFFFF",
                                  borderRadius: 5,
                                  paddingX: 2,
                                  paddingY: 1,
                                }}
                              >
                                {renderYoutubeComponentByType(
                                  option.elementType,
                                  option,
                                  values,
                                  data2,
                                  influencers,
                                  currentTab,
                                  fetchingInfluencer,
                                  fetchMoreInfluencers,
                                  fetchingInfluencers
                                )}
                              </Box>
                            ))}
                        </Box>

                        {/* Full-width components */}
                        {title.options
                          .filter((option) =>
                            ["fullgraph", "carousel", "table"].includes(
                              option.elementType
                            )
                          )
                          .map((option, optionIndex) => (
                            <Box
                              key={optionIndex}
                              sx={{ width: "100%", marginBottom: 20 }}
                            >
                              {renderYoutubeComponentByType(
                                option.elementType,
                                option,
                                data2,
                                values,
                                influencers,
                                currentTab,
                                fetchingInfluencer,
                                fetchMoreInfluencers,
                                fetchingInfluencers
                              )}
                            </Box>
                          ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </CustomTabPanel>
            ))}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default BasicTabs;
