/* eslint-disable no-undef */
// ! It consists of several not defiends

import React, {
    useState,
} from "react";
import CircularProgress from "@mui/material/CircularProgress";

import "../../../../index.css";
import {
    Box,
    Typography,
    IconButton,
    Tooltip,
    Button,
    styled,
    Dialog,
    ListItem,
    Checkbox,
    Menu,
    MenuItem,
    FormControlLabel,
    TextField,
    InputAdornment,
    List,
    ListItemButton,
    ListItemText,
    AvatarGroup,
    Avatar,

} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {
    CancelOutlined,
    Add,
    MoreHoriz as MoreHorizIcon,
    Share as ShareIcon,
    GetApp as GetAppIcon,
    PictureAsPdf as PictureAsPdfIcon,
    Campaign as CampaignIcon,
    PersonAdd as PersonAddIcon,
    Edit as EditIcon,
    Archive as ArchiveIcon,
    Search,
    Delete,
    AddTaskOutlined,
    WarningAmberOutlined,
} from "@mui/icons-material";
import ShareCollection from "../../collection/ShareCollection";
import AddNewBrand from "../../collection/AddNewBrand";
import insta from "../../../images/insta-xsm.png";
import insta_sm from "../../../images/insta-sm.png";
import youtube_sm from "../../../images/youtube-sm.png";
import ShareProfile from "./ShareProfile";




const CustomActions = ({ setSharePopup, row, combinedColumns }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [createCollectionDialog, setCreateCollectionDialog] = useState(false);
    const [isSucessful, setIsSuccessful] = useState(false);
    const [collection, setCollection] = useState({ ...row });
    const [campaign, setCampaign] = useState({});
    const [addBrandDialog, setAddBrandDialog] = useState(false);
    const [isBrandFocus, setIsBrandFocus] = useState(false);
    const [isCampaignFocus, setIsCampaignFocus] = useState(false);
    const [isStatusFocus, setIsStatusFocus] = useState(false);
    const [allCampaign, setAllCampaign] = useState([]);
    const [allBrands, setAllBrands] = useState([]);
    const [brandName, setBrandName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(true);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [campaignDetail, setCampaignDetail] = useState({});
    const [emailTemplate, setEmailTemplate] = useState("");
    const [isInviteByEmailChecked, setIsInviteByEmailChecked] = useState(false);
    const [isEmailFocus, setisEmailFoucs] = useState(false);
    const [showCampaign, setShowCampaign] = useState(false);
    const [error, setError] = useState({
        message: "",
        status: false,
    });
    const [showCollection, setShowCollection] = useState(false);

    const handleCapmapignListItemClick = (name, id) => {
        setCampaignDetail({
            name,
            id,
        });
        setIsCampaignFocus(false);
    };
    const handleOpenAssignDialog = () => {
        setOpenAssignDialog(true);
    };
    const handleOpenCampaignDialog = () => {
        setShowCampaign(true);
    };

    const handleOpenCollectionDialog = () => {
        setShowCollection(true);
    };

    const handleCloseAssignDialog = () => {
        setOpenAssignDialog(false);
    };

    const handleAssignToCampaign = () => {
        // Implement your logic to assign the collection to the selected campaign
        handleCloseAssignDialog();
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true);
        setError({ message: "", status: false });
        try {
            const response = await updateCollection(collection);
            setCollection(response);

            setIsSuccessful(true);
            setError({ message: "", status: false });
        } catch (e) {
            setError({ message: "Collection doesn't exists.", status: true });
            setIsSuccessful(false);
        } finally {
            setLoading(false);
        }
    };

    const handleCampaignSubmit = async (e) => {
        if (e) e.preventDefault();
        setLoading(true);
        setError({ message: "", status: false });
        try {
            const response = await AssignCampaign({
                id: row.id,
                campaign_id: campaignDetail.id,
            });
            // console.log(response);
            setIsSuccessful(true);
            setError({ message: "", status: false });
        } catch (e) {
            console.error("Error while submiting new collection data : " + e);

            setIsSuccessful(false);
        } finally {
            setLoading(false);
            setIsInviteByEmailChecked(false);
        }
    };

    const handleShareClick = () => {
        setOpenShareDialog(true); // Open the ShareCollection dialog
        setSharePopup(false); // Close the actions menu
    };

    const handleArchieve = () => {
        setShowConfirmationModal(true);
        setCollection({ ...collection, status: "Archived" });
    };

    const handleClose = () => {
        setOpenAssignDialog(false);
        setCreateCollectionDialog(false);
        setError({ message: "", status: false });
        setIsSuccessful(false);
        setIsBrandFocus(false);
        setBrandName("");
        setCollection({ ...collection });
        setAnchorEl(null);
        setShowConfirmationModal(false);

        // handleNewCollection(newCollection);
        // getCollection();
    };

    const getBrand = (id) => {
        const campaign = allBrands?.filter((brand) => {
            // console.log(brand, id, "brand-id");

            return brand.id == id;
        });

        return campaign;
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false); // Close the ShareCollection dialog
    };
    const dummyEmailTemplates = [
        { name: "Template A", id: 1 },
        { name: "Template B", id: 2 },
        { name: "Template C", id: 3 },
    ];

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closeCreateCollectionDialog = () => {
        setCreateCollectionDialog(false);
    };
    const openCreateCollectionDialog = () => {
        setCreateCollectionDialog(true);
    };

    const handleStatusChanged = (value) => {
        setCollection((prevCollection) => ({
            ...prevCollection,
            status: value,
        }));
        setIsStatusFocus(false);
    };

    const Mylabel = styled("label")(`
      color : #828282ff;
  `);
    const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
        color: "#FFFFFF",
    }));

    const MyInput = styled("input")(`
      background : #dce7f7ff;
      outlined : none;
      border : none;
      width : 100%;
      padding : 15px 10px;
      border-radius : 8px;
      font-size : 15px;
      margin-top : 5px;
  `);
    const MyTextArea = styled("textarea")(`
  background : #dce7f7ff;
      outlined : none;
      border : none;
      width : 100%;
      padding : 15px 10px;
      border-radius : 8px;
      font-size : 15px;
      margin-top : 5px;
  `);

    const handleListItemClick = (name, id) => {
        setBrandName(name);
        // console.log("brand name ", brandName);

        setCollection((prevCollection) => {
            return { ...prevCollection, assigned_brand_id: id };
        });
    };

    const handleCollectionChange = (e) => {
        const { name, value } = e.target;
        setCollection((prevCollection) => ({
            ...prevCollection,
            [name]: value,
        }));
    };

    const handleEmailListItemClick = (name, id) => {
        setEmailTemplate(name);
        setisEmailFoucs(false);
    };

    const handleBrandChange = (e) => {
        const { value } = e.target;
        setBrandName(value);

        // setFilteredBrand
        // setFilteredBrand(filteredBrands)
    };

    const handleStatusChange = (e) => {
        const { value } = e.target;
        setStatus(value);
    };

    const openAddBrandDialog = () => {
        setAddBrandDialog(true);
    };
    const closeAddBrandDialog = () => {
        setAddBrandDialog(false);
    };

    const filteredBrands = allBrands?.filter((brand) =>
        brand.name.toLowerCase().includes(brandName.toLowerCase())
    );


    const columnsData = combinedColumns.map((column) => {
        return { name: column.name, enabled: true }; // Set enabled to true by default
    });

    const handleCheckboxChange = (event) => {
        setIsInviteByEmailChecked(event.target.checked);
    };

    const handleCardClick1 = () => {
        setOpen(true);
    };
    const TemptDP =
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

    return (
        <>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon style={{ color: "#3dbf73ff" }} />
            </IconButton>
            <Dialog
                open={openShareDialog}
                onClose={handleCloseShareDialog}
                PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
            >
                <ShareProfile close={handleCloseShareDialog} />
            </Dialog>

            <Dialog
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >
                <Box p={2}>
                    <Typography variant="h6">
                        Are you sure you want to archive this collection?
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button
                            onClick={handleClose}
                            sx={{
                                textTransform: "none",
                                borderRadius: 10,
                                fontWeight: "bold",
                                marginRight: 1,
                                background: "#c5edd6ff",
                                borderWidth: "2px",
                                color: "black",
                                borderColor: "#3dbf73ff",
                                "&:hover": {
                                    borderWidth: "2px",
                                    borderColor: "#3dbf73ff",
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                handleClose();
                                handleSubmit();
                            }}
                            variant="contained"
                            sx={{
                                bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                                boxShadow: "none",
                                fontWeight: "bold",
                                borderRadius: 10,
                                // padding: '10px 30px',
                                "&:hover": {
                                    bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                                },
                            }}
                        >
                            Confirm Archive
                        </Button>
                    </Box>
                </Box>
            </Dialog>

            <Dialog
                open={createCollectionDialog}
                onClose={handleClose}
                // 👇 Props passed to Paper (modal content)
                PaperProps={{
                    sx: { borderRadius: 5, border: "2px solid black" },
                }}
            >
                {isSucessful ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 3,
                        }}
                    >
                        <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: "1.3rem",
                                color: "#3dbe72ff",
                                marginTop: 2,
                            }}
                        >
                            Collection Successfully Updated.
                        </Typography>

                        <Box
                            mt={4}
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                            }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    bgcolor: "#3dbe72ff",
                                    // color : '#3dbe72ff',
                                    fontWeight: "bold",
                                    // border: '1px solid black',
                                    boxShadow: "none",
                                    textTransform: "none",
                                    border: "1px solid #3dbe72ff",
                                    marginRight: 1,
                                    "&:hover": {
                                        bgcolor: "#3dbe72ff",
                                        color: "white",
                                        borderColor: "black",
                                    },
                                }}
                                onClick={() => handleClose()}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box container p={4}>
                        <Box
                            item
                            md={11}
                            xs={11}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    color: "#4caf50",
                                }}
                            >
                                Edit Collection
                            </Typography>
                        </Box>
                        <Box
                            item
                            md={1}
                            xs={1}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                            }}
                        >
                            <Tooltip title="Close" arrow placement="left">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setCreateCollectionDialog(false);
                                        handleClose();
                                    }}
                                >
                                    <CancelOutlined />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box item md={12} xs={12}>
                            <form>
                                <Box mt={2}>
                                    <Mylabel htmlFor="collection">Name of Collection</Mylabel>
                                    <Box>
                                        <MyInput
                                            type="text"
                                            name="name"
                                            id="collection"
                                            value={collection?.name}
                                            disabled={!isEditing}
                                            placeholder="Enter the Name of collection"
                                            onChange={(e) => handleCollectionChange(e)}
                                            required
                                            onFocus={() => setIsBrandFocus(false)}
                                        />
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Mylabel htmlFor="brand">Brand Name</Mylabel>
                                    <Box position={"relative"}>
                                        <MyInput
                                            type="text"
                                            name="brand_name"
                                            id="brand"
                                            disabled={!isEditing}
                                            value={
                                                getBrand(collection?.assigned_brand_id)?.[0]?.name || ""
                                            }
                                            placeholder="search the brand "
                                            required
                                            onChange={(e) => handleBrandChange(e)}
                                            onFocus={() => {
                                                setIsBrandFocus(true);
                                            }}
                                        // onBlur={() => setIsBrandFocus(false)}
                                        />
                                        <IconButton
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "10px",
                                                transform: "translateY(-50%)",
                                            }}
                                            onClick={openAddBrandDialog}
                                        >
                                            <Tooltip title="Add a new Brand" arrow>
                                                <Add />
                                            </Tooltip>
                                        </IconButton>
                                        {isBrandFocus && (
                                            <Box
                                                sx={{
                                                    padding: "5px 10px",
                                                    position: "absolute",
                                                    width: "100%",
                                                    background: "white",
                                                    marginTop: 1,
                                                    borderRadius: 2,
                                                    maxHeight: "140px",
                                                    overflowY: "scroll",
                                                    zIndex: 10,
                                                }}
                                            >
                                                {filteredBrands?.reverse()?.map(({ name, id }) => (
                                                    <ListItem
                                                        key={id}
                                                        component="div"
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => handleListItemClick(name, id)}
                                                    >
                                                        {name}
                                                    </ListItem>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Mylabel htmlFor="description">Description</Mylabel>
                                    <Box>
                                        <MyTextArea
                                            rows={3}
                                            type="text"
                                            name="description"
                                            id="description"
                                            disabled={!isEditing}
                                            placeholder="Write short description"
                                            onChange={(e) => handleCollectionChange(e)}
                                            required
                                            value={collection.description}
                                        />
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Mylabel htmlFor="collection">Status</Mylabel>
                                    <Box position={"relative"}>
                                        <MyInput
                                            value={collection.status}
                                            type="text"
                                            name="brand_name"
                                            id="brand"
                                            disabled={!isEditing}
                                            placeholder="search the brand "
                                            required
                                            onChange={(e) => handleStatusChange(e)}
                                            onFocus={() => {
                                                setIsStatusFocus(true);
                                            }}
                                        />

                                        {isStatusFocus && (
                                            <Box
                                                sx={{
                                                    padding: "5px 10px",
                                                    position: "absolute",
                                                    width: "100%",
                                                    background: "white",
                                                    marginTop: 1,
                                                    borderRadius: 2,
                                                    maxHeight: "100px",
                                                    overflowY: "auto",
                                                    zIndex: 10,
                                                }}
                                            >
                                                <ListItem
                                                    component="div"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleStatusChanged("Active")}
                                                >
                                                    Active
                                                </ListItem>
                                                <ListItem
                                                    component="div"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleStatusChanged("Draft")}
                                                >
                                                    Draft
                                                </ListItem>
                                                <ListItem
                                                    component="div"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleStatusChanged("Rejected")}
                                                >
                                                    Rejected
                                                </ListItem>
                                                <ListItem
                                                    component="div"
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => handleStatusChanged("Archieved")}
                                                >
                                                    Archieved
                                                </ListItem>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        mt: 2,
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            textTransform: "none",
                                            borderRadius: 10,
                                            fontWeight: "bold",
                                            marginRight: 1,
                                            background: "#c5edd6ff",
                                            borderWidth: "2px",
                                            color: "black",
                                            borderColor: "#3dbf73ff",
                                            "&:hover": {
                                                borderWidth: "2px",
                                                borderColor: "#3dbf73ff",
                                            },
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: 10,
                                            // padding: '10px 30px',
                                            "&:hover": {
                                                bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                                            },
                                        }}
                                        onClick={handleSubmit}
                                        endIcon={
                                            loading ? (
                                                <LoadingSpinner size={25} />
                                            ) : isSucessful ? (
                                                <AddTaskOutlined />
                                            ) : (
                                                error.status && <WarningAmberOutlined />
                                            )
                                        }
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                )}
            </Dialog>

            <Dialog open={addBrandDialog} onClose={closeAddBrandDialog}>
                <AddNewBrand close={closeAddBrandDialog} />
            </Dialog>

            <Dialog
                open={openAssignDialog}
                onClose={handleCloseAssignDialog}
                // 👇 Props passed to Paper (modal content)
                PaperProps={{ sx: { borderRadius: 5, border: "2px solid black" } }}
            >
                {isSucessful ? (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: 3,
                        }}
                    >
                        <AddTaskOutlined sx={{ fontSize: "2.2rem", color: "#3dbe72ff" }} />

                        <Typography
                            sx={{
                                fontWeight: "bold",
                                fontSize: "1.3rem",
                                color: "#3dbe72ff",
                                marginTop: 2,
                            }}
                        >
                            Campaign Assigned Successfully.
                        </Typography>

                        <Box
                            mt={4}
                            sx={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                            }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                sx={{
                                    bgcolor: "#3dbe72ff",
                                    // color : '#3dbe72ff',
                                    fontWeight: "bold",
                                    // border: '1px solid black',
                                    boxShadow: "none",
                                    textTransform: "none",
                                    border: "1px solid #3dbe72ff",
                                    marginRight: 1,
                                    "&:hover": {
                                        bgcolor: "#3dbe72ff",
                                        color: "white",
                                        borderColor: "black",
                                    },
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClose();
                                }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box container p={4}>
                        <Box
                            item
                            md={11}
                            xs={11}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "1.2rem",
                                    color: "#4caf50",
                                }}
                            >
                                Assign Collection to Campaign
                            </Typography>
                            {error.status && (
                                <Box>
                                    <p style={{ color: "#e81e61ff", fontSize: 13 }}>
                                        {error.message}
                                    </p>
                                </Box>
                            )}
                        </Box>
                        <Box
                            item
                            md={1}
                            xs={1}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "end",
                            }}
                        >
                            <Tooltip title="Close" arrow placement="left">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setCreateCollectionDialog(false);
                                        handleClose();
                                    }}
                                >
                                    <CancelOutlined />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box item md={12} xs={12}>
                            <form onSubmit={handleCampaignSubmit}>
                                <Box mt={2}>
                                    <Mylabel htmlFor="campaign"> Name of Campaign</Mylabel>
                                    <Box position={"relative"}>
                                        <MyInput
                                            type="text"
                                            name="campaign_name"
                                            id="campaign"
                                            placeholder="search the Campaign "
                                            required
                                            onChange={(e) => handleBrandChange(e)}
                                            onFocus={() => {
                                                setIsCampaignFocus(true);
                                            }}
                                            // onBlur={() => setIsBrandFocus(false)}
                                            value={campaignDetail.name}
                                        />
                                        <IconButton
                                            style={{
                                                position: "absolute",
                                                top: "50%",
                                                right: "10px",
                                                transform: "translateY(-50%)",
                                            }}
                                        // onClick={}
                                        >
                                            <Tooltip title="Add a new Brand" arrow>
                                                <Add />
                                            </Tooltip>
                                        </IconButton>
                                        {isCampaignFocus && (
                                            <Box
                                                sx={{
                                                    padding: "5px 10px",
                                                    position: "absolute",
                                                    width: "100%",
                                                    background: "white",
                                                    marginTop: 1,
                                                    borderRadius: 2,
                                                    maxHeight: "140px",
                                                    overflowY: "scroll",
                                                    zIndex: 10,
                                                }}
                                            >
                                                {allCampaign?.map(({ name, id }) => (
                                                    <ListItem
                                                        key={id}
                                                        component="div"
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() =>
                                                            handleCapmapignListItemClick(name, id)
                                                        }
                                                    >
                                                        {name}
                                                    </ListItem>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleCheckboxChange} />}
                                        label="Invite influencer via email"
                                    />
                                </Box>
                                {isInviteByEmailChecked && (
                                    <Box mt={2}>
                                        <Mylabel htmlFor="campaign"> Email Template</Mylabel>
                                        <Box position={"relative"}>
                                            <MyInput
                                                type="text"
                                                name="email_template"
                                                id="email_template"
                                                placeholder="Search for a template"
                                                // required
                                                // onBlur={() => setIsEmailFocus(false)}
                                                onFocus={() => setisEmailFoucs(true)}
                                                value={emailTemplate}
                                            />

                                            {isEmailFocus && (
                                                <Box
                                                    sx={{
                                                        padding: "5px 10px",
                                                        position: "absolute",
                                                        width: "100%",
                                                        background: "white",
                                                        marginTop: 1,
                                                        borderRadius: 2,
                                                        maxHeight: "140px",
                                                        overflowY: "scroll",
                                                        zIndex: 10,
                                                    }}
                                                >
                                                    {dummyEmailTemplates?.map(({ name, id }) => (
                                                        <ListItem
                                                            key={id}
                                                            component="div"
                                                            sx={{ cursor: "pointer" }}
                                                            onClick={() => handleEmailListItemClick(name, id)}
                                                        >
                                                            {name}
                                                        </ListItem>
                                                    ))}
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        mt: 2,
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            textTransform: "none",
                                            borderRadius: 10,
                                            fontWeight: "bold",
                                            marginRight: 1,
                                            background: "#c5edd6ff",
                                            borderWidth: "2px",
                                            color: "black",
                                            borderColor: "#3dbf73ff",
                                            "&:hover": {
                                                borderWidth: "2px",
                                                borderColor: "#3dbf73ff",
                                            },
                                        }}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        size="small"
                                        variant="contained"
                                        sx={{
                                            bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: 10,
                                            // padding: '10px 30px',
                                            "&:hover": {
                                                bgcolor: error.status ? "#e81e61ff" : "#4cb04fff",
                                            },
                                        }}
                                        endIcon={
                                            loading ? (
                                                <LoadingSpinner size={25} />
                                            ) : isSucessful ? (
                                                <AddTaskOutlined />
                                            ) : (
                                                error.status && <WarningAmberOutlined />
                                            )
                                        }
                                        disabled={loading || isSucessful}
                                    >
                                        {isSucessful ? "Assigned" : "Assign"}
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                )}
            </Dialog>

            {/* Add to Collection */}
            <Dialog
                open={showCollection}
                onClose={() => setShowCollection(false)}
                style={{ borderRadius: 20 }}
                maxWidth="md"
            >
                <Grid container>
                    <Grid
                        item
                        md={4}
                        p={3}
                        justifyContent="space-between"
                        style={{ background: "rgba(61, 190, 114, 0.2)" }}
                    >
                        <Grid container>
                            <Grid item md={12}>
                                <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                    Collection
                                </Typography>
                                <TextField
                                    size="small"
                                    placeholder="Search here..."
                                    sx={{
                                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                                        background: "#FFFFFF",
                                        marginTop: 2,
                                        marginBottom: 5,
                                        boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: 8, // Adjust the value as needed for your desired border radius
                                            paddingRight: 0.4,
                                            "&:hover fieldset": {
                                                borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <nav aria-label="secondary mailbox folders">
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton>
                                                <ListItemText>
                                                    <span>Food Influencers</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{ borderRadius: "10px" }}
                                                component="a"
                                                href="#simple-list"
                                            >
                                                <ListItemText>
                                                    <span>Lays</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{ borderRadius: "10px" }}
                                                component="a"
                                                href="#simple-list"
                                            >
                                                <ListItemText>
                                                    <span>Pringles</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </nav>
                            </Grid>
                        </Grid>
                        <Grid item md={12} mt={12}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                style={{
                                    background: "rgba(61, 190, 114, 0.3)",
                                    borderRadius: 20,
                                    textTransform: "none",
                                    boxShadow: "none",
                                    padding: "5px 20px",
                                    border: "2px solid rgba(61, 190, 114, 1)",
                                    color: "#000000",
                                    fontWeight: "bold",
                                }}
                            >
                                Create new Collection
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item md={8}>
                        <Grid item md={12} align="end" p={2}>
                            <Tooltip title="Close" arrow placement="left">
                                <IconButton onClick={() => setShowCollection(false)}>
                                    <CancelOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid container p={4}>
                            <Grid item md={12} display="flex">
                                <AvatarGroup
                                    max={4}
                                    total={147}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Avatar
                                        sx={{ width: 80, height: 80 }}
                                        alt="Remy Sharp"
                                        src={TemptDP}
                                    />
                                    <Avatar
                                        sx={{ width: 60, height: 60 }}
                                        alt="Travis Howard"
                                        src={TemptDP}
                                    />
                                    <Avatar
                                        sx={{ width: 50, height: 50 }}
                                        alt="Agnes Walker"
                                        src={TemptDP}
                                    />
                                    <Avatar
                                        sx={{ width: 50, height: 50 }}
                                        alt="Trevor Henderson"
                                        src={TemptDP}
                                    />
                                </AvatarGroup>
                                <Box ml={2}>
                                    <Typography fontSize="1.3rem" fontWeight="bold">
                                        Food Influencers
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12} mt={2}>
                                <Button
                                    style={{
                                        textTransform: "none",
                                        color: "#3DBE72",
                                        borderColor: "#3DBE72",
                                        marginRight: 10,
                                    }}
                                    size="small"
                                    variant="outlined"
                                >
                                    Food & Drinks
                                </Button>
                                <Button
                                    style={{
                                        textTransform: "none",
                                        color: "#3DBE72",
                                        borderColor: "#3DBE72",
                                    }}
                                    size="small"
                                    variant="outlined"
                                >
                                    Infotainment
                                </Button>
                            </Grid>
                            <Grid item md={12} mt={3}>
                                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                                    Assigned to Campaign
                                </Typography>
                                <Typography>Lays Marketing</Typography>
                            </Grid>

                            <Grid item md={12} mt={3}>
                                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                                    Bio
                                </Typography>
                                <Typography>
                                    Live life on your own tems #jointhemvmt Watches, Eyewear &
                                    Premium Accessories For her @mvmforher Shop our Site
                                </Typography>
                                <span>
                                    #Live #life #jointhemvmt #Watches #Eyewear #Premium
                                    #Accessories
                                </span>
                            </Grid>

                            <Grid item md={12} align="end">
                                <Button
                                    size="small"
                                    variant="contained"
                                    style={{
                                        background: "#3DBE72",
                                        borderRadius: 20,
                                        textTransform: "none",
                                        boxShadow: "none",
                                        padding: "5px 20px",
                                    }}
                                    onClick={() => addInfluencer()}
                                >
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            {/* Campaign Popup  */}
            <Dialog
                open={showCampaign}
                onClose={() => setShowCampaign(false)}
                style={{ borderRadius: 20 }}
                maxWidth="md"
            >
                <Grid container>
                    <Grid
                        item
                        md={4}
                        p={3}
                        justifyContent="space-between"
                        style={{ background: "rgba(61, 190, 114, 0.2)" }}
                    >
                        <Grid container>
                            <Grid item md={12}>
                                <Typography style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                                    Campaign
                                </Typography>
                                <TextField
                                    size="small"
                                    placeholder="Search here..."
                                    sx={{
                                        borderRadius: 8, // Adjust the value as needed for your desired border radius
                                        background: "#FFFFFF",
                                        marginTop: 2,
                                        marginBottom: 5,
                                        boxShadow: "12px 9px 24px -11px rgba(61,190,114,1)",
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: 8, // Adjust the value as needed for your desired border radius
                                            paddingRight: 0.4,
                                            "&:hover fieldset": {
                                                borderColor: "rgba(61, 190, 114, 1)", // Set the border color for hover state
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "rgba(61, 190, 114, 1)", // Set the border color for focused state
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <Search />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <nav aria-label="secondary mailbox folders">
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{
                                                    borderRadius: "10px",
                                                    background: "black",
                                                    color: "#FFFFFF",
                                                    "&:hover": { background: "black", color: "#FFFFFF" },
                                                }}
                                            >
                                                <ListItemText>
                                                    <span>Kurkure</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>

                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{ borderRadius: "10px" }}
                                                component="a"
                                                href="#simple-list"
                                            >
                                                <ListItemText>
                                                    <span>Lays</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{ borderRadius: "10px" }}
                                                component="a"
                                                href="#simple-list"
                                            >
                                                <ListItemText>
                                                    <span>Pringles</span>
                                                </ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </nav>
                            </Grid>
                        </Grid>
                        <Grid item md={12} mt={12}>
                            <Button
                                fullWidth
                                size="small"
                                variant="contained"
                                style={{
                                    background: "rgba(61, 190, 114, 0.3)",
                                    borderRadius: 20,
                                    textTransform: "none",
                                    boxShadow: "none",
                                    padding: "5px 20px",
                                    border: "2px solid rgba(61, 190, 114, 1)",
                                    color: "#000000",
                                    fontWeight: "bold",
                                }}
                            >
                                Create new Campaign
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item md={8}>
                        <Grid item md={12} align="end" p={2}>
                            <Tooltip title="Close" arrow placement="left">
                                <IconButton onClick={() => setShowCampaign(false)}>
                                    <CancelOutlined />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid container p={4}>
                            <Grid item md={12} display="flex">
                                <img
                                    width={60}
                                    style={{ borderRadius: "50%" }}
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT614EiE7SOojHkzZp69vJVoo9awHcCIXgS4A&usqp=CAU"
                                    alt=""
                                />
                                <Box ml={2}>
                                    <Typography fontSize="1.3rem" fontWeight="bold">
                                        Kurkure
                                    </Typography>
                                    <Typography fontSize="0.9rem">
                                        We want to increase our yearly sales.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item md={12} mt={3}>
                                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                                    Deliverables
                                </Typography>
                                <Box>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                        }}
                                        size="small"
                                        variant="outlined"
                                        startIcon={<img src={insta} />}
                                    >
                                        Instagram Reels
                                    </Button>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                            margin: "0px 10px",
                                        }}
                                        size="small"
                                        variant="outlined"
                                        startIcon={<img src={insta} />}
                                    >
                                        Instagram Posts
                                    </Button>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                        }}
                                        size="small"
                                        variant="outlined"
                                        startIcon={<img src={insta} />}
                                    >
                                        Instagram Story
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item md={12} mt={3}>
                                <Typography mb={1} sx={{ fontWeight: "bold" }}>
                                    Creator Preferences
                                </Typography>
                                <Box>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                        }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        Male
                                    </Button>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                            margin: "0px 5px",
                                        }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        Female
                                    </Button>
                                    <Button
                                        style={{
                                            textTransform: "none",
                                            color: "#3DBE72",
                                            borderColor: "#3DBE72",
                                        }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        Both
                                    </Button>
                                </Box>
                            </Grid>

                            <Grid item md={12} mt={3}>
                                <Typography fontWeight={"bold"}>Campaign Budget</Typography>
                                <Typography fontSize={"1.5rem"} fontWeight={"bold"}>
                                    $1.5k
                                </Typography>
                            </Grid>
                            <Grid item md={12} align="end">
                                <Button
                                    size="small"
                                    variant="contained"
                                    style={{
                                        background: "#3DBE72",
                                        borderRadius: 20,
                                        textTransform: "none",
                                        boxShadow: "none",
                                        padding: "5px 20px",
                                    }}
                                >
                                    Add to Campaign
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>


            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenShareDialog(true);
                    }}
                >
                    <ShareIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Share
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>
                    <GetAppIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Download as CSV
                </MenuItem> */}
                {/* <MenuItem onClick={handleClose}>
                    <PictureAsPdfIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Download as PDF
                </MenuItem> */}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleOpenCampaignDialog();
                    }}
                >
                    <CampaignIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Add to Campaign
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleOpenCollectionDialog();
                    }}
                >
                    <CampaignIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Add to Collection
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <EditIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />
                    Edit Profile
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        openCreateCollectionDialog();
                    }}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    <ArchiveIcon
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Hide Influencer
                </MenuItem>
                {/* <MenuItem
                    onClick={() => {
                        handleClose();
                    }}
                    sx={{ display: "flex", alignItems: "center" }}
                >
                    <Delete
                        sx={{ marginRight: "8px" }}
                        style={{ color: "#3dbf73ff" }}
                    />{" "}
                    Remove
                </MenuItem> */}
            </Menu>
        </>
    );
};

export default CustomActions;