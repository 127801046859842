import React from 'react'
import {  Button, Typography, styled } from '@mui/material'

import Grid from '@mui/material/Grid2';
// icons 
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import UploadIcon from '@mui/icons-material/Upload';


const Dp = styled('img')`

    width : 90%;
    border-radius : 50%;
`

const Title = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1rem'
}))

const SubTitle = styled(Typography)(({ theme }) => ({
    // fontWeight : 'bold',
    fontSize: '.7rem'
}))

const Details = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '1.1rem'
}))

const Status = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontSize: '.8rem'

}))

const ViewPlan = styled(Button)(({ theme }) => ({
    background: '#3DBE72',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 20,
    '&:hover': {
        background: '#3DBE72',
        boxShadow: 'none',
    }
}))

const ModifyCampaign = styled(Button)(({ theme }) => ({
    color: '#3DBE72',
    textTransform: 'none',

    marginLeft: 5,
    borderColor: '#3DBE72',
    borderRadius: 20,
    '&:hover': {
        background: '#3DBE72',
        color: '#FFFFFF'
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '.7rem',
    }

}))


const CampaignCard = (props) => {
    const myData = props.data;
    return (
        <Grid container alignItems="center" justify="center" style={{ padding: '1rem', border: '2px solid black', borderRadius: 20, marginLeft: 5 }}>
            <Grid item md={4} xs={4}>
                <Dp src={myData.dp} alt="" width={'100%'} height={'100%'} />
            </Grid>
            <Grid item md={8} xs={8}>
                <Title>{myData.title}</Title>
                <SubTitle>{myData.subtitle}</SubTitle>
            </Grid>
            <Grid item md={12} mt={2}>
                <Details>Details</Details>
            </Grid>
            <Grid item md={12} mt={1}>
                <Status> <span style={{ color: '#3DBE72', display: 'flex', alignItems: 'center', }}> <DonutLargeIcon /> &nbsp; Staus : </span> <span> &nbsp; {myData.details.status} </span> </Status>
            </Grid>
            <Grid item md={12} mt={1} mb={2}>
                <Status> <span style={{ color: '#3DBE72', display: 'flex', alignItems: 'center', }}> <UploadIcon /> &nbsp; % Completion : </span> <span> &nbsp; {myData.details.completion}</span> </Status>
            </Grid>
            <Grid item md={5} xs={5} style={{ margin: '10px 0px' }}>

                <ViewPlan fullWidth size='small' variant='contained'>View Plan</ViewPlan>

            </Grid>
            <Grid item md={7} xs={7}>
                <ModifyCampaign fullWidth size='small' variant='outlined'>Modify Campaign</ModifyCampaign>
            </Grid>

        </Grid>
    )
}

export default CampaignCard