import Grid from '@mui/material/Grid2';
import { Typography, Box, Icon, IconButton, Button, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom'
// import { Box } from '@mui/system'

import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";

import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const AccountAnalytics = ({ data, ID, selectedSocial }) => {

  const formatRender = (value) => {
    let valuetype = typeof value;

    if (valuetype === "string") {
      // replace all single quotes with double quotes
      let temp = value.replace(/'/g, '"');
      temp = JSON.parse(temp);

      if (Array.isArray(temp)) {
        return temp.join(", ");
      } else {
        return temp;
      }
    } else if (valuetype === "number") {
      // convert the string to number and the make the decimal values fixed to 2
      return value.toFixed(2);
    } else {
      return value;
    }
  };

  const InstaOptions = [
    // { key: "influencer_category", title: "Influencer Category" },
    // { key: "influencer_gender", title: "Influencer Gender" },
    { key: "engagement_rate", title: "Engagement Rate" },
    { key: "average_reels_views", title: "Average Reels Views" },
    { key: "average_likes", title: "Average Likes" },
    { key: "average_comments", title: "Average Comments" },
    { key: "brand_mentions", title: "Brand Mentions" },
    { key: "monthly_followers_growth", title: "Monthly Followers Growth" },
  ];

  const YoutubeOptions = [
    // { key: "influencer_category", title: "Influencer Category" },
    // { key: "gender", title: "Influencer Gender" },
    { key: "average_video_views", title: "Average Video Views" },
    { key: "average_comments", title: "Average Comments" },
    { key: "topics", title: "Influencer Interests" },
    { key: "engagement_rate", title: "Engagement Rate" },
    // { key: "mentions", title: "Brand Mentions" },
    // { key: "monthly_subscribers_growth", title: "Monthly Subscribers Growth" }
  ]

  let encodedId = btoa(ID?.toString());
  return (
    <Box
      borderRadius={4}
      padding={3}
      marginTop={4}
      sx={{
        background: "#e3f2fd",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: { md: "center", xs: "flex-start" },
          marginBottom: 3,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
          Account Analytics {selectedSocial === "instagram" ? " Instagram" : " Youtube"}
        </Typography>
        <Link
          component={RouterLink}
          to={`/dashboard/find-influencers/profile/${encodedId}`}
          sx={{ textDecoration: "none", color: "inherit", alignItems: "center" }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              borderRadius: 30,
              color: "#FFFFFF",
              fontWeight: "bold",
              background: "#3DBE72",
              paddingY: 0.8,
              paddingX: 2,
            }}
          >
            View Full Report
          </Button>
        </Link>
      </Box>

      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}
      >
        {selectedSocial === "instagram" ? InstaOptions.map((option, index) => (
          <Box
            item
            key={index}
            sx={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                {option}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
              </IconButton>
            </Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "1.7rem" }}>
              {"266"}
            </Typography>
          </Box>
        )) : YoutubeOptions.map((option, index) => {
          return <Grid
            size={1}
            key={index}
            sx={{ background: "#FFFFFF", padding: 2, borderRadius: 4 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontSize: 14, color: 'gray' }}>
                {option.title}
              </Typography>
              <IconButton sx={{ color: "#3DBE72" }}>
                <HelpOutlineRoundedIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                {data[option.key] ? formatRender(data[option.key]) : " "}
              </Typography>
            </Box>
          </Grid>
        }
        )}
      </Grid>

    </Box>
  );
};

export default AccountAnalytics;
