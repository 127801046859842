import axios from "axios";
import axiosInstance from "../axiosInstance";

const URL = process.env.REACT_APP_BASE_URL;

export const getUserByID = async (id) => {
  const Id = JSON.stringify(id);
  try {
    const res = await axios.post(`${URL}/search/yt/influencer/byid`, Id, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });
    console.log("user response by user id : ", res);
    return res.data;
  } catch (e) {
    console.log("error while fetching user details by id : ", e.message);
  }
};
