// src/components/NotificationProvider.js
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Centralized notification provider
const NotificationProvider = ({ children }) => {
    const notistackRef = React.useRef();

    const onClickDismiss = (key) => () => {
        notistackRef.current.closeSnackbar(key);
    };

    return (
        <SnackbarProvider
            maxSnack={3} // Adjust this based on your needs
            ref={notistackRef}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            // autoHideDuration={5000} // Auto close after 3 seconds
            action={(key) => (
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={onClickDismiss(key)}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        >
            {children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;
